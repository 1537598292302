import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom'

import { DashboardLayout } from '@/components/templates/DashboardLayout.tsx'
import { ProtectedRoute } from '@/components/templates/ProtectedRoute.tsx'
import { UserManagement } from '@/screens/admin/UserManagement'
import { Dashboard } from '@/screens/admin/UserManagement/dashboard/Dashboard.tsx'
import { Chat } from '@/screens/Chat'
import {
  ExerciseScreen,
  HandTherapy,
  ProgramSelection,
} from '@/screens/PatientHub/Exercises'
import { PatientScreen } from '@/screens/PatientHub/PatientScreen'
import { PatientSearch } from '@/screens/PatientHub/PatientSearch'
import { PDFLookup } from '@/screens/PatientHub/PDFLookup'
import { ProspectusScreen } from '@/screens/PatientHub/Prospectus/Prospectus.tsx'
import { PreCall } from '@/screens/PreCall'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { ENABLE_VIDEO_CALL } from '@/util/featureToggles'
import { Permissions } from '~common/auth/permissions'

import { chatPath } from './paths'

const DefaultRoute = () => {
  const user = useCurrentUser()

  if (user.permissions.includes(Permissions.Chat)) {
    return <Navigate to="/chat" />
  }

  return <Navigate to="/patients" />
}

const paths = [
  {
    children: [
      {
        element: <DefaultRoute />,
        id: 'Home',
        path: '/',
      },
      {
        element: (
          <ProtectedRoute permission={Permissions.Chat}>
            <Chat />
          </ProtectedRoute>
        ),
        id: 'Chat',
        path: chatPath,
      },
      {
        children: [
          {
            element: <PatientSearch />,
            id: 'Search patients',
            path: '/patients',
          },
          {
            element: <PatientScreen />,
            id: 'Patient screen',
            path: '/patients/:patientId',
          },
          {
            children: [
              {
                element: <ProgramSelection />,
                id: 'Select program',
                path: '/patients/:patientId/hand-therapy/select-program/:programId?',
              },
              {
                element: <ExerciseScreen />,
                id: 'Select exercises',
                path: '/patients/:patientId/hand-therapy/program/:programId?',
              },
            ],
            element: (
              <ProtectedRoute permission={Permissions.HandTherapy}>
                <HandTherapy />
              </ProtectedRoute>
            ),
            id: 'Hand therapy',
            path: '/patients/:patientId/hand-therapy',
          },
          {
            element: (
              <ProtectedRoute permission={Permissions.PDFLookup}>
                <PDFLookup />
              </ProtectedRoute>
            ),
            id: 'PDF Lookup',
            path: '/patients/:patientId/pdf-lookup',
          },
          {
            element: (
              <ProtectedRoute permission={Permissions.Prospectus}>
                <ProspectusScreen />
              </ProtectedRoute>
            ),
            id: 'Prospectus',
            path: '/patients/:patientId/prospectus',
          },
        ],
        element: (
          <ProtectedRoute permission={Permissions.PatientHub}>
            <Outlet />
          </ProtectedRoute>
        ),
        id: 'Patients',
        path: '/patients',
      },
      {
        element: (
          <ProtectedRoute permission={Permissions.AdminPanel}>
            <UserManagement />
          </ProtectedRoute>
        ),
        id: 'Admin Panel',
        path: '/admin',
      },
      {
        element: (
          <ProtectedRoute permission={Permissions.AdminDashboard}>
            <Dashboard />
          </ProtectedRoute>
        ),
        id: 'Dashboard',
        path: '/dashboard',
      },
      ...(ENABLE_VIDEO_CALL
        ? [
            {
              element: <PreCall />,
              id: 'Pre Call',
              path: '/pre-call',
            },
          ]
        : []),
    ],
    element: <DashboardLayout />,
    path: '/',
  },
  {
    element: <DefaultRoute />,
    path: '*',
  },
]

const router = createBrowserRouter(paths)

export const Router = () => <RouterProvider router={router} />
