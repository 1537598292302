import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import lodashUnescape from 'lodash/unescape'
import { useTranslation } from 'react-i18next'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import styled from 'styled-components'

import { Skeleton } from '@/components/common/Skeleton.tsx'
import { useTriageQuestionnaire } from '@/hooks/data/useTriageQuestionnaire.ts'

const Wrapper = styled.div<{ open: boolean }>`
  display: flex;
  height: ${(props) => (props.open ? '1px' : 'unset')};
  flex-grow: ${(props) => (props.open ? '1' : '0')};
  flex-direction: column;
  margin: 24px 20px;
  border: 1px solid ${({ theme }) => theme.colors.common.black20};
`

const SkeletonWrapper = styled.div`
  margin: 24px 20px;
`

const Button = styled(DisclosureButton)`
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  padding: 10px 20px;
  color: ${({ theme }) => theme.colors.common.black};

  &[aria-expanded='true'] {
    border-bottom: 1px solid ${({ theme }) => theme.colors.common.black20};
  }
`

const ChevronUp = styled(BsChevronUp)`
  height: 16px;
  width: 16px;
`

const ChevronDown = styled(BsChevronDown)`
  height: 16px;
  width: 16px;
`

const Panel = styled(DisclosurePanel)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 20px;
`

const QuestionAnswer = styled.div`
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.common.black10};
  font-size: 17px;

  &:last-child {
    border-bottom: none;
  }
`

const Question = styled.div`
  font-weight: 500;
`

const Answer = styled.div`
  margin: 4px 0;
`

export const TriageAccordion = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.triage',
  })

  const { isLoading, questionsAnswers: triage } = useTriageQuestionnaire()

  if (isLoading)
    return (
      <SkeletonWrapper>
        <Skeleton height={47} width={300} />
        <Skeleton height={28} width={160} />
        <Skeleton height={16} width={78} />
      </SkeletonWrapper>
    )

  if (!triage) return null

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => {
        return (
          <Wrapper open={open}>
            <Button>
              {t('accordionTitle')}
              {open ? <ChevronUp /> : <ChevronDown />}
            </Button>
            <Panel>
              {triage.map(({ answer, question }, i) => (
                <QuestionAnswer key={i}>
                  <Question>{question}</Question>
                  <Answer>{lodashUnescape(answer)}</Answer>
                </QuestionAnswer>
              ))}
            </Panel>
          </Wrapper>
        )
      }}
    </Disclosure>
  )
}
