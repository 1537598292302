import { OAuthProvider } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { getErrorMessageAndCode } from '@/util/error.ts'
import { azureAD, firebaseAuth, signInWithPopup } from '@/util/firebase.ts'

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 40px;
  border: 1px solid ${(props) => props.theme.colors.common.border};
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
`
const LoginImage = styled.img`
  width: 332px;
`

const Description = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 40px 0 32px 0;
`

const LoginButton = styled(StyledActionButton)`
  width: 100%;
`

type LoginProps = {
  setIsLoading: (isLoading: boolean) => void
}

export const Login = ({ setIsLoading }: LoginProps) => {
  const storedEmail = window.localStorage.getItem('emailForSignIn')
  const { t } = useTranslation('translation', { keyPrefix: 'login' })

  const handleMicrosoftSignIn = async () => {
    if (storedEmail) {
      window.localStorage.setItem('emailForSignIn', storedEmail)
    }
    // This will prefill the email field in the SSO popup
    azureAD.setCustomParameters({ login_hint: storedEmail ?? '' })
    try {
      setIsLoading(true)
      const userCredential = await signInWithPopup()

      const OAuthCredential = OAuthProvider.credentialFromResult(userCredential)

      // call backend with the email/id + token to update roles
      const externalId = userCredential.user.uid
      const userEmail = userCredential.user.email
      const accessToken = await userCredential.user.getIdToken()

      const result = await fetch(
        `${import.meta.env.BACKEND_API_URL}/users/${externalId}`,
        {
          body: JSON.stringify({
            email: userEmail,
            token: OAuthCredential?.idToken,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        },
      )

      if (result.ok) {
        // forcing a refresh on the token to trigger update
        await firebaseAuth.currentUser?.getIdToken(true)
      }
    } catch (e) {
      const { code, message } = getErrorMessageAndCode(e)

      console.error(code, message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoginWrapper>
      <LoginForm>
        <LoginImage alt="Equipe Zorgbedrijven" src="/equipe.svg"></LoginImage>
        <Description>{t('description')}</Description>
        <LoginButton
          onClick={handleMicrosoftSignIn}
          type="button"
          variant="Confirm"
        >
          {t('confirm')}
        </LoginButton>
      </LoginForm>
    </LoginWrapper>
  )
}
