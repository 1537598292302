import i18n from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormFieldType } from '@/components/admin/FormField.tsx'
import { Permission } from '@/types/permission.ts'

export const usePermissionFormFields = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.permissions',
  })

  const permissionFormFields: Array<FormFieldType<Permission>> = useMemo(
    () => [
      {
        id: 'name',
        label: t('columns.name'),
        type: 'text',
      },
      {
        id: 'description',
        label: t('columns.description'),
        type: 'text',
      },
    ],
    [i18n.language],
  )

  return {
    permissionFormFields,
  }
}
