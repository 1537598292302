import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useInternalConversation } from '@/state/hooks/useInternalConversation.ts'

import { ReturnToQueueModal } from './ReturnToQueueModal.tsx'

export const ReturnToQueue = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const internalConversation = useInternalConversation()
  const { closeInternalConversation, unassignActiveConversation } = useSocket()

  const handleClick = () => {
    if (internalConversation) {
      setModalIsOpen(true)
    } else {
      unassignActiveConversation()
    }
  }

  const handleConfirm = () => {
    if (internalConversation) {
      closeInternalConversation(internalConversation?.id)
    }
    unassignActiveConversation()
    setModalIsOpen(false)
  }

  return (
    <>
      <StyledActionButton
        data-testid="returnToQueueButton"
        onClick={handleClick}
        size="small"
        variant="Secondary"
      >
        {t('actions.returnToQueue')}
      </StyledActionButton>
      {modalIsOpen && (
        <ReturnToQueueModal
          onCancel={() => setModalIsOpen(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  )
}
