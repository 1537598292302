import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { Permission } from '@/types/permission'

import { useFetchFromAPI } from '../data/useFetchFromAPI'

const permissionsQueryOptions = queryOptions({
  queryKey: ['permissions'],
})

export const usePermissions = () => {
  const fetchAPI = useFetchFromAPI()

  const { data, isLoading } = useQuery<Permission[]>({
    queryFn: async () => fetchAPI(`admin/permissions`),
    queryKey: permissionsQueryOptions.queryKey,
  })

  return { isLoading, permissions: data || [] }
}

type usePermissionMutationProps = {
  onSuccess?: () => void
}

type CreatePermission = { permission: Omit<Permission, 'id'> }

export const useCreatePermission = ({
  onSuccess,
}: usePermissionMutationProps) => {
  const fetchAPI = useFetchFromAPI('POST')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    Permission,
    unknown,
    CreatePermission
  >({
    mutationFn: async ({ permission }) =>
      fetchAPI('admin/permissions', permission),
    onSuccess: (data: Permission) => {
      queryClient.setQueryData(
        permissionsQueryOptions.queryKey,
        (oldData: Permission[]) => {
          if (oldData) {
            return [...oldData, data]
          }
          return [data]
        },
      )
      onSuccess?.()
    },
  })

  return {
    createPermission: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
    newPermission: data,
  }
}

type UpdatePermission = {
  permission: Omit<Permission, 'id'>
  permissionId: string
}

export const useUpdatePermission = ({
  onSuccess,
}: usePermissionMutationProps) => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Permission,
    unknown,
    UpdatePermission
  >({
    mutationFn: async ({ permission, permissionId }) =>
      fetchAPI(`admin/permissions/${permissionId}`, permission),
    onSuccess: (data: Permission) => {
      queryClient.setQueryData(
        permissionsQueryOptions.queryKey,
        (oldData: Permission[]) => {
          if (oldData) {
            return oldData.map((permission) => {
              if (data.id === permission.id) {
                return data
              }
              return permission
            })
          }
          return [data]
        },
      )
      onSuccess?.()
    },
  })

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    updatePermission: mutate,
  }
}

type DeletePermission = {
  permissionId: string
}
export const useDeletePermission = ({
  onSuccess,
}: usePermissionMutationProps) => {
  const fetchAPI = useFetchFromAPI('DELETE')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Permission,
    unknown,
    DeletePermission
  >({
    mutationFn: async ({ permissionId }) =>
      fetchAPI(`admin/permissions/${permissionId}`),
    onSuccess: (_, deletedPermission) => {
      queryClient.setQueryData(
        permissionsQueryOptions.queryKey,
        (oldData: Permission[]) => {
          if (oldData) {
            return oldData.filter(
              (permission) => permission.id !== deletedPermission.permissionId,
            )
          }
          return []
        },
      )
      onSuccess?.()
    },
  })

  return {
    deletePermission: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
  }
}
