import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import {
  ModalActionButtons,
  ModalBackground,
  StyledModal,
} from '@/components/common/Modal.tsx'
import { Header3 } from '@/components/common/Text.tsx'

const CloseChatModal = styled(StyledModal)`
  width: 440px;
  text-align: center;
`

export const ReturnToQueueModal = ({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void
  onConfirm: () => void
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  const [modalContainer] = useState(document.getElementById('modal-container'))

  return (
    modalContainer &&
    createPortal(
      <ModalBackground closeModal={onCancel}>
        <CloseChatModal>
          <Header3>{t('returnToQueue.header')}</Header3>
          <p>{t('returnToQueue.text')}</p>
          <ModalActionButtons>
            <StyledActionButton onClick={onCancel} variant="Secondary">
              {t('returnToQueue.cancel')}
            </StyledActionButton>
            <StyledActionButton
              data-testid="confirmReturnToQueueButton"
              onClick={onConfirm}
              variant="Secondary"
            >
              {t('returnToQueue.confirm')}
            </StyledActionButton>
          </ModalActionButtons>
        </CloseChatModal>
      </ModalBackground>,
      modalContainer,
    )
  )
}
