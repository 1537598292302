import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Label, TextArea } from '@/components/common/Input.tsx'
import { Tile } from '@/components/common/Tile.tsx'
import { FrequencyListbox } from '@/components/templates/Tiles/FrequencyListbox'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext'
import { Exercise } from '@/types/exercise.ts'

const SPLINT_OPTIONS_KEYS = ['day', 'night', 'day-night']

type SplintTileProps = {
  splint: Exercise
}

export const SplintTile = ({ splint }: SplintTileProps) => {
  const { actions } = useHandTherapyContext()
  const { register } = useFormContext()
  const keyPrefix = 'handTherapy.splints'
  const { t } = useTranslation('translation', {
    keyPrefix,
  })

  const handleRemoveSplint = () => {
    actions?.removeExercise(splint.contentfulId)
    actions?.setIsDirty(true)
  }

  return (
    <Tile
      imageSrc={splint.thumbnail}
      onClose={handleRemoveSplint}
      title={splint.name}
    >
      <Label>
        {t('wearDuring')}
        <FrequencyListbox
          defaultValue={splint.details.wearDuring}
          i18nKeyPrefix={keyPrefix}
          name={`wearDuring_${splint.contentfulId}`}
          options={SPLINT_OPTIONS_KEYS}
        />
      </Label>
      <Label>
        {t('instructions')}
        <TextArea
          {...register(`instructions_${splint.contentfulId}`)}
          rows={4}
        />
      </Label>
    </Tile>
  )
}
