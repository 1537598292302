import { useMutation } from '@tanstack/react-query'

import { useFetchFromAPI } from './useFetchFromAPI'

type CreateChatSummary = {
  chatSummary: {
    attachments: { url: string }[]
    author: {
      id: string
      name: string
    }
    content: string
  }
  patientId: string
}

export const useCreateChatSummary = () => {
  const fetchAPI = useFetchFromAPI('POST')

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    unknown,
    unknown,
    CreateChatSummary
  >({
    mutationFn: async ({ chatSummary, patientId }) => {
      return fetchAPI(`admin/patients/${patientId}/communications`, chatSummary)
    },
  })

  return {
    createChatSummary: mutate,
    data,
    isError,
    isPending,
    isSuccess,
  }
}
