import { useQuery } from '@tanstack/react-query'

import { useFetchFromAPI } from '../data/useFetchFromAPI.ts'

type UserGroup = {
  id: number
  key: string
  name: string
}

export const useUserGroups = () => {
  const fetchAPI = useFetchFromAPI()

  const { data: userGroups, isLoading } = useQuery<UserGroup[]>({
    queryFn: async () => fetchAPI(`admin/user-groups`),
    queryKey: ['userGroups'],
  })

  return { isLoading, userGroups }
}
