import styled from 'styled-components'

import { RawDropdown } from '@/components/common/Dropdown.tsx'
import { BodyM } from '@/components/common/Text.tsx'
import { Queue } from '@/state/stateType.ts'

const DropdownContainer = styled.div`
  min-width: 250px;
  position: relative;
`

const QueueTitle = styled.p`
  font-weight: 500;
  margin-bottom: ${(props) => `${props.theme.spacing}px`};
`

const UserGroups = styled.div`
  color: ${(props) => props.theme.colors.common.selected};
`

export const QueuesDropdown = ({
  className,
  onSelect,
  queues,
  selectedQueueId,
}: {
  className?: string
  onSelect: (id: string) => void
  queues: Queue[]
  selectedQueueId?: string
}) => {
  const filteredQueues = queues.filter((q) => q.id !== selectedQueueId)
  const selectedQueue = queues.find((q) => q.id === selectedQueueId)

  if (filteredQueues.length === 0 && selectedQueue) {
    return <BodyM fontWeight="bold">{selectedQueue.title}</BodyM>
  }
  // TODO:  selectedQueue is empty if Urgent message
  return (
    <DropdownContainer className={className}>
      <RawDropdown<Queue>
        items={filteredQueues}
        onSelect={onSelect}
        prompt={selectedQueue?.title}
        renderItem={(item) => (
          <>
            <QueueTitle>{item.title}</QueueTitle>
            <UserGroups>{item.userGroups.join(', ')}</UserGroups>
          </>
        )}
        selectedItemId={selectedQueueId}
      />
    </DropdownContainer>
  )
}
