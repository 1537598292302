import styled from 'styled-components'

import { StyledDestructiveButton } from '@/components/common/Buttons.tsx'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  overflow: auto;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 24px;
`

export const Content = styled.div`
  height: 100%;
  padding: 40px;
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`

export const Actions = styled.footer`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.common.black10};
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.common.white};
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`

export const CancelButton = styled(StyledDestructiveButton)`
  padding: 8px 24px;
`
