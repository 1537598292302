import dayjs from 'dayjs'

import { CarePath } from '@/types/patient'

export function groupCarePlans(carePlans: CarePath[]): {
  onGoingCarePlans: CarePath[]
  pastCarePlans: CarePath[]
} {
  const [onGoingCarePlans, pastCarePlans] =
    carePlans.reduce(
      ([ongoing, past], carePlan) => {
        if (
          carePlan.period?.end &&
          dayjs(carePlan.period.end).isBefore(new Date())
        ) {
          past.push(carePlan)
        } else {
          ongoing.push(carePlan)
        }
        return [ongoing, past]
      },
      [[], []] as [CarePath[], CarePath[]],
    ) || []
  return { onGoingCarePlans, pastCarePlans }
}
