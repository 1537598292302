import styled from 'styled-components'

import { textSizeM, textSizeS } from '@/styles/theme/fonts.ts'

export const Header1 = styled.h1`
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.13px;
`

export const Header2 = styled.h2`
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: -0.13px;
`

export const Header3 = styled.h3`
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: -0.11px;
`

export const Header4 = styled.h4`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.36px;
`

export const TinyTitle = styled.h4`
  color: ${({ theme }) => theme.colors.common.black75};
  font-weight: 500;
  padding-bottom: 4px;
  text-transform: uppercase;
  font-size: 14px;
`

export const BodyMedium = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.005em;
`

export const Navigation = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
`
export const BodySmall = styled.p`
  font-size: 14px;
  font-weight: 300;
`

type TextProps = {
  fontWeight?: 'bold' | 'normal'
}

export const Text = styled.p<TextProps>`
  ${textSizeM};
  font-weight: ${({ fontWeight }) => (fontWeight === 'bold' ? 500 : 400)};
  color: ${({ color, theme }) => color || theme.colors.common.black62};
`
export const BodyS = styled(Text)`
  ${textSizeS}
`

export const BodyM = styled(Text)``

export const TitleSmall = styled(Text)`
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.36px;
  font-weight: 500;
  text-transform: uppercase;
`
