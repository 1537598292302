import './styles/fonts.css'

import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import i18n from 'i18next'
import { useEffect } from 'react'

import { setError, store, useAppStore } from '@/app.ts'
import { Error } from '@/components/templates/Errors/Error'
import { ErrorTop } from '@/components/templates/Errors/ErrorTop'
import { FullScreenLoader } from '@/components/templates/FullScreenLoader.tsx'
import { Notification as NotificationComponent } from '@/components/templates/Notification.tsx'
import { SocketProvider } from '@/contexts/SocketContext.tsx'
import { useAuthentication } from '@/contexts/useAuthenticationContext'
import { usePrefetchUsers } from '@/hooks/admin/useUsers.ts'
import { Router } from '@/router/Router.tsx'
import { Login } from '@/screens/Login.tsx'
import { Permissions } from '~common/auth/permissions.ts'

import { useNotificationPermission } from './hooks/useNotificationPermission'
import { useSmartLook } from './hooks/useSmartLook'
import { Clinician } from './state/stateType'

dayjs.extend(localeData)

const App = () => {
  const { isAuthenticated, isLoading, setIsLoading, token, user } =
    useAuthentication()
  useEffect(() => {
    store.setLoggedInUser(user?.uid)
    return () => store.setLoggedInUser(undefined)
  }, [user?.uid])

  if (isLoading) {
    return <FullScreenLoader />
  }

  if ((!isAuthenticated && !isLoading) || !token) {
    return <Login setIsLoading={setIsLoading} />
  }

  return (
    <SocketProvider token={token}>
      <Main />
    </SocketProvider>
  )
}

function Main() {
  const state = useAppStore()
  const clinician: Clinician = state.user
  const { isAuthenticated, isLoading } = useAuthentication()
  const prefetchUsers = usePrefetchUsers()

  useNotificationPermission()
  useSmartLook(clinician)

  useEffect(() => {
    if (clinician.permissions?.includes(Permissions.AdminPanel)) {
      prefetchUsers()
    }
  }, [clinician])

  useEffect(() => {
    dayjs.locale(i18n.language)
  }, [i18n.language])

  if (!isLoading && isAuthenticated && state.error === 'AccessDenied') {
    return <Error text="Access Denied" hasLogoutButton />
  }

  if (!state.isInitialized || !clinician) {
    return <FullScreenLoader />
  }

  return (
    <>
      {state.error && (
        <ErrorTop message={state.error} onClose={() => setError(undefined)} />
      )}
      <Router />
      <div id="modal-container" />
      {state.notification && (
        <NotificationComponent text={state.notification} />
      )}
    </>
  )
}

export default App
