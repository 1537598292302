import { AppState } from '@/state/stateType'
import { setProps } from '@/util/object'
import { ConversationClosedEvent } from '~common/serverToClientParser'

export default function handleConversationClosed(
  state: AppState,
  ev: ConversationClosedEvent,
): AppState {
  const updatedConversations = [...state.conversations].filter(
    (conversation) => conversation.id !== ev.conversationId,
  )

  return setProps(state, {
    activeConversationId: undefined,
    conversations: updatedConversations,
  })
}
