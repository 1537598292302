import { AppState } from '@/state/stateType'
import { setProps } from '@/util/object'
import { ConversationAssignedEvent } from '~common/serverToClientParser'

import mapStateConversations from './util'

export default function handleConversationAssigned(
  state: AppState,
  ev: ConversationAssignedEvent,
): AppState {
  return mapStateConversations(
    ev.conversationId,
    (c) =>
      setProps(c, {
        assignedToId: ev.assignedToId,
        events: c.events?.concat([ev]),
      }),
    state,
  )
}
