import { TabGroup, TabPanels } from '@headlessui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { StyledTab, StyledTabList } from '@/components/common/Tabs.tsx'
import { Header2 } from '@/components/common/Text.tsx'
import {
  useCreatePermission,
  usePermissions,
} from '@/hooks/admin/usePermissions.ts'
import { useCreateRole, useRoles } from '@/hooks/admin/useRoles.ts'
import { useCreateUser, useUsers } from '@/hooks/admin/useUsers.ts'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { FeatureFlags } from '@/screens/admin/UserManagement/featureFlags/FeatureFlags.tsx'
import { PermissionsTable } from '@/screens/admin/UserManagement/permissions/PermissionsTable.tsx'
import { usePermissionFormFields } from '@/screens/admin/UserManagement/permissions/usePermissionFormFields.ts'
import { QueuesDashboard } from '@/screens/admin/UserManagement/queues/QueuesDashboard.tsx'
import { RolesTable } from '@/screens/admin/UserManagement/roles/RolesTable.tsx'
import { useRoleFormFields } from '@/screens/admin/UserManagement/roles/useRoleFormFields.ts'
import { tagColors } from '@/screens/admin/UserManagement/tagColors.ts'
import { useUserFormFields } from '@/screens/admin/UserManagement/users/useUserFormFields.ts'
import { Permission } from '@/types/permission.ts'
import { User } from '@/types/user.ts'

import { DownloadCSVButton } from './DownloadCSVButton.tsx'
import * as S from './UserManagement.styled.ts'
import {
  FlexContainer,
  ScreenContentTabPanel,
  StyledActionButtons,
  TableContainer,
} from './UserManagement.styled.ts'
import { UsersTable } from './users/UsersTable.tsx'

export const UserManagement = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement',
  })
  const { isLoading: isLoadingUsers, users } = useUsers()
  const { userFormFields } = useUserFormFields()
  const [newUserModal, setNewUserModal] = useState<boolean>(false)
  const { createUser, isError: isErrorCreatingUser } = useCreateUser({
    onSuccess: () => {
      setNewUserModal(false)
    },
  })

  const { roles } = useRoles()
  const { roleFormFields } = useRoleFormFields()
  const [newRoleModal, setNewRoleModal] = useState<boolean>(false)
  const { createRole, isError: isErrorCreatingRole } = useCreateRole({
    onSuccess: () => {
      setNewRoleModal(false)
    },
  })

  const { permissions } = usePermissions()
  const { permissionFormFields } = usePermissionFormFields()
  const [newPermissionModal, setNewPermissionModal] = useState<boolean>(false)
  const { createPermission, isError: isErrorCreatingPermission } =
    useCreatePermission({
      onSuccess: () => {
        setNewPermissionModal(false)
      },
    })

  const mapRoleWithColor = roles?.map((role, index) => {
    return {
      ...role,
      ...tagColors[index],
    }
  })

  const mapPermissionWithColor = permissions?.map((permission, index) => {
    return {
      ...permission,
      id: permission.id,
      ...tagColors[index],
    }
  })
  return (
    <S.Container>
      <TabGroup as={React.Fragment}>
        <StyledTabList>
          <StyledTab>{t('tabs.users')}</StyledTab>
          <StyledTab>{t('tabs.roles')}</StyledTab>
          <StyledTab>{t('tabs.reasons')}</StyledTab>
          <StyledTab>{t('tabs.featureFlags')}</StyledTab>
        </StyledTabList>
        <TabPanels>
          <ScreenContentTabPanel>
            <S.HeaderBar>
              <Header2>{t('tabs.users')}</Header2>
              <StyledActionButtons>
                <StyledActionButton
                  onClick={() => setNewUserModal(true)}
                  variant="Primary"
                >
                  {t('users.actions.add')}
                </StyledActionButton>
                <DownloadCSVButton
                  data={users
                    .filter((user) => user.customClaims?.patientId)
                    .map((user) => ({
                      creationDate: user.metadata.creationTime,
                      lastRefresh: user.metadata.lastRefreshTime,
                      patientId: user.customClaims.patientId,
                    }))}
                  disabled={isLoadingUsers || users.length === 0}
                  fileName="users"
                  label={t('users.actions.download')}
                />
              </StyledActionButtons>
            </S.HeaderBar>
            {isLoadingUsers ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <>
                <UsersTable roles={mapRoleWithColor} users={users} />
                {newUserModal && (
                  <EditModal
                    closeModal={() => setNewUserModal(false)}
                    errorMessage={t('users.createModal.error')}
                    formFields={userFormFields}
                    isError={isErrorCreatingUser}
                    onSave={(user: User) =>
                      createUser({
                        user,
                      })
                    }
                    title={t('users.createModal.title')}
                  />
                )}
              </>
            )}
          </ScreenContentTabPanel>
          <ScreenContentTabPanel>
            <S.HeaderBar>
              <Header2>{t('tabs.roles')}</Header2>
              <StyledActionButtons>
                <StyledActionButton
                  onClick={() => setNewRoleModal(true)}
                  variant="Primary"
                >
                  {t('roles.actions.add')}
                </StyledActionButton>
                <StyledActionButton
                  onClick={() => setNewPermissionModal(true)}
                  variant="Secondary"
                >
                  {t('permissions.actions.add')}
                </StyledActionButton>
              </StyledActionButtons>
            </S.HeaderBar>
            <FlexContainer>
              <TableContainer>
                <RolesTable
                  permissions={mapPermissionWithColor}
                  roles={roles}
                />
              </TableContainer>
              <TableContainer>
                <PermissionsTable permissions={permissions} />
              </TableContainer>
            </FlexContainer>
            {newRoleModal && (
              <EditModal
                closeModal={() => setNewRoleModal(false)}
                errorMessage={t('roles.createModal.error')}
                formFields={roleFormFields}
                isError={isErrorCreatingRole}
                onSave={(role) =>
                  createRole({
                    role,
                  })
                }
                selectOptions={{
                  permissions,
                }}
                title={t('roles.createModal.title')}
              />
            )}
            {newPermissionModal && (
              <EditModal
                caption={t('permissions.createModal.caption')}
                closeModal={() => setNewPermissionModal(false)}
                errorMessage={t('permissions.createModal.error')}
                formFields={permissionFormFields}
                isError={isErrorCreatingPermission}
                onSave={(permission: Permission) =>
                  createPermission({
                    permission,
                  })
                }
                title={t('permissions.createModal.title')}
              />
            )}
          </ScreenContentTabPanel>
          <ScreenContentTabPanel>
            <QueuesDashboard />
          </ScreenContentTabPanel>
          <ScreenContentTabPanel>
            <FeatureFlags />
          </ScreenContentTabPanel>
        </TabPanels>
      </TabGroup>
    </S.Container>
  )
}
