import styled from 'styled-components'

import { ActionBar } from '@/components/templates/ChatArea/Actions/ActionButtons.tsx'
import { ChatArea } from '@/components/templates/ChatArea/ChatArea.tsx'
import { PatientSidebar } from '@/components/templates/PatientSidebar/Sidebar.tsx'
import { Queues } from '@/components/templates/Queues/Queues.tsx'
import { Conversation } from '@/state/stateType.ts'

const SidebarContainer = styled.div`
  grid-row: 2/6;
  grid-column: 3;
`

export function ActiveConversationContainer({
  activeConversation,
  assignedConversations,
  setActiveConversation,
  unassignedConversations,
}: {
  activeConversation: Conversation
  assignedConversations: Conversation[]
  setActiveConversation: (value: string | undefined) => void
  unassignedConversations: Conversation[]
}) {
  const activeConversationPatientPulseId = activeConversation?.patient.pulseIds

  return (
    <>
      <ActionBar />
      <Queues
        activeConversationId={activeConversation?.id}
        assignedConversations={assignedConversations}
        setActiveConversation={setActiveConversation}
        unassignedConversations={unassignedConversations}
      />
      <ChatArea />
      <SidebarContainer>
        <PatientSidebar
          pulseIds={activeConversationPatientPulseId ?? undefined}
        />
      </SidebarContainer>
    </>
  )
}
