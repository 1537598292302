import 'dayjs/locale/nl'

import { TabGroup, TabPanels } from '@headlessui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledTab, StyledTabList } from '@/components/common/Tabs.tsx'
import * as S from '@/screens/admin/UserManagement/UserManagement.styled.ts'
import { ScreenContentTabPanel } from '@/screens/admin/UserManagement/UserManagement.styled.ts'

import { ChatDashboard } from './ChatDashboard/ChatDashboard.tsx'
import { UsersDashboard } from './UsersDashboard/UsersDashboard.tsx'

export const Dashboard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminDashboard',
  })
  return (
    <S.Container>
      <TabGroup as={React.Fragment}>
        <StyledTabList>
          <StyledTab>{t('tabs.users')}</StyledTab>
          <StyledTab>{t('tabs.chats')}</StyledTab>
        </StyledTabList>
        <TabPanels>
          <ScreenContentTabPanel>
            <UsersDashboard />
          </ScreenContentTabPanel>
          <ScreenContentTabPanel>
            <ChatDashboard />
          </ScreenContentTabPanel>
        </TabPanels>
      </TabGroup>
    </S.Container>
  )
}
