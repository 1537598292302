import { AppState } from '@/state/stateType'
import { getActiveConversation } from '@/state/stateUtil.ts'
import { setProps, updateProp } from '@/util/object'

export default function handleCloseInternalConversation(
  state: AppState,
): AppState {
  const currentUserId = state.user.id
  const activeConversation = getActiveConversation(state)

  if (currentUserId === activeConversation?.assignedToId) {
    return updateProp(state, 'conversations', (conversations) => {
      return conversations.map((conversation) => {
        if (conversation.id === activeConversation.id) {
          return {
            ...conversation,
            internalConversation: undefined,
          }
        }
        return conversation
      })
    })
  }
  const updatedConversations = [...state.conversations].filter(
    (conversation) => conversation.id !== activeConversation?.id,
  )
  return setProps(state, {
    activeConversationId: undefined,
    conversations: updatedConversations,
  })
}
