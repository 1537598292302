import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'

import { AuthenticationProvider } from '@/contexts/AuthenticationContext.tsx'
import { GlobalStyle } from '@/styles/globalStyles.tsx'
import theme from '@/styles/theme'

const queryClient = new QueryClient()

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticationProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {children}
          <ReactQueryDevtools initialIsOpen={true} />
        </ThemeProvider>
      </AuthenticationProvider>
    </QueryClientProvider>
  )
}
