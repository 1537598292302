import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'

import { AssignConversationModal } from './AssignConversationModal.tsx'

export const AssignConversation = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.assign',
  })

  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <StyledActionButton
        data-testid="assignToClinician"
        onClick={() => setModalIsOpen(true)}
        size="small"
        variant="Secondary"
      >
        {t('button')}
      </StyledActionButton>
      {modalIsOpen && (
        <AssignConversationModal closeModal={() => setModalIsOpen(false)} />
      )}
    </>
  )
}
