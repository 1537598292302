export const getErrorCode = (error: unknown) =>
  error && typeof error === 'object' && 'code' in error
    ? (error.code as string)
    : undefined

export const getErrorMessage = (error: unknown) =>
  error && typeof error === 'object' && 'message' in error
    ? (error.message as string)
    : undefined

export const getErrorMessageAndCode = (error: unknown) =>
  error && typeof error === 'object' && 'code' in error && 'message' in error
    ? (error as { code: string; message: string })
    : { code: undefined, message: undefined }
