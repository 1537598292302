import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import ErrorPDFIcon from '@/assets/svgs/error-pdf.svg'
import SearchInfoIcon from '@/assets/svgs/search-info.svg'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import {
  ModalActionButtons,
  ModalBackground,
} from '@/components/common/Modal.tsx'
import { Spinner } from '@/components/common/Spinner.tsx'
import { BodyM, BodyS, Header3 } from '@/components/common/Text.tsx'
import { CarePlansStack } from '@/components/templates/PatientSidebar/CarePlans.tsx'
import {
  ExpandProfile,
  useExpandProfileTransition,
} from '@/components/templates/PatientSidebar/ExpandProfile.tsx'
import { PatientInformation } from '@/components/templates/PatientSidebar/PatientInformation.tsx'
import { Overlay } from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'
import {
  useCarePlanPDF,
  useSendCarePlanPDF,
} from '@/hooks/data/useCarePlanPDF.ts'
import { usePatientInfo } from '@/hooks/data/usePatient.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'
import { groupCarePlans } from '@/util/care-plans.ts'

import * as S from './PDFLookup.styled.ts'
import { DefaultPDFText } from './PDFLookup.styled.ts'

const SUCCESS_MESSAGE_TIMEOUT = 1500

export const PDFLookup = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.pdfs',
  })
  const { patientId } = usePatientPulseIdFromParams()
  const { patientInfo } = usePatientInfo(patientId)
  const theme = useTheme()
  const [selectedCarePlanId, setSelectedCarePlanId] = useState<string>()
  const [confirmSendPDFModalOpen, setConfirmSendPDFModalOpen] = useState(false)
  const { isError, isLoading, isSuccess, reset, sendPDFEmail } =
    useSendCarePlanPDF({
      onSuccess: () => {
        setTimeout(() => {
          setConfirmSendPDFModalOpen(false)
          reset()
        }, SUCCESS_MESSAGE_TIMEOUT)
      },
    })

  const closeModal = () => {
    setConfirmSendPDFModalOpen(false)
    reset()
  }

  return (
    <S.Main>
      <S.GridContainer>
        <S.Content>
          <S.DefaultBackground>
            {selectedCarePlanId ? (
              <PDFViewer carePlanId={selectedCarePlanId}></PDFViewer>
            ) : (
              <>
                <S.DefaultPDFIcon alt="Search info icon" src={SearchInfoIcon} />
                <DefaultPDFText fontWeight="bold">
                  {t('noCarePlanSelected.title')}
                </DefaultPDFText>
                <BodyS>{t('noCarePlanSelected.description')}</BodyS>
              </>
            )}
          </S.DefaultBackground>
        </S.Content>

        <PatientCarePlansSidebar
          onSelect={setSelectedCarePlanId}
          onSend={() => setConfirmSendPDFModalOpen(true)}
          selectedCarePlanId={selectedCarePlanId}
        />
        {selectedCarePlanId && confirmSendPDFModalOpen && (
          <ModalBackground closeModal={closeModal}>
            <S.Modal>
              <S.CloseModalButton onClick={closeModal} />
              <Header3>{t('sendPDF.header')}</Header3>
              <S.ModalText>
                {t('sendPDF.text', {
                  email: patientInfo?.email,
                })}
              </S.ModalText>
              {isError && (
                <BodyS color={theme.colors.common.alert}>
                  {t('sendPDF.error')}
                </BodyS>
              )}
              {isSuccess && (
                <BodyS color={theme.colors.common.darkSeaGreen}>
                  {t('sendPDF.success')}
                </BodyS>
              )}
              <ModalActionButtons>
                <StyledActionButton
                  disabled={isLoading || isSuccess}
                  onClick={closeModal}
                  variant="Secondary"
                >
                  {t('sendPDF.cancel')}
                </StyledActionButton>
                <StyledActionButton
                  disabled={isLoading || isSuccess}
                  onClick={() => {
                    sendPDFEmail({
                      carePlanId: selectedCarePlanId,
                      email: patientInfo?.email,
                      language: patientInfo?.language,
                    })
                  }}
                  variant="Primary"
                >
                  {isLoading ? <S.ButtonSpinner /> : t('sendPDF.confirm')}
                </StyledActionButton>
              </ModalActionButtons>
            </S.Modal>
          </ModalBackground>
        )}
      </S.GridContainer>
    </S.Main>
  )
}

const PDFViewer = ({ carePlanId }: { carePlanId: string }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.pdfs',
  })

  const { data, error, isLoading } = useCarePlanPDF(carePlanId)

  if (isLoading) {
    return <Spinner />
  }
  if (error || !data) {
    return (
      <>
        <S.DefaultPDFIcon alt="error loading pdf icon" src={ErrorPDFIcon} />
        <DefaultPDFText fontWeight="bold">{t('error.title')}</DefaultPDFText>
        <BodyS>{t('error.description')}</BodyS>
      </>
    )
  }
  return <iframe height="100%" src={data} width="100%" />
}

const PatientCarePlansSidebar = ({
  onSelect,
  onSend,
  selectedCarePlanId,
}: {
  onSelect: (carePlanId: string) => void
  onSend: () => void
  selectedCarePlanId?: string
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.pdfs',
  })
  const { patientId } = usePatientPulseIdFromParams()
  const { isLoading, patientInfo } = usePatientInfo(patientId)
  const { expanding } = useExpandProfileTransition()

  const { onGoingCarePlans, pastCarePlans } = useMemo(
    () => groupCarePlans(patientInfo?.carePaths ?? []),
    [patientInfo?.carePaths],
  )

  return (
    <>
      <S.SidebarContainer expanding={expanding}>
        <ExpandProfile pulseIds={patientId} />
        <PatientInformation pulseIds={patientId} />
        <S.CarePlanContainer>
          {!isLoading &&
            (!patientInfo?.carePaths ||
              patientInfo?.carePaths.length === 0) && (
              <BodyM>{t('noCarePlans')}</BodyM>
            )}
          {onGoingCarePlans && onGoingCarePlans.length !== 0 && (
            <CarePlansStack
              canSendPDF={!!patientInfo?.email}
              carePlans={onGoingCarePlans}
              onSelect={onSelect}
              onSend={onSend}
              selectedCarePlanId={selectedCarePlanId}
              title={t('onGoingCarePlans')}
            />
          )}
          {pastCarePlans && pastCarePlans.length !== 0 && (
            <CarePlansStack
              canSendPDF={!!patientInfo?.email}
              carePlans={pastCarePlans}
              onSelect={onSelect}
              onSend={onSend}
              selectedCarePlanId={selectedCarePlanId}
              title={t('pastCarePlans')}
            />
          )}
        </S.CarePlanContainer>
      </S.SidebarContainer>
      <Overlay expanding={expanding} />
    </>
  )
}
