import { t } from 'i18next'

import { AppState } from '@/state/stateType'
import { parseStringDate } from '@/util/date'
import { updateProp } from '@/util/object'
import { ConversationCreatedEvent } from '~common/serverToClientParser'

export default function handleConversationCreated(
  state: AppState,
  ev: ConversationCreatedEvent,
): AppState {
  const c = ev.conversation
  const isForUser = !!state.user.userGroups.find(
    (group) => group.id === c.userGroupId,
  )

  if (!isForUser) {
    return state
  }

  new Notification(
    t('chat.notifications.conversationCreated', { user: c.patient?.name }),
  )

  return updateProp(state, 'conversations', (conversations) =>
    conversations.concat([
      {
        assignedToId: c.assignedToId,
        closedAt: parseStringDate(c.closedAt),
        events: c.events,
        id: c.id,
        lastMessageTime: parseStringDate(c.lastMessageTime),
        patient: c.patient,
        priority: c.priority,
        queueId: c.queueId,
        reasonForEntryId: c.reasonForEntryId,
        reasonForEntryTitle: c.reasonForEntryTitle,
        triage: ev.triage,
      },
    ]),
  )
}
