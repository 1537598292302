import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { User } from '@/types/user.ts'

import { useFetchFromAPI } from '../data/useFetchFromAPI.ts'

const userQueryOptions = queryOptions({
  queryKey: ['users'],
})

export const useUsers = () => {
  const fetchAPI = useFetchFromAPI()

  const { data, isLoading } = useQuery<User[]>({
    queryFn: async () => fetchAPI(`admin/users`),
    queryKey: userQueryOptions.queryKey,
    refetchOnWindowFocus: false,
    retryOnMount: false,
  })

  return { isLoading, users: data || [] }
}

type CreateUser = { user: Pick<User, 'email' | 'displayName'> }

type useUserMutationProps = {
  onSuccess?: () => void
}
export const useCreateUser = ({ onSuccess }: useUserMutationProps) => {
  const fetchAPI = useFetchFromAPI('POST')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    User,
    Error,
    CreateUser
  >({
    mutationFn: async ({ user }) => {
      return fetchAPI('admin/users', {
        email: user.email,
        name: user.displayName,
      })
    },
    onSuccess: (data: User) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (oldData: User[]) => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
      onSuccess?.()
    },
  })

  return {
    createUser: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
    newUser: data,
  }
}

type UpdateUser = {
  uid: string
  user: Pick<User, 'email' | 'displayName' | 'customClaims'>
}

export const useUpdateUser = ({ onSuccess }: useUserMutationProps) => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    User,
    unknown,
    UpdateUser
  >({
    mutationFn: async ({ uid, user }) => {
      return fetchAPI(`admin/users/${uid}`, {
        email: user.email,
        name: user.displayName,
        patientId: user.customClaims.patientId,
        roles: user.customClaims.roles,
      })
    },
    onSuccess: (data: User) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (oldData: User[]) => {
        if (oldData) {
          return oldData.map((user) => {
            if (data.uid === user.uid) {
              return data
            }
            return user
          })
        }
        return [data]
      })
      onSuccess?.()
    },
  })

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    updateUser: mutate,
  }
}

type DeleteUser = {
  uid: string
}

export const useDeleteUser = ({ onSuccess }: useUserMutationProps) => {
  const fetchAPI = useFetchFromAPI('DELETE')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    User,
    unknown,
    DeleteUser
  >({
    mutationFn: async ({ uid }) => fetchAPI(`admin/users/${uid}`),
    onSuccess: (_, deletedUser) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (oldData: User[]) => {
        if (oldData) {
          return oldData.filter((user) => user.uid !== deletedUser.uid)
        }
        return []
      })
      onSuccess?.()
    },
  })

  return {
    deleteUser: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
  }
}

export const usePrefetchUsers = () => {
  const queryClient = useQueryClient()
  const fetchAPI = useFetchFromAPI()

  return () =>
    queryClient.prefetchQuery({
      queryFn: async () => fetchAPI(`admin/users`),
      queryKey: userQueryOptions.queryKey,
    })
}
