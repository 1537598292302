import styled from 'styled-components'

import { BaseGridContainer } from '@/components/templates/GridContainer.tsx'
import { SIDEBAR_WIDTH } from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'

export const SidebarContainer = styled.aside`
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 100%;
  overflow: auto;
  width: ${SIDEBAR_WIDTH}px;
`

export const GridContainer = styled(BaseGridContainer)`
  grid-template-columns: 1fr fit-content(${SIDEBAR_WIDTH}px);
  grid-template-rows: 1fr;
`
