export function setProp<T extends object, N extends keyof T>(
  obj: T,
  name: N,
  value: T[N],
): T {
  return updateProp(obj, name, () => value)
}

export function updateProp<T extends object, N extends keyof T>(
  obj: T,
  name: N,
  f: (v: T[N]) => T[N],
): T {
  const newValue = f(obj[name])
  return newValue === obj[name] ? obj : { ...obj, [name]: newValue }
}

export function setProps<T extends object>(obj: T, upd: Partial<T>): T {
  return Object.keys(upd).reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc, key) => setProp(acc, key as any, (upd as any)[key]),
    obj,
  )
}
