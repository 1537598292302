import { useQuery } from '@tanstack/react-query'

import { useFetchFromAPI } from '../data/useFetchFromAPI.ts'

export type Organization = {
  code: string
  ids: string[]
  medicalCategory?: string
  name: string
}

export const useOrganizations = () => {
  const fetchAPI = useFetchFromAPI()

  const { data: organizations, isLoading } = useQuery<Organization[]>({
    queryFn: async () => fetchAPI(`admin/organizations`),
    queryKey: ['organizations'],
  })

  return { isLoading, organizations }
}
