import { Skeleton } from '@/components/common/Skeleton.tsx'

export const TabSkeleton = () => (
  <>
    <Skeleton width={60} />
    <Skeleton gap={8} height={70} width={440} />
    <Skeleton gap={8} height={70} width={440} />
    <Skeleton gap={8} height={70} width={440} />
  </>
)
