import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { z } from 'zod'

import {
  StyledActionButton,
  StyledDestructiveButton,
} from '@/components/common/Buttons.tsx'
import { Checkbox } from '@/components/common/Checkbox'
import {
  ModalActionButtons,
  ModalBackground,
  StyledModal,
} from '@/components/common/Modal.tsx'
import { BodyMedium, BodySmall, Header3 } from '@/components/common/Text.tsx'
import { TextArea } from '@/components/common/TextArea'
import { useAuthentication } from '@/contexts/useAuthenticationContext'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useCreateChatSummary } from '@/hooks/data/usePatientChatSummary'
import { useActiveConversation } from '@/state/hooks/useActiveConversation'
import { useCurrentUser } from '@/state/hooks/useCurrentUser'
import { useInternalConversation } from '@/state/hooks/useInternalConversation.ts'
import { getConversationAttachments } from '@/state/stateUtil'
import { ENABLE_SAVE_CHAT_SUMMARY } from '@/util/featureToggles'
import { getAttachmentFullUrl } from '@/util/storage'
import { Attachment } from '~common/clientToServerParser'

const CloseChatModal = styled(StyledModal)`
  width: 640px;
  text-align: left;
  align-items: self-start;
`

const SummaryTextArea = styled(TextArea)`
  border-radius: 4px;
  min-height: 100px;
  text-align: left;
`

export const CloseConversationModal = ({
  closeModal,
}: {
  closeModal: () => void
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  const [disabled, setDisabled] = useState<boolean>(false)

  const currentUser = useCurrentUser()
  const { token } = useAuthentication()
  const activeConversation = useActiveConversation()
  const internalConversation = useInternalConversation()
  const attachments = getConversationAttachments(activeConversation)

  const { closeActiveConversation, closeInternalConversation } = useSocket()
  const { createChatSummary } = useCreateChatSummary()

  const [modalContainer] = useState(document.getElementById('modal-container'))
  const [saveReport, setSaveReport] = useState<boolean>(false)
  const [summary, setSummary] = useState<string>('')
  const [includeImages, setIncludeImages] = useState<boolean>(false)

  const closeChat = async () => {
    if (activeConversation && saveReport) {
      setDisabled(true)
      const imagesUrls = includeImages
        ? await getAttachmentsFullUrl(token, attachments)
        : []

      createChatSummary({
        chatSummary: {
          attachments: imagesUrls,
          author: {
            id: currentUser.id,
            name: currentUser.name,
          },
          content: summary,
        },
        patientId: activeConversation.patient.pulseIds!,
      })
    }

    if (internalConversation?.id) {
      closeInternalConversation(internalConversation.id)
    }
    closeActiveConversation()
    closeModal()
    setDisabled(false)
  }

  return (
    modalContainer &&
    createPortal(
      <ModalBackground closeModal={closeModal}>
        <CloseChatModal>
          <Header3>{t('close.conversation.header')}</Header3>
          <p>{t('close.conversation.text')}</p>
          {ENABLE_SAVE_CHAT_SUMMARY && (
            <>
              <Checkbox
                checked={saveReport}
                label={t('close.saveReport')}
                onChange={() => setSaveReport(!saveReport)}
              />
              {saveReport && (
                <>
                  <div>
                    <BodyMedium>{t('close.patientNotes')}</BodyMedium>
                    <BodySmall>{t('close.triageNote')}</BodySmall>
                  </div>
                  <SummaryTextArea
                    onChange={(e) => setSummary(e.target.value)}
                    rows={4}
                    value={summary}
                    withoutAttachment
                  />
                  {attachments.length > 0 && (
                    <Checkbox
                      checked={includeImages}
                      disabled={!saveReport}
                      label={t('close.includeImages')}
                      onChange={() => setIncludeImages(!includeImages)}
                    />
                  )}
                </>
              )}
            </>
          )}

          <ModalActionButtons>
            <StyledActionButton onClick={closeModal} variant="Secondary">
              {t('close.cancel')}
            </StyledActionButton>
            <StyledDestructiveButton
              data-testid="confirmFinishConversationButton"
              disabled={disabled}
              onClick={closeChat}
              variant="Primary"
            >
              {saveReport
                ? t('close.confirmAndSaveReport')
                : t('close.confirm')}
            </StyledDestructiveButton>
          </ModalActionButtons>
        </CloseChatModal>
      </ModalBackground>,
      modalContainer,
    )
  )
}
async function getAttachmentsFullUrl(
  token: string | null,
  attachments: Attachment[],
) {
  const serviceChatUrl = import.meta.env.API_URL

  if (!token) {
    return []
  }

  const promises = attachments.map(async (a) => {
    return await getAttachmentFullUrl(serviceChatUrl, token, a.url)
  })

  const result = await Promise.all(promises)

  const imagesUrlSchema = z.array(
    z.object({
      url: z.string(),
    }),
  )

  return imagesUrlSchema.parse(result)
}
