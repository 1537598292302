import { useContext } from 'react'

import { SocketContext } from './SocketContext'

export const useSocket = () => {
  const context = useContext(SocketContext)

  if (!context) {
    throw new Error('useSocket must be used within a SocketContext provider')
  }

  return context
}
