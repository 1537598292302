import i18n from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormFieldType } from '@/components/admin/FormField.tsx'
import { Role } from '~common/auth/role'

export const useRoleFormFields = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.roles',
  })

  const roleFormFields: Array<FormFieldType<Role>> = useMemo(
    () => [
      {
        id: 'name',
        label: t('columns.name'),
        type: 'text',
      },
      {
        id: 'value',
        label: t('columns.value'),
        type: 'text',
      },
      {
        id: 'description',
        label: t('columns.description'),
        type: 'text',
      },
      {
        displayValue: 'name',
        id: 'permissions',
        label: t('columns.permissions'),
        type: 'multiselect',
        value: 'id',
      },
      {
        id: 'azureGroups',
        label: t('columns.azureGroups'),
        type: 'tagInput',
      },
    ],
    [i18n.language],
  )
  return {
    roleFormFields,
  }
}
