import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Program } from '@/types/program'

import { useFetchFromAPI } from './useFetchFromAPI'

export const usePatientProgram = (patientId?: string) => {
  const fetchAPI = useFetchFromAPI()

  const {
    data: program,
    isFetched,
    isFetching,
    isLoading,
    refetch,
  } = useQuery<string, unknown, Program>({
    enabled: Boolean(patientId),
    queryFn: async () =>
      fetchAPI(`admin/hand-therapy/programs?patientId=${patientId}`),
    queryKey: ['patientProgram', patientId],
    refetchOnWindowFocus: false,
    retry: false,
  })

  return {
    fetchPatientProgram: refetch,
    isFetched,
    isFetching,
    isLoading,
    program,
  }
}

type CreateProgramVariables = { program: Omit<Program, 'id'> }

export const useCreatePatientProgram = () => {
  const queryClient = useQueryClient()
  const fetchAPI = useFetchFromAPI('POST')

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    Program,
    unknown,
    CreateProgramVariables
  >({
    mutationFn: async ({ program }) => {
      return fetchAPI('admin/hand-therapy/programs', program)
    },
    onSuccess: (_, { program }) => {
      queryClient.invalidateQueries({
        queryKey: ['patientProgram', program.patientId],
      })
    },
  })

  return {
    createPatientProgram: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
    newPatientProgram: data,
  }
}

type UpdateProgramVariables = {
  program: Omit<Program, 'id'>
  programId: string
}

export const useUpdatePatientProgram = () => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Program,
    unknown,
    UpdateProgramVariables
  >({
    mutationFn: async ({ program, programId }) => {
      return fetchAPI(`admin/hand-therapy/programs/${programId}`, program)
    },
    onSuccess: (_, { program }) => {
      queryClient.invalidateQueries({
        queryKey: ['patientProgram', program.patientId],
      })
    },
  })

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    updatePatientProgram: mutate,
  }
}
