import { Switch as HUSwitch } from '@headlessui/react'
import styled from 'styled-components'

const StyledSwitch = styled(HUSwitch)`
  width: 44px;
  height: 24px;
  margin: 6px 0;
  display: block;
  padding: 2px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.common.mediumBackground};
  transition: background-color 0.3s;

  &[aria-checked='true'] {
    background-color: ${({ theme }) => theme.colors.common.accentDark};
    span {
      transform: translateX(20px);
    }
  }
`

const StyledSwitchCircle = styled.span`
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  transform: translateX(0);
  transition: transform 0.3s;
`

type SwitchProps = {
  checked?: boolean
  onChange: (checked: boolean, e?: Event) => void
}

export const Switch = ({ checked, onChange }: SwitchProps) => {
  return (
    <StyledSwitch
      checked={checked ?? false}
      onClick={(e: Event) => {
        onChange(!checked, e)
      }}
    >
      <span className="sr-only"></span>
      <StyledSwitchCircle />
    </StyledSwitch>
  )
}
