import { useAuthentication } from '@/contexts/useAuthenticationContext'
import i18n from '@/i18n'

export const useFetchFromAPI = (method = 'GET') => {
  const { user } = useAuthentication()

  return async <T, TBody>(urn: string, body?: TBody): Promise<T> =>
    fetch(`${import.meta.env.BACKEND_API_URL}/${urn}`, {
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Accept: 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${await user?.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method,
    }).then((response) => {
      if (response.ok) {
        if (response.status === 204) {
          return
        }
        return response.json()
      } else {
        throw new Error(`Failed fetching ${urn}`)
      }
    })
}
