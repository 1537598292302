import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MonthPicker } from '@/components/admin/MonthPicker.tsx'
import { YearPicker } from '@/components/admin/YearPicker.tsx'
import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { Header3 } from '@/components/common/Text.tsx'
import { useMetrics } from '@/hooks/admin/useMetrics.ts'
import { BarChart } from '@/screens/admin/UserManagement/dashboard/Charts/BarChart.tsx'
import {
  ChartPlaceholder,
  PieChart,
} from '@/screens/admin/UserManagement/dashboard/Charts/PieChart.tsx'
import { ChatResponseCharts } from '@/screens/admin/UserManagement/dashboard/ChatDashboard/ChatResponseCharts.tsx'
import * as S from '@/screens/admin/UserManagement/dashboard/Dashboard.styled.ts'
import { DashboardCard } from '@/screens/admin/UserManagement/dashboard/DashboardCard.tsx'

const DATE_FORMAT = 'YYYY-MM-DD'

export const ChatDashboard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminDashboard.chats',
  })
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [selectedYear, setSelectedYear] = useState(dayjs().year())

  const { isLoading, metrics: { conversations } = {} } = useMetrics(
    dayjs().year(selectedYear).startOf('year').format(DATE_FORMAT),
    dayjs().year(selectedYear).endOf('year').format(DATE_FORMAT),
  )
  const conversationsByMonth =
    conversations?.byMonth.map((month, index) => ({
      ...month,
      month: dayjs().month(index).format('MMM'),
    })) || []

  const currentMonthMetrics = conversationsByMonth[selectedDate.month()]

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return (
    <S.Section>
      <S.HeaderBar>
        <Header3>{t('title')}</Header3>
        <YearPicker onChange={setSelectedYear} selected={selectedYear} />
      </S.HeaderBar>
      <S.Row>
        <DashboardCard
          caption={t('chart.conversations.caption', {
            year: selectedYear,
          })}
          title={t('chart.conversations.title')}
        >
          <BarChart
            bar1DataKey="open"
            bar2DataKey="closed"
            data={conversationsByMonth || []}
            xDataKey="month"
          />
        </DashboardCard>
      </S.Row>
      <S.Row>
        <ChatResponseCharts selectedYear={selectedYear} />
      </S.Row>
      <S.HeaderBar>
        <Header3>{t('monthly')}</Header3>
        <MonthPicker
          filterByYear={selectedYear}
          onChange={setSelectedDate}
          selected={selectedDate}
        />
      </S.HeaderBar>
      <S.Row>
        <DashboardCard
          caption={t('chart.reasons.caption', {
            total: currentMonthMetrics?.total,
          })}
          title={t('chart.reasons.title')}
          highlighted
        >
          {currentMonthMetrics?.byReasons &&
          !currentMonthMetrics.byReasons.every(
            (reason) => reason.count === 0,
          ) ? (
            <PieChart
              data={currentMonthMetrics.byReasons
                .filter((reason) => reason.count > 0)
                .map((reason) => ({
                  count: reason.count,
                  key: reason.reason,
                }))}
            />
          ) : (
            <ChartPlaceholder />
          )}
        </DashboardCard>
        <DashboardCard
          caption={t('chart.closedOpened.caption', {
            total: currentMonthMetrics?.total,
          })}
          title={t('chart.closedOpened.title')}
          highlighted
        >
          {currentMonthMetrics?.byReasons.every(
            (reason) => reason.count === 0,
          ) ? (
            <ChartPlaceholder />
          ) : (
            <PieChart
              data={[
                {
                  count: currentMonthMetrics?.open,
                  key: t('chart.closedOpened.key.open'),
                },
                {
                  count: currentMonthMetrics?.closed,
                  key: t('chart.closedOpened.key.closed'),
                },
              ]}
            />
          )}
        </DashboardCard>
      </S.Row>
    </S.Section>
  )
}
