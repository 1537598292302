import React, { ForwardedRef, forwardRef } from 'react'
import styled from 'styled-components'

import UrgentFillSvg from '@/assets/svgs/urgent-fill.svg'
import { Stack } from '@/components/common/Stack.tsx'
import { TitleSmall } from '@/components/common/Text.tsx'

const Wrapper = styled.div`
  & + & {
    margin-top: 16px;
  }
`

const Title = styled(TitleSmall)`
  margin-bottom: 12px;
`

const UrgentIcon = styled.img`
  vertical-align: top;
  margin-left: 8px;
`

type CardStackProps = {
  children: React.ReactNode
  isUrgent?: boolean
  title?: string
}

export const CardStack = forwardRef(
  (
    { children, isUrgent = false, title }: CardStackProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <Wrapper ref={ref}>
      {title && (
        <Title>
          {title}
          {isUrgent && <UrgentIcon alt="Urgent icon" src={UrgentFillSvg} />}
        </Title>
      )}
      <Stack direction="column" spacing={8}>
        {children}
      </Stack>
    </Wrapper>
  ),
)
CardStack.displayName = 'CardStack'
