import { useTranslation } from 'react-i18next'

import { BodyM } from '@/components/common/Text.tsx'

export const NoContent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.chats',
  })

  return <BodyM>{t('noContent')}</BodyM>
}
