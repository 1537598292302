import { MenuItems as HeadlessMenuItems } from '@headlessui/react'
import styled from 'styled-components'

import { Stack } from '@/components/common/Stack.tsx'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`

export const MenuItems = styled(HeadlessMenuItems)`
  position: absolute;
  right: 0;
  top: ${NAVBAR_HEIGHT + 10}px;
  min-width: 12rem;
  z-index: 9999;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.common.darkBackground};
  border: 1px solid ${(props) => props.theme.colors.common.border};
  color: ${(props) => props.theme.colors.common.white};
  display: inline-block;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`

export const AdminPanelContainer = styled(Stack)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
`

export const AdminMenu = styled(Stack)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
`

export const NavButton = styled.button`
  padding: 12px 20px;
  text-align: left;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.common.white};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black05};
  }
`
