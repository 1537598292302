import { InputHTMLAttributes, useEffect, useState } from 'react'
import styled from 'styled-components'

import { textSizeS } from '@/styles/theme/fonts.ts'

export const Label = styled.label`
  ${textSizeS};
  font-weight: 500;

  & > input,
  & > button {
    margin-top: 4px;
  }
`

export const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  background-color: ${({ theme }) => theme.colors.common.white};
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  ${textSizeS};
`

export const TextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  background-color: ${({ theme }) => theme.colors.common.white};
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  ${textSizeS};
`

export const DebouncedInput = ({
  debounce = 500,
  onChange,
  value: initialValue,
  ...props
}: {
  debounce?: number
  onChange: (value: string | number) => void
  value: string | number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <Input
      {...props}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  )
}
