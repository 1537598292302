import {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  usePatientProspectuses,
  useUpdatePatientProspectus,
} from '@/hooks/data/usePatientProspectus.ts'
import { useProspectuses } from '@/hooks/data/useProspectus.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'
import { Prospectus } from '@/types/prospectus.ts'

enum SidebarType {
  PatientInfo = 'patientInfo',
  Prospectus = 'prospectus',
}

interface ProspectusContextType {
  actions: {
    addProspectus: (prospectus: Prospectus) => void
    closeProspectusSidebar: () => void
    confirmUpdateProspectuses: () => void
    openProspectusSidebar: () => void
    removeProspectus: (id: string) => void
    resetUpdateProspectuses: () => void
  }
  isDirty: boolean
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  prospectuses: Prospectus[]
  sidebar: SidebarType
}

const ProspectusContext = createContext<ProspectusContextType | undefined>(
  undefined,
)

const ProspectsProvider = ({ children }: PropsWithChildren) => {
  const { patientId } = usePatientPulseIdFromParams()
  const { patientProspectuses } = usePatientProspectuses(patientId || '')
  const { isLoading, prospectuses: allProspectuses } = useProspectuses()
  const {
    isError,
    isLoading: isLoadingUpdate,
    isSuccess,
    updatePatientProspectus,
  } = useUpdatePatientProspectus()

  const initialProspectuses = useRef<Prospectus[]>()

  const [sidebar, setSidebar] = useState(SidebarType.PatientInfo)
  const [prospectuses, setProspectuses] = useState<Prospectus[]>([])
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const matchProspectuses = patientProspectuses
      ?.map((patientProspectus) => {
        return allProspectuses?.find(
          (prospectus) => prospectus.id === patientProspectus.contentfulId,
        )
      })
      .filter((prospectus) => prospectus) as Prospectus[]

    if (matchProspectuses) {
      initialProspectuses.current = matchProspectuses
      setProspectuses(matchProspectuses)
    }
  }, [allProspectuses, patientProspectuses])

  useEffect(() => {
    if (isError) {
      resetUpdateProspectuses()
    }
  }, [isError])

  const addProspectus = (prospectus: Prospectus) => {
    setProspectuses([...prospectuses, prospectus])
    setIsDirty(true)
  }

  const removeProspectus = (id: string) => {
    setProspectuses(prospectuses.filter((prospectus) => prospectus.id !== id))
    setIsDirty(true)
  }

  const closeProspectusSidebar = () => {
    setSidebar(SidebarType.PatientInfo)
  }

  const openProspectusSidebar = () => {
    setSidebar(SidebarType.Prospectus)
  }

  const confirmUpdateProspectuses = () => {
    setIsDirty(false)
    updatePatientProspectus({
      patientId: patientId!,
      prospectuses: prospectuses.map(({ id }) => id),
    })
  }

  const resetUpdateProspectuses = () => {
    setIsDirty(false)
    setProspectuses(initialProspectuses.current || [])
  }

  return (
    <ProspectusContext.Provider
      value={{
        actions: {
          addProspectus,
          closeProspectusSidebar,
          confirmUpdateProspectuses,
          openProspectusSidebar,
          removeProspectus,
          resetUpdateProspectuses,
        },
        isDirty,
        isError,
        isLoading: isLoading || isLoadingUpdate,
        isSuccess,
        prospectuses,
        sidebar,
      }}
    >
      {children}
    </ProspectusContext.Provider>
  )
}

export { ProspectsProvider, ProspectusContext, SidebarType }
