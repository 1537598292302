export type TagColors = {
  backgroundColor: string
  color: string
}
export const tagColors: Array<TagColors> = [
  { backgroundColor: '#f5e6cc', color: '#5a3b20' }, // Light Beige / Darker Brown
  { backgroundColor: '#d3eaf7', color: '#3b5c72' }, // Pastel Blue / Darker Medium Blue
  { backgroundColor: '#d7efd4', color: '#3b5c3b' }, // Light Green / Darker Green
  { backgroundColor: '#f7d1d4', color: '#8a4c56' }, // Pastel Pink / Darker Dusky Pink
  { backgroundColor: '#fbe4c3', color: '#8a4c1d' }, // Light Peach / Darker Tan
  { backgroundColor: '#e4d7f5', color: '#6a52b3' }, // Lavender / Darker Purple
  { backgroundColor: '#f9f2b8', color: '#8b6432' }, // Light Yellow / Darker Peru
  { backgroundColor: '#ffd8b1', color: '#8b4513' }, // Light Coral / Darker Saddle Brown
  { backgroundColor: '#f0fff0', color: '#006400' }, // Honeydew / Dark Green
]
