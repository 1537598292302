import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header4 } from '../common/Text'

export const InboxContainer = styled.div`
  background-color: ${(props) => props.theme.colors.common.white};
  display: flex;
  align-items: center;
  padding: 0px 24px;
  min-height: 64px;
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`

export function Inbox() {
  const { t } = useTranslation('translation', { keyPrefix: 'chat' })
  return (
    <InboxContainer>
      <Header4>{t('inbox')}</Header4>
    </InboxContainer>
  )
}
