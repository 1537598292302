import { useEffect } from 'react'
import styled from 'styled-components'

import { store, useAppStore } from '@/app'
import { BaseGridContainer } from '@/components/templates/GridContainer.tsx'
import { Inbox } from '@/components/templates/Inbox'
import { SIDEBAR_WIDTH } from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'
import { ActiveConversationContainer } from '@/screens/Chat/ActiveConversation.tsx'
import { NoActiveConversationContainer } from '@/screens/Chat/NoActiveConversation.tsx'
import {
  getActiveConversation,
  getConversationsAssignedToMe,
  getUnassignedConversations,
} from '@/state/stateUtil'

const GridContainer = styled(BaseGridContainer)`
  grid-template-columns: 362px 1fr ${SIDEBAR_WIDTH}px;
  grid-template-rows: 1fr 11fr fit-content(300px);
`

export function Chat() {
  const state = useAppStore()
  const activeConversation = getActiveConversation(state)
  const assignedConversations = getConversationsAssignedToMe(state)
  const unassignedConversations = getUnassignedConversations(state)
  const setActiveConversation = store.setActiveConversation

  useEffect(() => {
    setActiveConversation(undefined)
  }, [])

  return (
    <GridContainer>
      <Inbox />
      {activeConversation ? (
        <ActiveConversationContainer
          activeConversation={activeConversation}
          assignedConversations={assignedConversations}
          setActiveConversation={setActiveConversation}
          unassignedConversations={unassignedConversations}
        />
      ) : (
        <NoActiveConversationContainer
          assignedConversations={assignedConversations}
          setActiveConversation={setActiveConversation}
          unassignedConversations={unassignedConversations}
        />
      )}
    </GridContainer>
  )
}
