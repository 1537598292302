import { MenuItems } from '@headlessui/react'
import styled from 'styled-components'

export const StyledMenu = styled.div`
  position: relative;
`
export const StyledMenuItems = styled(MenuItems)`
  display: inline-block;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
`

export const ChevronButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  height: 24px;

  &:disabled svg path {
    fill: #7f7f7f;
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

export const PickerButton = styled.button<{ active: boolean }>`
  padding: 8px 16px;
  text-align: center;
  &:disabled {
    color: #7f7f7f;
  }

  background-color: ${({ active, theme }) =>
    active ? theme.colors.common.accentDark : 'transparent'};
  color: ${({ active, theme }) =>
    active ? theme.colors.common.white : theme.colors.common.black};
`

export const MenuButton = styled.button`
  background-color: ${({ theme }) => theme.colors.common.accentDark};
  color: white;
  border-color: ${({ theme }) => theme.colors.common.accentDark};
  border-radius: 4px;
  padding: 8px 16px;
`
