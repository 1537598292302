import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormFieldType } from '@/components/admin/FormField.tsx'
import i18n from '@/i18n'
import { Queue } from '@/types/queue.ts'

export const useQueueFormFields = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.queues',
  })

  const queueFormFields: Array<FormFieldType<Queue>> = useMemo(
    () => [
      {
        id: 'title',
        label: t('columns.title'),
        type: 'text',
      },
      {
        id: 'description',
        label: t('columns.description'),
        type: 'text',
      },
      {
        displayValue: 'name',
        id: 'userGroups',
        label: t('columns.userGroups'),
        type: 'multiselect',
        value: 'id',
      },
    ],
    [i18n.language],
  )
  return {
    queueFormFields,
  }
}
