import React from 'react'
import { RxCross2 } from 'react-icons/rx'

import { tagColors } from '@/screens/admin/UserManagement/tagColors.ts'

import * as S from './Tags.styled.ts'

export type Tag = {
  id: string | number
  name: string
}

type TagsProps = {
  className?: string
  colorMap?: TagColorMap
  data: Array<Tag> | Array<string>
  onDelete?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    itemId: string | number,
  ) => void
}

type TagColorMap = {
  [key: string]: { backgroundColor: string; color: string; name: string }
}
export const getTagsData = (
  data?: Array<Tag> | Array<string>,
  prevData?: TagColorMap,
): {
  [key: string]: { backgroundColor: string; color: string; name: string }
} => {
  if (!data) return {}
  const parsedData = data.map((item) => {
    if (typeof item === 'string') {
      return { id: item, name: item }
    }
    return item
  })

  return parsedData.reduce((acc, item, currentIndex) => {
    if (prevData && prevData[item.id]) {
      acc[item.id] = prevData[item.id]
    } else {
      const itemWithColor = tagColors[currentIndex % tagColors.length]
      if (itemWithColor) {
        acc[item.id] = {
          backgroundColor: itemWithColor.backgroundColor,
          color: itemWithColor.color,
          name: item.name,
        }
      }
    }
    return acc
  }, {} as TagColorMap)
}

export const Tags = ({ className, colorMap, data, onDelete }: TagsProps) => {
  const parsedData = data.map((item) => {
    if (typeof item === 'string') {
      return { id: item, name: item }
    }
    return item
  })

  return (
    <S.Tags className={className}>
      {parsedData.map((item) => {
        const itemWithColor = colorMap?.[item.id]

        return (
          <S.Tag
            key={item.id}
            backgroundColor={itemWithColor?.backgroundColor}
            color={itemWithColor?.color}
          >
            {item.name}
            {onDelete && (
              <S.TagDeleteButton onClick={(e) => onDelete(e, item.id)}>
                <RxCross2 height={16} width={16} />
              </S.TagDeleteButton>
            )}
          </S.Tag>
        )
      })}
    </S.Tags>
  )
}
