import { TabList as HeadlessTabList } from '@headlessui/react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { BodyS } from '@/components/common/Text.tsx'

export const NAVBAR_HEIGHT = 64
export const Logo = styled.img`
  width: 150px;
  padding: 0 86px;
`
export const Navbar = styled.div`
  flex: none;
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.common.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
  padding: 0 20px;
`

export const TabList = styled(HeadlessTabList)`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
`

export const TabItemLink = styled(NavLink)`
  --border-width: 4px;
  color: ${({ theme }) => theme.colors.common.black};
  padding: 4px 16px;
  height: 100%;
  border-bottom: var(--border-width) solid transparent;
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;

  &:hover,
  &:active {
    opacity: 1;
    background: ${({ theme }) => theme.colors.common.black05};
  }

  &.active {
    opacity: 1;
    border-bottom-color: black;
    background: ${({ theme }) => theme.colors.common.black10};
  }
`

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`

export const Label = styled(BodyS)`
  color: ${({ theme }) => theme.colors.common.black};
  font-weight: 500;
`
