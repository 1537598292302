import styled, { keyframes } from 'styled-components'

const ACCENT_COLOR = '#D40A8B'
const ACCENT_COLOR_LIGHT = '#DBE0E6'

const rotate = keyframes`
  0% { transform: rotate(0deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(1.2) }
  100% { transform: rotate(720deg) scale(0.8) }
`

const ball1 = keyframes`
  0% {
    box-shadow: 30px 0 0 ${ACCENT_COLOR};
  }
  50% {
    box-shadow: 0 0 0 ${ACCENT_COLOR};
    margin-bottom: 0;
    transform: translate(15px, 15px);
}
  100% {
    box-shadow: 30px 0 0 ${ACCENT_COLOR};
    margin-bottom: 10px;
}
`

const ball2 = keyframes`
  0% {
    box-shadow: 30px 0 0 ${ACCENT_COLOR_LIGHT};
}
  50% {
    box-shadow: 0 0 0 ${ACCENT_COLOR_LIGHT};
  margin-top: -20px;
  transform: translate(15px, 15px);
}
  100% {
    box-shadow: 30px 0 0 ${ACCENT_COLOR_LIGHT};
  margin-top: 0;
}
`

export const Spinner = styled.span`
  animation: ${rotate} 1.5s infinite;
  height: 50px;
  width: 50px;

  &::before,
  &::after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }

  &::before {
    animation: ${ball1} 1.5s infinite;
    background-color: ${ACCENT_COLOR_LIGHT};
    box-shadow: 30px 0 0 ${ACCENT_COLOR};
    margin-bottom: 10px;
  }

  &::after {
    animation: ${ball2} 1.5s infinite;
    background-color: ${ACCENT_COLOR};
    box-shadow: 30px 0 0 ${ACCENT_COLOR_LIGHT};
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
