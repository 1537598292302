import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { StyledTable, TableCell, TableRow } from '@/components/admin/Table.tsx'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { ModalBackground, StyledModal } from '@/components/common/Modal.tsx'
import { Spinner } from '@/components/common/Spinner.tsx'
import { BodyS, Header2 } from '@/components/common/Text.tsx'
import { ActionButtons } from '@/screens/admin/UserManagement/ActionButtons.tsx'
import * as S from '@/screens/admin/UserManagement/featureFlags/ExpandFeatureFlag.styled.ts'

const LABEL = 'LABEL_OSMCHRIJVING'
const CSV_SEPARATOR = ';'

export const UploadFileModal = ({
  closeModal,
  onSave,
}: {
  closeModal: () => void
  onSave: (labels: string[]) => void
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.featureFlags.expandFeatureFlag.uploadFileModal',
  })
  const theme = useTheme()
  const [labels, setLabels] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  async function importLabelsFromCSV(file: File): Promise<string[]> {
    const labels: string[] = []
    if (!file) {
      throw new Error(t('error.noFileProvided'))
    }

    if (!file.name.endsWith('.csv')) {
      throw new Error(t('error.fileNotCSV'))
    }

    const text = await file.text()
    const rows = text.split('\n')
    const header = rows[0].split(CSV_SEPARATOR)
    const labelIndex = header.indexOf(LABEL)

    if (labelIndex === -1) {
      throw new Error(LABEL + ' column not found')
    }

    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(CSV_SEPARATOR)
      const label = columns[labelIndex]?.trim()

      if (label) {
        labels.push(label)
      }
    }

    return labels
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)

    if (e.target.files) {
      const file = e.target.files[0]
      try {
        const importedLabels = await importLabelsFromCSV(file)
        setLabels(importedLabels)
        setError(null)
      } catch (err) {
        setError((err as Error).message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDelete = useCallback(
    (value: string) => {
      setLabels(labels.filter((val) => val !== value))
    },
    [labels, setLabels],
  )

  const handleSave = useCallback(() => {
    onSave(labels)
    closeModal()
  }, [labels, closeModal, onSave])

  return (
    <ModalBackground closeModal={closeModal}>
      <StyledModal>
        <Header2>{t('title')}</Header2>
        <input type="file" onChange={handleFileChange} />
        {error && <BodyS color={theme.colors.common.alert}>{error}</BodyS>}
        {loading && <Spinner />}
        {labels && labels.length > 0 && (
          <S.ValuesBlock>
            <StyledTable>
              {labels.map((value) => (
                <TableRow key={value}>
                  <TableCell>
                    <BodyS>{value}</BodyS>
                  </TableCell>
                  <TableCell>
                    <ActionButtons
                      onDelete={() => {
                        handleDelete(value)
                      }}
                    ></ActionButtons>
                  </TableCell>
                </TableRow>
              ))}
            </StyledTable>
          </S.ValuesBlock>
        )}
        <StyledActionButton variant="Primary" onClick={handleSave}>
          {t('actions.save')}
        </StyledActionButton>
      </StyledModal>
    </ModalBackground>
  )
}
