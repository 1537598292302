import ReactJson from '@microlink/react-json-view'
import styled from 'styled-components'

import { ModalBackground, StyledModal } from '@/components/common/Modal.tsx'
import { Header2 } from '@/components/common/Text.tsx'

const Modal = styled(StyledModal)`
  max-height: 100%;
  overflow-y: auto;
  width: 70%;
`

type ContentModalProps = {
  closeModal: () => void
  data: any
  title?: string
}
export const ContentModal = ({
  closeModal,
  data,
  title,
}: ContentModalProps) => {
  return (
    <ModalBackground closeModal={closeModal}>
      <Modal>
        <Header2>{title}</Header2>
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          src={data}
          style={{ width: '100%' }}
          displayArrayKey={false}
        />
      </Modal>
    </ModalBackground>
  )
}
