import {
  ExpandProfile,
  useExpandProfileTransition,
} from '@/components/templates/PatientSidebar/ExpandProfile.tsx'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

import { InformationTabs } from './InformationTabs.tsx'
import { PatientInformation } from './PatientInformation.tsx'
import {
  InformationTabsContainer,
  Overlay,
  SidebarContainer,
} from './Sidebar.styled.tsx'

export const PatientSidebar = ({ pulseIds }: { pulseIds?: string }) => {
  const { patientId } = usePatientPulseIdFromParams()
  const id = pulseIds ?? patientId
  const { expanding } = useExpandProfileTransition()

  return (
    <>
      <SidebarContainer expanding={expanding}>
        <ExpandProfile pulseIds={id} />
        <PatientInformation pulseIds={id} />
        <InformationTabsContainer>
          <InformationTabs pulseIds={id} />
        </InformationTabsContainer>
      </SidebarContainer>
      <Overlay expanding={expanding} />
    </>
  )
}
