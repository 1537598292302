import styled from 'styled-components'

import { BodyM, Header4 } from '@/components/common/Text.tsx'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useActiveConversation } from '@/state/hooks/useActiveConversation.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { useQueues } from '@/state/hooks/useQueues.ts'

import { AssignConversation } from './AssignConversation/AssignConversation.tsx'
import { CloseConversation } from './CloseConversation/CloseConversation.tsx'
import { QueuesDropdown } from './QueuesDropdown.tsx'
import { ReturnToQueue } from './ReturnConversationToQueue/ReturnToQueue.tsx'
import { StartConversation } from './StartConversation.tsx'

const ActionContainer = styled.div`
  grid-column: 2/4;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border-bottom: 1px solid ${(props) => props.theme.colors.common.border};
`

const FlexContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

const Dropdown = styled(QueuesDropdown)`
  max-width: 500px;
`

export function ActionBar() {
  const clinician = useCurrentUser()
  const activeConversation = useActiveConversation()
  const queues = useQueues()

  const isAssignedToCurrentUser =
    activeConversation?.assignedToId === clinician.id

  const isInQueue = !activeConversation?.assignedToId

  const isInternalChat =
    activeConversation?.internalConversation?.assignedToId === clinician.id

  const { changeActiveConversationQueue } = useSocket()

  return (
    <ActionContainer>
      <FlexContainer>
        <Header4>{activeConversation?.patient.name}</Header4>
        {isInternalChat ? (
          <BodyM fontWeight="bold">
            {activeConversation.reasonForEntryTitle}
          </BodyM>
        ) : (
          <Dropdown
            onSelect={changeActiveConversationQueue}
            queues={queues}
            selectedQueueId={activeConversation?.queueId}
          />
        )}
      </FlexContainer>
      {!isInternalChat && (
        <FlexContainer>
          <AssignConversation />
          {isAssignedToCurrentUser && (
            <>
              <ReturnToQueue />
              <CloseConversation />
            </>
          )}
          {isInQueue && <StartConversation />}
        </FlexContainer>
      )}
    </ActionContainer>
  )
}
