export function mapSelected<T>(
  pred: (t: T) => boolean,
  map: (t: T) => T,
  arr: T[],
): T[] {
  let changeCount = 0
  const res = arr.map((i) => {
    const newValue = pred(i) ? map(i) : i
    if (newValue !== i) {
      changeCount += 1
    }
    return newValue
  })
  return changeCount > 0 ? res : arr
}

export function filterMap<T, S = T>(
  pred: (t: T) => boolean,
  map: (t: T) => S,
  arr: T[],
): S[] {
  const res: S[] = []
  let changeCount = 0
  arr.forEach((i) => {
    if (pred(i)) {
      const newValue = map(i)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (newValue !== (i as any)) {
        changeCount += 1
      }
      res.push(newValue)
    }
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return changeCount > 0 || res.length !== arr.length ? res : (arr as any)
}

export function setAt<T>(arr: T[], index: number, value: T): T[] {
  return updateAt(arr, index, () => value)
}

export function updateAt<T>(arr: T[], index: number, f: (v: T) => T): T[] {
  if (index < 0 || index >= arr.length) {
    return arr
  }
  const newValue = f(arr[index])
  return newValue === arr[index]
    ? arr
    : [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

export function findLastIndex<T>(arr: T[], pred: (t: T) => boolean): number {
  for (let i = arr.length - 1; i >= 0; i -= 1) {
    if (pred(arr[i])) return i
  }
  return -1
}

export function partition<T>(arr: T[], g: (t: T) => string): T[][] {
  const result: T[][] = []
  if (arr.length === 0) {
    return result
  }
  let current: T[] = []
  let key = g(arr[0])
  for (let i = 0; i < arr.length; i += 1) {
    const newKey = g(arr[i])
    if (newKey !== key) {
      key = newKey
      result.push(current)
      current = []
    }
    current.push(arr[i])
  }
  if (current.length > 0) {
    result.push(current)
  }
  return result
}

export function dropWhile<T>(arr: T[], pred: (t: T) => boolean): T[] {
  // find first index where predicate is false
  const idx = arr.findIndex((v) => !pred(v))
  return arr.slice(idx)
}

export interface Items<T> {
  item: (i: number) => T | null
  length: number
}

export function itemsToArray<T>(items: Items<T> | null): T[] {
  const result: T[] = []
  if (items) {
    for (let i = 0; i < items.length; i += 1) {
      const value = items.item(i)
      if (value) {
        result.push(value)
      }
    }
  }
  return result
}
