import {
  DetailFields,
  Exercise,
  FrequencyTime,
  WearDuring,
} from '@/types/exercise'
import { Program } from '@/types/program'

import { FormData } from './Exercises'

export const mapFormDefaultValues = (program: Program) => {
  const formValues: Record<string, any> = {}

  const splintFields: DetailFields = ['wearDuring', 'instructions']
  const exerciseFields: DetailFields = [
    'frequency',
    'rest',
    'repetitions',
    'sets',
    'frequencyTime',
    'instructions',
  ]

  program.exercises.forEach((exercise) => {
    exerciseFields.forEach((field) => {
      formValues[`${field}_${exercise.contentfulId}`] =
        exercise.details?.[field]
    })

    if (exercise.instructions) {
      formValues[`instructions_${exercise.contentfulId}`] =
        exercise.instructions
    }
    return
  })

  program.splints.forEach((splint) => {
    splintFields.forEach((field) => {
      formValues[`${field}_${splint.contentfulId}`] = splint.details?.[field]
    })

    if (splint.instructions) {
      formValues[`instructions_${splint.contentfulId}`] = splint.instructions
    }
    return
  })

  return formValues
}

export const mapFormExercise = ({
  exercise,
  formData,
}: {
  exercise: Exercise
  formData: FormData
}) => {
  const fieldKeys = {
    frequency: `frequency_${exercise.contentfulId}`,
    frequencyTime: `frequencyTime_${exercise.contentfulId}`,
    instructions: `instructions_${exercise.contentfulId}`,
    repetitions: `repetitions_${exercise.contentfulId}`,
    rest: `rest_${exercise.contentfulId}`,
    sets: `sets_${exercise.contentfulId}`,
  }
  return {
    ...exercise,
    details: {
      frequency: Number(formData[fieldKeys.frequency]) || undefined,
      frequencyTime: formData[fieldKeys.frequencyTime] as FrequencyTime,
      repetitions: Number(formData[fieldKeys.repetitions]) || undefined,
      rest: formData[fieldKeys.rest],
      sets: Number(formData[fieldKeys.sets]) || undefined,
    },
    instructions: formData[fieldKeys.instructions],
  }
}

export const mapFormSplint = ({
  formData,
  splint,
}: {
  formData: FormData
  splint: Exercise
}) => {
  const fieldKeys = {
    instructions: `instructions_${splint.contentfulId}`,
    wearDuring: `wearDuring_${splint.contentfulId}`,
  }

  return {
    ...splint,
    details: {
      wearDuring: (formData[fieldKeys.wearDuring] as WearDuring) ?? undefined,
    },
    instructions: formData[fieldKeys.instructions],
  }
}
