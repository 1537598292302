import { RxArrowLeft, RxPencil1 } from 'react-icons/rx'
import styled from 'styled-components'

import { BodyM } from '@/components/common/Text.tsx'

const ButtonStyled = styled.button`
  display: flex;
  gap: 8px;
  color: ${(props) => props.theme.colors.primary.main};

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const LinkButtonText = styled(BodyM)`
  color: ${({ theme }) => theme.colors.common.accentDark};
`

export const BackLinkButton = ({
  className,
  label,
  onClick,
}: {
  className?: string
  label: string
  onClick: () => void
}) => (
  <ButtonStyled className={className} onClick={onClick}>
    <RxArrowLeft />
    <LinkButtonText fontWeight="bold">{label}</LinkButtonText>
  </ButtonStyled>
)

export const EditLinkButton = ({
  className,
  label,
  onClick,
}: {
  className?: string
  label: string
  onClick: () => void
}) => (
  <ButtonStyled className={className} onClick={onClick}>
    <LinkButtonText fontWeight="bold">{label}</LinkButtonText>
    <RxPencil1 height={24} width={24} />
  </ButtonStyled>
)
