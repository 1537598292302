import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { NavigationBar } from '@/components/templates/NavigationBar'
import { ExpandProfileTransitionProvider } from '@/components/templates/PatientSidebar/ExpandProfile.tsx'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
`

export const DashboardLayout = () => (
  <Layout>
    <NavigationBar />
    <Content>
      <ExpandProfileTransitionProvider>
        <Outlet />
      </ExpandProfileTransitionProvider>
    </Content>
  </Layout>
)
