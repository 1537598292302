import styled from 'styled-components'

type TagProps = {
  backgroundColor?: string
  color?: string
}
export const Tag = styled.div<TagProps>`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 2px;
  line-height: 1;
  font-weight: 400;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.common.mediumBackground};
  color: ${({ color, theme }) => color || theme.colors.common.black};
`

export const Tags = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`

export const TagDeleteButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.common.black};
  cursor: pointer;
  margin: 0 4px;
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;
  }
`
