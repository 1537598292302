import { useMutation, useQuery } from '@tanstack/react-query'

import { useAuthentication } from '@/contexts/useAuthenticationContext.ts'
import { useFetchFromAPI } from '@/hooks/data/useFetchFromAPI.ts'
import i18n from '@/i18n'

export const useCarePlanPDF = (carePlanId: string) => {
  const { user } = useAuthentication()
  const { data, error, isLoading } = useQuery({
    queryFn: async () => {
      return await fetch(
        `${import.meta.env.BACKEND_API_URL}/admin/care-plans/${carePlanId}/pdf`,
        {
          headers: {
            'Accept-Language': i18n.language,
            Authorization: `Bearer ${await user?.getIdToken()}`,
            'Content-Type': 'application/pdf',
          },
          method: 'GET',
        },
      ).then(async (response) => {
        if (response.ok) {
          const pdfBlob = await response.blob()
          return URL.createObjectURL(pdfBlob)
        } else {
          throw new Error(`Failed fetching pdf ${carePlanId}`)
        }
      })
    },
    queryKey: ['pdf', carePlanId],
  })

  return {
    data,
    error,
    isLoading,
  }
}

export const useSendCarePlanPDF = ({
  onSuccess,
}: {
  onSuccess?: () => void
}) => {
  const fetchAPI = useFetchFromAPI('POST')

  const { isError, isPending, isSuccess, mutate, reset } = useMutation<
    unknown,
    unknown,
    { carePlanId: string; email?: string; language?: string }
  >({
    mutationFn: async ({ carePlanId, email, language }) => {
      return fetchAPI(`admin/care-plans/${carePlanId}/pdf/send`, {
        email,
        language,
      })
    },
    onSuccess,
  })

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    reset,
    sendPDFEmail: mutate,
  }
}
