import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ highlighted?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.common.black05 : '#ffffff'};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px 32px;
  min-width: 200px;
  min-height: 120px;
`

const Title = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
`

const Caption = styled.p`
  font-size: 14px;
  color: #92979d;
`

type DashboardCardProps = {
  caption?: string
  children?: React.ReactNode
  highlighted?: boolean
  title: string
}

export const DashboardValue = styled.p`
  font-size: 28px;
  font-weight: 600;
  color: #000000;
`

export const DashboardCard = ({
  caption,
  children,
  highlighted,
  title,
}: DashboardCardProps) => {
  return (
    <Wrapper highlighted={highlighted}>
      <Title>{title}</Title>
      {children}
      {caption && <Caption>{caption}</Caption>}
    </Wrapper>
  )
}
