import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  animation: fadein 1s linear alternate infinite;
  animation-delay: 0.5s;

  @keyframes fadein {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }
`

export const FullScreenLoader = () => {
  return (
    <Container>
      <Img
        alt="Equipe Zorgbedrijven"
        src="/equipe.svg"
        style={{
          margin: 'auto',
          width: '250px',
        }}
      />
    </Container>
  )
}
