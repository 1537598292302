/*
  Wraps a function to another function. When calling the returned wrapper
  function, it will call the original function at maximum of `callsPerSecond`
  times per second.
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttle<F extends (...p: any[]) => any>(
  f: F,
  callsPerSecond: number,
): (...p: Parameters<F>) => ReturnType<F> {
  let lastTime = 0
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: ReturnType<F> = null as any
  const delta = 1000 / callsPerSecond
  return (...p) => {
    const now = Date.now()
    if (now - lastTime >= delta) {
      lastTime = now
      result = f(...p)
    }
    return result
  }
}
