import { z } from 'zod'

export const Language = {
  en: 'en' as const,
  nl: 'nl' as const,
}

export const DEFAULT_LANGUAGE = Language.nl

export const LanguageParser = z.enum([Language.en, Language.nl])

export type Language = z.infer<typeof LanguageParser>
