import { AppState } from '@/state/stateType'
import { mapSelected } from '@/util/array'
import { setProps } from '@/util/object'
import { ConversationUnassignedEvent } from '~common/serverToClientParser'

export default function handleConversationUnassigned(
  state: AppState,
  ev: ConversationUnassignedEvent,
): AppState {
  const updatedConversations = mapSelected(
    (c) => c.id === ev.conversationId,
    (c) => ({
      ...c,
      assignedToId: undefined,
      events: c.events?.concat([ev]),
    }),
    state.conversations,
  )

  return setProps(state, {
    activeConversationId: undefined,
    conversations: updatedConversations,
  })
}
