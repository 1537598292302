import styled from 'styled-components'

import { BodySmall } from '@/components/common/Text'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
`

export const Main = styled.main`
  width: 720px;
  margin-bottom: 100px;
`

export const SearchInputWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 24px;
  gap: 4px;

  input {
    width: 100%;
  }
`

export const Section = styled.section`
  position: relative;
`

export const IconButton = styled.button`
  color: ${({ theme }) => theme.colors.common.black62};
  margin-left: auto;
  display: flex;
  align-items: center;

  &:focus {
    outline-style: dotted;
  }
`

export const InstructionText = styled(BodySmall)`
  margin: 8px 16px;
  color: ${({ theme }) => theme.colors.common.black62};
`

export const ClearButton = styled.button`
  text-transform: capitalize;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.common.darkSteelBlue};
  padding: 0;
`

type SelectionProps = {
  focused?: boolean
}

export const Selection = styled.button<SelectionProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.colors.common.black10};
  border-top: 1px solid transparent;
  width: 100%;
  gap: 20px;

  background-color: ${({ focused, theme }) =>
    focused ? theme.colors.common.periwinkleExtraLight : 'initial'};
`

export const Results = styled.div`
  position: absolute;
  width: 100%;
`

export const ResultList = styled.ul`
  max-height: 260px;
  overflow-y: auto;
`

export const Checkmark = styled.p`
  flex: 0 0 40px;
`

export const PatientName = styled.p`
  flex: 1;
`

export const PatientIds = styled.div`
  display: flex;
  gap: 8px;
`

export const PatientId = styled.h4`
  font-size: 16px;
  font-weight: 500;
`
