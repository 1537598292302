import { useParams } from 'react-router-dom'

import { SelectPatientPathParams } from '@/router/paths.ts'

export const usePatientPulseIdFromParams = () => {
  const { patientId } = useParams<SelectPatientPathParams>()

  return {
    patientId: patientId ? atob(patientId) : undefined,
  }
}
