import { initializeApp } from 'firebase/app'
import * as fbAuth from 'firebase/auth'

const firebaseConfig = {
  apiKey: import.meta.env.FIREBASE_API_KEY,
  appId: import.meta.env.FIREBASE_APP_ID,
  authDomain: import.meta.env.FIREBASE_AUTH_DOMAIN,
  messagingSenderId: import.meta.env.FIREBASE_MESSAGING_SENDER_ID,
  projectId: import.meta.env.FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.FIREBASE_STORAGE_BUCKET,
}

// Initialize Firebase
export const azureAD = new fbAuth.OAuthProvider('microsoft.com')

export const firebase = initializeApp(firebaseConfig)
export const firebaseAuth = fbAuth.getAuth(firebase)

type Tail<T extends any[]> = ((...args: T) => any) extends (
  head: any,
  ...tail: infer Tail
) => any
  ? Tail
  : never

type OmitFirst<F extends (...args: any) => any> = Tail<Parameters<F>>

export const signInWithRedirect = (
  ...params: OmitFirst<typeof fbAuth.signInWithRedirect>
) => fbAuth.signInWithRedirect(firebaseAuth, ...params)

export const signInWithPopup = (
  resolver?: Parameters<typeof fbAuth.signInWithPopup>,
) => {
  fbAuth.setPersistence(firebaseAuth, fbAuth.browserSessionPersistence)
  return fbAuth.signInWithPopup(firebaseAuth, azureAD, resolver)
}

export const sendSignInLinkToEmail = (
  // ...params: OmitFirst<typeof fbAuth.sendSignInLinkToEmail>
  email: string,
) => {
  // fbAuth.sendSignInLinkToEmail(firebaseAuth, ...params)
  // Use backend to send custom email instead of firebase
  return fetch(
    `${import.meta.env.BACKEND_API_URL}/auth/signIn?url=${encodeURI(
      window.location.href,
    )}`,
    {
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  )
}

export const signInWithEmailLink = (
  ...params: OmitFirst<typeof fbAuth.signInWithEmailLink>
) => fbAuth.signInWithEmailLink(firebaseAuth, ...params)

export const isSignInWithEmailLink = () => {
  return fbAuth.isSignInWithEmailLink(firebaseAuth, window.location.href)
}
