import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  StyledActionButton,
  StyledDestructiveButton,
} from '@/components/common/Buttons.tsx'
import {
  ModalActionButtons,
  ModalBackground,
  StyledModal,
} from '@/components/common/Modal.tsx'
import { Header3 } from '@/components/common/Text.tsx'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useInternalConversation } from '@/state/hooks/useInternalConversation.ts'

const CloseChatModal = styled(StyledModal)`
  width: 440px;
`

export const CloseInternalConversationModal = ({
  closeModal,
}: {
  closeModal: () => void
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  const [modalContainer] = useState(document.getElementById('modal-container'))
  const internalConversation = useInternalConversation()
  const { closeInternalConversation } = useSocket()

  const handleCloseInternalConversation = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // TODO: check behaviour
    e.stopPropagation()
    e.preventDefault()
    if (internalConversation?.id) {
      closeInternalConversation(internalConversation.id)
    }
    closeModal()
  }

  return (
    modalContainer &&
    createPortal(
      <ModalBackground closeModal={closeModal}>
        <CloseChatModal>
          <Header3>{t('close.internal.header')}</Header3>
          <p>{t('close.internal.text')}</p>
          <ModalActionButtons>
            <StyledActionButton onClick={closeModal} variant="Secondary">
              {t('close.cancel')}
            </StyledActionButton>
            <StyledDestructiveButton
              data-testid="confirmFinishInternalConversationButton"
              onClick={handleCloseInternalConversation}
              variant="Primary"
            >
              {t('close.confirm')}
            </StyledDestructiveButton>
          </ModalActionButtons>
        </CloseChatModal>
      </ModalBackground>,
      modalContainer,
    )
  )
}
