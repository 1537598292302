import styled, { keyframes } from 'styled-components'

type SkeletonProps = {
  gap?: number
  height?: number
  width?: number
}

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`
export const Skeleton = styled.div<SkeletonProps>`
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  height: ${({ height }) => height || 17}px;
  width: ${({ width }) => width || 100}px;
  margin-bottom: ${({ gap }) => gap || 12}px;
  background-color: ${({ theme }) => theme.colors.common.black10};

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );

    animation: ${shimmer} 2s infinite;
  }
`
