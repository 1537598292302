import { useQuery } from '@tanstack/react-query'

import { Exercise } from '@/types/exercise'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useExercises = (type: string) => {
  const fetchAPI = useFetchFromAPI()

  const { data: exercises, isLoading } = useQuery<Exercise[]>({
    queryFn: async () => fetchAPI(`admin/hand-therapy/exercises?type=${type}`),
    queryKey: ['exercises', type],
  })

  return { exercises, isLoading }
}
