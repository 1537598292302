import { ConversationPriority } from '~common/commonParser'
import * as S from '~common/serverToClientParser'

interface BaseConversation {
  assignedToId?: S.UUID
  assignedToName?: string
  closedAt?: Date
  createdById?: S.UUID
  id: S.UUID
  lastMessageTime?: Date
}

export interface InternalConversation extends BaseConversation {
  conversationId?: string
  createdByName?: string
  events?: S.InternalConversationEvent[]
}

export interface Triage {
  answers: Record<string, string>
  conversationId: S.UUID
  id: S.UUID
  pulseQuestionnaireId: string
}

export interface Conversation extends BaseConversation {
  events: S.ConversationEvent[]
  internalConversation?: InternalConversation
  isAttachmentLoading?: boolean
  patient: Patient
  priority: ConversationPriority
  queueId: S.UUID
  reasonForEntryId: S.UUID
  reasonForEntryTitle: string
  triage?: Triage
  typingUser?: { id: string; name?: string }
}

export interface User {
  email: string
  externalId: string
  id: S.UUID
  imageUrl?: string
  language: S.Language
  name: string
  permissions: string[]
  userGroups: { id: string; name: string }[]
}

export interface Clinician {
  id: S.UUID
  imageUrl?: string
  name: string
  permissions?: string[]
}

export interface Patient {
  id: S.UUID
  name: string
  pulseIds: string | null
}

export interface Queue {
  description: string
  id: S.UUID
  title: string
  userGroups: string[]
}

export interface ReasonForEntryType {
  id: S.UUID
  priority: ConversationPriority
  queueId: S.UUID
  title: string
}

export interface AppState {
  activeConversationId?: S.UUID
  // Loaded images url -> image data as url
  attachments: Record<string, Promise<string>>
  clinicians: Clinician[]
  conversations: Conversation[]
  error?: string
  isInitialized: boolean
  isLoading: boolean
  loggedInUserId?: S.UUID
  notification?: string
  queues: Queue[]
  reasonsForEntry: ReasonForEntryType[]
  user: User
}

export const EmptyAppState: AppState = {
  activeConversationId: undefined,
  attachments: {},
  clinicians: [],
  conversations: [],
  error: undefined,
  isInitialized: false,
  isLoading: false,
  loggedInUserId: undefined,
  notification: undefined,
  queues: [],
  reasonsForEntry: [],
  user: {
    email: '',
    externalId: '',
    id: '',
    imageUrl: '',
    language: 'nl',
    name: '',
    permissions: [],
    userGroups: [],
  },
}
