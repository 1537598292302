import { t } from 'i18next'

import { AppState } from '@/state/stateType'
import { setProps, updateProp } from '@/util/object'
import { InternalConversationCreated } from '~common/serverToClientParser'

export default function handleInternalConversationCreated(
  state: AppState,
  ev: InternalConversationCreated,
): AppState {
  new Notification(
    t('chat.notifications.internalConversationCreated', { user: ev.userName }),
  )

  const activeConversationId = state.activeConversationId
  if (activeConversationId) {
    return updateProp(state, 'conversations', (conversations) => {
      return conversations.map((conversation) => {
        if (conversation.id === activeConversationId) {
          return {
            ...conversation,
            internalConversation: ev.internalConversation,
          }
        }
        return conversation
      })
    })
  }
  return setProps(state, {
    activeConversationId: ev.id,
    conversations: [...state.conversations, ev],
  })
}
