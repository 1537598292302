import { flexRender, Row, Table } from '@tanstack/react-table'
import React, { useCallback, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { RxDragHandleHorizontal } from 'react-icons/rx'

import { FilterInput } from '@/components/admin/FilterInput.tsx'
import {
  CenteredCell,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
  TableStyled,
} from '@/components/admin/Table.tsx'
import { ContentModal } from '@/screens/admin/UserManagement/ContentModal.tsx'
import { FeatureFlagFromAPI } from '@/types/featureFlag.ts'
import { Permission } from '@/types/permission.ts'
import { Queue } from '@/types/queue.ts'
import { Reason } from '@/types/reason.ts'
import { User } from '@/types/user.ts'
import { Role } from '~common/auth/role'

export const DND_ITEM_TYPE = 'row'

export const RowDragHandleCell = () => {
  return (
    <CenteredCell>
      <button>
        <RxDragHandleHorizontal color="#222222bf" height={18} width={18} />
      </button>
    </CenteredCell>
  )
}

type DraggableRowProps<T> = {
  moveRow: (fromId: string, toId: string) => void
  onDragEnd: (item: T, rowId: string) => void
  row: Row<T>
}

type TableItem = User | Role | Permission | Queue | Reason | FeatureFlagFromAPI

const DraggableRow = <T extends TableItem>({
  moveRow,
  onDragEnd,
  row,
  ...rest
}: DraggableRowProps<T>) => {
  const { id: rowId, index } = row
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: { index: number; rowId: string }) {
      if (item.rowId !== rowId) {
        moveRow(item.rowId, rowId)
        item.index = index
      }
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index, rowId },
    type: DND_ITEM_TYPE,
  })

  return (
    <TableRow
      ref={(node) => preview(drag(drop(node)))}
      style={{
        backgroundColor: 'white',
        opacity: isDragging ? 0.5 : 1,
      }}
      {...rest}
    >
      {row.getVisibleCells().map((cell) => {
        if (cell.column.columnDef.id === 'drag-handle') {
          return (
            <TableCell key={cell.id}>
              <RowDragHandleCell />
            </TableCell>
          )
        }
        return (
          <TableCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

type ContentTableProps<T> = {
  data?: T[]
  isDragEnabled?: boolean
  modalOnRowClick?: boolean
  table: Table<T>
  updateData?: (items: T[]) => void
  onRowClick?: (row: T) => void
}
export const ContentTable = <T extends TableItem>({
  data = [],
  isDragEnabled = false,
  modalOnRowClick = false,
  onRowClick,
  table,
  updateData: updateLocalData,
}: ContentTableProps<T>) => {
  const [itemToShow, setItemToShow] = useState<T | null>()

  const moveRow = useCallback(
    (fromId: string, toId: string) => {
      const fromIndex = table
        .getRowModel()
        .rows.findIndex((item) => item.id === fromId)
      const toIndex = table
        .getRowModel()
        .rows.findIndex((item) => item.id === toId)
      const newData = [...data]
      const [removed] = newData.splice(fromIndex, 1)
      newData.splice(toIndex, 0, removed)
      updateLocalData?.(newData)
    },
    [data, updateLocalData, table],
  )

  const handleRowClick = useCallback(
    (item: T) => {
      onRowClick?.(item)
      if (modalOnRowClick) {
        setItemToShow(item)
      }
    },
    [modalOnRowClick, onRowClick],
  )

  const Row: React.ElementType = isDragEnabled ? DraggableRow : TableRow

  return (
    <>
      <TableStyled>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableHeadRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {header.column.getCanFilter() && (
                    <FilterInput column={header.column} table={table} />
                  )}
                </TableHead>
              ))}
            </TableHeadRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <Row
              key={row.id}
              onClick={() => handleRowClick(row.original)}
              {...(isDragEnabled
                ? {
                    moveRow,
                    row,
                  }
                : {})}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                )
              })}
            </Row>
          ))}
        </TableBody>
      </TableStyled>
      {modalOnRowClick && itemToShow && (
        <ContentModal
          closeModal={() => setItemToShow(null)}
          data={itemToShow}
          title="Data"
        />
      )}
    </>
  )
}
