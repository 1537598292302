import { Popover as HPopover } from '@headlessui/react'
import dayjs from 'dayjs'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import DefaultAvatar from '@/assets/svgs/avatar.svg'
import { LogoutIcon } from '@/components/common/Icons.tsx'
import { Stack } from '@/components/common/Stack.tsx'
import { BodyM, BodyS, BodySmall } from '@/components/common/Text.tsx'
import { supportedLangs } from '@/i18n/index.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { firebaseAuth } from '@/util/firebase.ts'

import * as S from './UserInfo.styled.ts'

export const UserInfo = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'navbar',
  })
  const clinician = useCurrentUser()

  return (
    <HPopover>
      <S.PopoverButton>
        <S.Avatar src={clinician.imageUrl ?? DefaultAvatar}></S.Avatar>
      </S.PopoverButton>
      <S.Panel>
        <S.PanelContent>
          <Stack direction="column" spacing={0}>
            <S.UserData direction="column" spacing={8}>
              <div>
                <BodyM color="white" fontWeight="bold">
                  {clinician.name}
                </BodyM>
                <BodySmall color="white">{clinician.email}</BodySmall>
              </div>
              <BodyS color="white" fontWeight="bold">
                {clinician.userGroups.map((group) => group.name).join(' | ')}
              </BodyS>
            </S.UserData>
            <S.LanguageSelection>
              {supportedLangs.map((lang) => (
                <S.LangButton
                  key={lang}
                  onClick={() => {
                    i18n.changeLanguage(lang)
                    dayjs.locale(i18n.language)
                  }}
                  selected={lang === i18n.language}
                >
                  {lang}
                </S.LangButton>
              ))}
            </S.LanguageSelection>
            <S.LogoutStack direction="row" spacing={6}>
              <S.IconButton
                onClick={() => firebaseAuth.signOut()}
                variant="Primary"
              >
                <p>{t('logout')}</p>
                <LogoutIcon color="white" />
              </S.IconButton>
            </S.LogoutStack>
          </Stack>
        </S.PanelContent>
      </S.Panel>
    </HPopover>
  )
}
