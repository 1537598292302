import { AppState } from '@/state/stateType.ts'
import { updateProp } from '@/util/object'
import { InternalConversationEvent } from '~common/serverToClientParser'

export default function handleInternalChatMessageAdded(
  state: AppState,
  ev: InternalConversationEvent,
): AppState {
  const activeConversationId = state.activeConversationId
  return updateProp(state, 'conversations', (conversations) => {
    return conversations.map((conversation) => {
      if (conversation.id === activeConversationId) {
        return {
          ...conversation,
          internalConversation: {
            ...conversation.internalConversation,
            events: [...(conversation.internalConversation?.events || []), ev],
          },
        }
      }
      return conversation
    })
  })
}
