import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxChevronDown, RxChevronUp } from 'react-icons/rx'
import styled from 'styled-components'

import { BackLinkButton } from '@/components/common/LinkButton.tsx'
import { BodyM, BodyS } from '@/components/common/Text.tsx'
import { PlainChatContent } from '@/components/templates/ChatArea/ChatContent.tsx'
import { useConversationEvents } from '@/hooks/data/useConversationEvents.ts'
import { useInternalConversationEvents } from '@/hooks/data/useInternalConversationEvents.ts'
import { Conversation, InternalConversation } from '@/types/conversation.ts'
import { ConversationEvent } from '~common/serverToClientParser.ts'

import { DateSpan } from '../DateSpan.tsx'
import { CardContainer, CardWrapper } from '../Sidebar.styled.tsx'

const ChatCardContainer = styled(CardContainer)`
  flex-direction: column;
  width: 100%;
  gap: 4px;
  border-radius: 0;
  border-bottom-width: 0;
`

const ChatDisclosureButton = styled(DisclosureButton)`
  width: 100%;
`

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: row;
`

const ChatContentContainer = styled.div`
  padding: 12px 20px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-bottom-width: 0;
`

const ButtonIcon = styled.span`
  flex: none;
  color: ${(props) => props.theme.colors.common.black75};
  align-items: center;
  display: flex;
`

const DisclosureList = styled.div`
  margin-top: 24px;
`

const Wrapper = styled.div`
  &:first-of-type ${ChatCardContainer} {
    border-radius: 5px 5px 0 0;
  }

  &:last-of-type ${ChatCardContainer} {
    border-bottom-width: 1px;
  }

  &:last-of-type ${ChatContentContainer} {
    border-bottom-width: 1px;
    border-top: 0;
  }
`

type ChatCardProps = {
  assignedToId?: string
  caption?: string | null
  createdById?: string
  date?: string | null
  defaultOpen?: boolean
  events: ConversationEvent[]
  hideOldAttachments?: boolean
  isClosed?: boolean
  name: string
  onClick?: () => void
}

export const Card = ({
  assignedToId,
  caption,
  createdById,
  date,
  defaultOpen = false,
  events,
  hideOldAttachments,
  name,
  onClick,
}: ChatCardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.chats',
  })
  const [active, setActive] = useState<boolean>(defaultOpen)

  const oneWeekAgo = dayjs().subtract(1, 'week')
  const hideAttachments = hideOldAttachments && dayjs(date).isBefore(oneWeekAgo)
  const isClosed = !!date
  return (
    <Disclosure as={Wrapper}>
      <ChatDisclosureButton
        as={isClosed ? DisclosureButton : 'div'}
        onClick={
          isClosed ? () => setActive((prevState) => !prevState) : undefined
        }
      >
        <ChatCardContainer
          onClick={onClick}
          role={onClick ? 'button' : undefined}
        >
          <FlexContainer>
            <CardWrapper>
              <FlexContainer>
                <BodyM color="black" fontWeight="bold">
                  {name}
                </BodyM>
                {date && <DateSpan date={date} />}
              </FlexContainer>
              <BodyS>{caption}</BodyS>
            </CardWrapper>
            {isClosed && (
              <ButtonIcon>
                {active ? <RxChevronUp /> : <RxChevronDown />}
              </ButtonIcon>
            )}
          </FlexContainer>
        </ChatCardContainer>
      </ChatDisclosureButton>
      {active && (
        <DisclosurePanel static>
          <ChatContentContainer>
            {events && events.length > 0 ? (
              <PlainChatContent
                assignedToId={assignedToId}
                createdById={createdById}
                events={events}
                hideAttachments={hideAttachments}
              ></PlainChatContent>
            ) : (
              <BodyS>{t('noEvents')}</BodyS>
            )}
          </ChatContentContainer>
        </DisclosurePanel>
      )}
    </Disclosure>
  )
}

type DetailedChatProps = {
  conversation: Conversation
  internalConversationId: string | null
  onClose: () => void
}

export const ChatDetailed = ({
  conversation,
  internalConversationId,
  onClose,
}: DetailedChatProps) => {
  const { events: conversationEvents } = useConversationEvents(conversation.id)
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.chats',
  })

  return (
    <>
      <BackLinkButton label={t('backToList')} onClick={onClose} />
      <DisclosureList>
        <Card
          caption={conversation.assignedTo}
          date={conversation.closedAt}
          defaultOpen={!internalConversationId}
          events={conversationEvents as ConversationEvent[]}
          hideOldAttachments={true}
          name={conversation.reasonForEntryTitle}
        />
        {conversation.internalConversations.map((internalCoversation) => (
          <InternalChatDetailed
            key={internalCoversation.id}
            activeId={internalConversationId}
            internalConversation={internalCoversation}
          />
        ))}
      </DisclosureList>
    </>
  )
}

type InternalChatDetailed = {
  activeId: string | null
  internalConversation: InternalConversation
}
const InternalChatDetailed = ({
  activeId,
  internalConversation,
}: InternalChatDetailed) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.chats',
  })
  const { events: internalConversationEvents } = useInternalConversationEvents(
    internalConversation.id,
  )

  return (
    <Card
      key={internalConversation.id}
      assignedToId={internalConversation.assignedToId}
      caption={`${internalConversation.assignedToName}, ${internalConversation.createdByName}`}
      createdById={internalConversation.createdById}
      date={internalConversation.closedAt}
      defaultOpen={activeId === internalConversation.id}
      events={internalConversationEvents as ConversationEvent[]}
      name={t('internal.title')}
    />
  )
}
