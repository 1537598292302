import { useEffect } from 'react'

export const useNotificationPermission = () => {
  useEffect(() => {
    if (!('Notification' in window)) {
      alert('This browser does not support notifications.')
    }

    if (Notification.permission !== 'granted') {
      Notification.requestPermission()
    }
  }, [])
}
