import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Conversation } from '@/state/stateType.ts'

import { Queue } from './Queue.tsx'

interface QueuesProps {
  activeConversationId: string | undefined
  assignedConversations: Conversation[]
  setActiveConversation: (active: string) => void
  unassignedConversations: Conversation[]
}

const QueuesContainer = styled.div`
  background-color: ${(props) => props.theme.colors.common.white};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  gap: 12px;
  grid-column: 1;
  grid-row: 2/4;
  border-top: 1px solid ${(props) => props.theme.colors.common.border};
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`

const ActiveConversationsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const UnAssignedConversationsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.common.border};
  margin: 0 24px;
`

export function Queues({
  activeConversationId,
  assignedConversations,
  setActiveConversation,
  unassignedConversations,
}: QueuesProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'chat' })

  return (
    <QueuesContainer>
      {assignedConversations.length > 0 && (
        <>
          <ActiveConversationsContainer>
            <Queue
              activeConversationId={activeConversationId}
              conversations={assignedConversations}
              setActive={setActiveConversation}
              title={t('ongoingConversations')}
              assignedToClinician
            />
          </ActiveConversationsContainer>
          <Divider />
        </>
      )}
      <UnAssignedConversationsContainer>
        <Queue
          activeConversationId={activeConversationId}
          assignedToClinician={false}
          conversations={unassignedConversations}
          setActive={setActiveConversation}
          title={t('queue')}
        />
      </UnAssignedConversationsContainer>
    </QueuesContainer>
  )
}
