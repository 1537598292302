/*

This file contains all the business domain related actions that communicate with the server.

It uses the store.ts to make changes to application state.

*/

import React from 'react'

import { EmptyAppState } from './state/stateType'
import initStore from './store'

export const store = initStore(EmptyAppState)

export function useAppStore() {
  return React.useSyncExternalStore(
    store.subscribe,
    store.getSnapshot,
    () => EmptyAppState,
  )
}

export function setError(message: string | undefined) {
  store.setError(message)
}
