import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '@/state/hooks/useCurrentUser'
import { Permission } from '~common/auth/permissions'

import { Error } from './Errors/Error'

type ProtectedRouteProps = {
  children: JSX.Element
  permission: Permission
}

export const ProtectedRoute = ({
  children,
  permission,
}: ProtectedRouteProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' })
  const user = useCurrentUser()

  if (user.permissions.includes(permission)) {
    return children
  }

  return <Error hasLogoutButton={false} text={t('noPermissions')} />
}
