import styled, { css } from 'styled-components'

interface ButtonProps {
  className?: string
  size?: 'small'
  variant: 'Primary' | 'Secondary' | 'Borderless' | 'Confirm'
}

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.42px;
  transition: background-color 0.2s;
  gap: 8px;

  ${({ disabled }) =>
    disabled &&
    `
       opacity: 0.5;
       cursor: default;
       pointer-events: none;
 `};
`

export const StyledActionButton = styled(StyledButton)`
  ${(props) => {
    switch (props.size) {
      case 'small':
        return css`
          padding: 4px 10px;
        `
      default:
        return css`
          padding: 8px 24px;
        `
    }
  }}

  ${(props) => {
    switch (props.variant) {
      case 'Primary':
        return css`
          color: ${props.theme.colors.primary.text};
          background-color: ${props.theme.colors.primary.main};
          border: 1px solid ${props.theme.colors.primary.main};
        `

      case 'Secondary':
        return css`
          color: ${props.theme.colors.secondary.text};
          border: 1px solid ${props.theme.colors.secondary.text};
          background-color: ${props.theme.colors.secondary.main};
          &:hover {
            color: ${props.theme.colors.secondary.main};
            background-color: ${props.theme.colors.secondary.text};
            border: 1px solid ${props.theme.colors.secondary.text};
          }
        `

      case 'Confirm':
        return css`
          color: ${props.theme.colors.secondary.main};
          background-color: ${props.theme.colors.secondary.text};
          border: 1px solid ${props.theme.colors.secondary.text};
          &:hover {
            background-color: ${props.theme.colors.common.black95};
            border: 1px solid ${props.theme.colors.common.black95};
          }
        `
    }
  }};
`
export const StyledDestructiveButton = styled(StyledButton)`
  ${(props) => {
    switch (props.size) {
      case 'small':
        return css`
          padding: 4px 10px;
        `
      default:
        return css`
          padding: 4px 24px;
        `
    }
  }}

  ${(props) =>
    props.variant === 'Primary'
      ? css`
          color: ${props.theme.colors.destructive.text};
          background-color: ${props.theme.colors.destructive.main};
        `
      : css`
          color: ${props.theme.colors.destructive.main};
        `};

  border: 1px solid ${(props) => props.theme.colors.destructive.main};
  &:hover {
    color: ${(props) => props.theme.colors.destructive.text};
    background-color: ${(props) => props.theme.colors.destructive.main};
  }
`
