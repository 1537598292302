import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Header3 } from '@/components/common/Text.tsx'
import {
  useDeletePermission,
  useUpdatePermission,
} from '@/hooks/admin/usePermissions'
import i18n from '@/i18n'
import { ActionButtons } from '@/screens/admin/UserManagement/ActionButtons.tsx'
import { ContentTable } from '@/screens/admin/UserManagement/ContentTable.tsx'
import { DeleteModal } from '@/screens/admin/UserManagement/DeleteModal.tsx'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { usePermissionFormFields } from '@/screens/admin/UserManagement/permissions/usePermissionFormFields.ts'
import { HeaderBar } from '@/screens/admin/UserManagement/UserManagement.styled.ts'
import { Permission } from '@/types/permission.ts'

type PermissionsTableProps = {
  permissions: Permission[]
}

export const PermissionsTable = ({ permissions }: PermissionsTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.permissions',
  })
  const { permissionFormFields } = usePermissionFormFields()
  const [permissionToDelete, setPermissionToDelete] = useState<string | null>(
    null,
  )
  const [permissionToEdit, setPermissionToEdit] = useState<Permission | null>(
    null,
  )

  const { updatePermission } = useUpdatePermission({
    onSuccess: () => {
      handleStopEditingPermission()
    },
  })

  const { deletePermission } = useDeletePermission({
    onSuccess: () => {
      setPermissionToDelete(null)
    },
  })

  const handleStartEditingPermission = useCallback((permission: Permission) => {
    setPermissionToEdit(permission)
  }, [])

  const handleStopEditingPermission = useCallback(() => {
    setPermissionToEdit(null)
  }, [])

  const columns = useMemo<ColumnDef<Permission>[]>(
    () => [
      {
        accessorKey: 'name',
        enableColumnFilter: false,
        header: t('columns.name'),
      },
      {
        accessorKey: 'description',
        enableColumnFilter: false,
        header: t('columns.description'),
      },
      {
        accessorFn: (row) => row,
        cell: ({ getValue }) => {
          const permission = getValue() as Permission

          return (
            <ActionButtons
              onDelete={() => {
                setPermissionToDelete(permission.id)
              }}
              onEdit={() => {
                handleStartEditingPermission(permission)
              }}
            />
          )
        },
        enableColumnFilter: false,
        header: t('columns.actions'),
        id: 'actions',
      },
    ],
    [i18n.language],
  )
  const table = useReactTable<Permission>({
    columns,
    data: permissions,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <HeaderBar>
        <Header3>{t('title')}</Header3>
      </HeaderBar>
      <ContentTable table={table} />
      {permissionToEdit && (
        <EditModal
          closeModal={handleStopEditingPermission}
          data={permissionToEdit}
          errorMessage={t('editModal.error')}
          formFields={permissionFormFields}
          isError={false}
          onSave={(permission) => {
            updatePermission({
              permission,
              permissionId: permission.id,
            })
          }}
          title={t('editModal.title')}
        />
      )}
      {permissionToDelete && (
        <DeleteModal
          caption={t('deleteModal.caption', {
            name: permissions.find(
              (permission) => permission.id === permissionToDelete,
            )?.name,
          })}
          closeModal={() => {
            setPermissionToDelete(null)
          }}
          errorMessage={t('deleteModal.error')}
          onDelete={() =>
            deletePermission({ permissionId: permissionToDelete })
          }
          title={t('deleteModal.title')}
        />
      )}
    </>
  )
}
