import styled from 'styled-components'

export const Search = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.common.border};
  padding: 10px 16px 10px 16px;
  justify-content: space-between;
  border-radius: 4px;
  align-items: center;
  gap: ${(props) => props.theme.spacing}px;
  input {
    width: 100%;
  }
`
