import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useContactReasons } from '@/hooks/data/useContactReasonts.ts'
import { usePatientConversations } from '@/hooks/data/usePatientConversations.ts'
import { useActiveConversation } from '@/state/hooks/useActiveConversation.ts'
import { Conversation } from '@/types/conversation.ts'

import { CardStack } from '../CardStack.tsx'
import { TabSkeleton } from '../TabSkeleton.tsx'
import { ChatCard } from './ChatCard.tsx'
import { ChatDetailed } from './ChatDetailed.tsx'
import { NoContent } from './NoContent.tsx'

type ChatListProps = {
  patientId: string
}

export const ChatList = ({ patientId }: ChatListProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.chats',
  })
  const activeConversation = useActiveConversation()
  const { conversations, isLoading } = usePatientConversations(patientId)
  const { contactReasons } = useContactReasons()

  const [activeChat, setActiveChat] = useState<Conversation | null>(null)
  const [activeInternalChatId, setActiveInternalChatId] = useState<
    string | null
  >(null)

  // when clicking on other chat(with other patient) on the left sidebar this closes ChatDetailed
  // there was a bug that didn't change the chat tab content when internal chat was opened
  useEffect(() => {
    setActiveChat(null)
  }, [activeConversation])

  if (isLoading) {
    return <TabSkeleton />
  }

  if (!isLoading && !conversations) {
    return <NoContent />
  }

  const mappedConversations = conversations?.map((conversation) => {
    const reason = contactReasons?.find(
      (reason) => reason.id === conversation.reasonForEntryId,
    )
    return {
      ...conversation,
      reasonForEntryTitle: reason?.title ?? conversation.reasonForEntryTitle,
    }
  })

  const ongoingConversations = mappedConversations?.filter(
    (conversation) => !conversation.closedAt,
  )
  const pastConversations = mappedConversations?.filter(
    (conversation) => !!conversation.closedAt,
  )

  const handleClick = (
    conversation: Conversation,
    activeInternalChatId?: string,
  ) => {
    setActiveChat(conversation)
    if (activeInternalChatId) {
      setActiveInternalChatId(activeInternalChatId)
    } else {
      setActiveInternalChatId(null)
    }
  }

  const handleClose = () => {
    setActiveChat(null)
  }

  if (activeChat) {
    return (
      <ChatDetailed
        conversation={activeChat}
        internalConversationId={activeInternalChatId}
        onClose={handleClose}
      />
    )
  }

  return (
    <>
      {ongoingConversations && ongoingConversations.length > 0 && (
        <CardStack title={t('ongoing')}>
          {ongoingConversations.map((conversation) => (
            <ChatCard
              key={conversation.id}
              conversation={conversation}
              onClick={handleClick}
            />
          ))}
        </CardStack>
      )}
      {pastConversations && pastConversations.length > 0 && (
        <CardStack title={t('past')}>
          {pastConversations.map((conversation) => (
            <ChatCard
              key={conversation.id}
              conversation={conversation}
              onClick={handleClick}
            />
          ))}
        </CardStack>
      )}
    </>
  )
}
