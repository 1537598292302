import { set } from 'lodash/fp'
import { Reducer, useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormField,
  FormFieldProps,
  FormFieldType,
} from '@/components/admin/FormField.tsx'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { ModalBackground } from '@/components/common/Modal.tsx'
import { BodyS, Header3 } from '@/components/common/Text.tsx'
import {
  ErrorMessage,
  Modal,
  StyledActionButtons,
} from '@/screens/admin/UserManagement/UserManagement.styled.ts'
import { FeatureFlag } from '@/types/featureFlag.ts'
import { Permission } from '@/types/permission.ts'
import { Queue } from '@/types/queue.ts'
import { ReasonWithOrganizations } from '@/types/reason.ts'
import { User } from '@/types/user.ts'
import { Role } from '~common/auth/role'

type EditModalProps<T> = {
  caption?: string
  closeModal: () => void
  data?: T
  errorMessage?: string
  formFields: FormFieldType<T>[]
  isError?: boolean
  onSave: (data: T) => void
  selectOptions?: FormFieldProps<T>['selectOptions']
  title: string
}
export const EditModal = <
  T extends
    | User
    | Role
    | Permission
    | Queue
    | ReasonWithOrganizations
    | FeatureFlag,
>({
  caption,
  closeModal,
  data,
  errorMessage,
  formFields,
  isError,
  onSave,
  selectOptions,
  title,
}: EditModalProps<T>) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.actions',
  })
  const initialState = data ? data : ({} as T)

  const [updatedData, dispatch] = useReducer<
    Reducer<T, { payload: string | Array<string | Permission>; type: string }>
  >((state, action) => {
    return set(action.type, action.payload, state)
  }, initialState)

  const handleInputChange = useCallback(
    (id: string, value: any) => {
      dispatch({ payload: value, type: id })
    },
    [dispatch],
  )

  const handleSave = useCallback(() => {
    onSave(updatedData)
  }, [onSave, updatedData])

  return (
    <ModalBackground closeModal={closeModal}>
      <Modal>
        <Header3>{title}</Header3>
        {caption && <BodyS>{caption}</BodyS>}
        {formFields.map((field) => {
          const fieldIds = field.id.split('.')
          const prevFieldValue = fieldIds.reduce(
            (acc: any, id) => acc?.[id],
            updatedData,
          )
          return (
            <FormField<T>
              key={field.id}
              field={field}
              onInputChange={handleInputChange}
              selectOptions={selectOptions}
              value={prevFieldValue}
            />
          )
        })}
        <StyledActionButtons>
          <StyledActionButton onClick={closeModal} variant="Secondary">
            {t('cancel')}
          </StyledActionButton>
          <StyledActionButton onClick={handleSave} variant="Primary">
            {t('save')}
          </StyledActionButton>
        </StyledActionButtons>
        {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Modal>
    </ModalBackground>
  )
}
