import { Cell, Pie, PieChart as RePieChart } from 'recharts'
import styled from 'styled-components'

import {
  COLORS,
  CONTAINER_HEIGHT,
  SMALL_CONTAINER_WIDTH,
} from '@/screens/admin/UserManagement/dashboard/Charts/constants.ts'

type ChartProps = {
  data: any[]
}
export const PieChart = ({ data }: ChartProps) => {
  return (
    <RePieChart height={CONTAINER_HEIGHT} width={SMALL_CONTAINER_WIDTH}>
      <Pie
        cx="50%"
        cy="50%"
        data={data}
        dataKey="count"
        fontSize={14}
        label={({ count, key }) => count + ' ' + key}
        nameKey="key"
        outerRadius={100}
      >
        {data.map((_, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </RePieChart>
  )
}

const StyledPlaceholder = styled.div`
  height: ${CONTAINER_HEIGHT}px;
  width: ${SMALL_CONTAINER_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #7f7f7f;
`
export const ChartPlaceholder = () => (
  <StyledPlaceholder>No data available</StyledPlaceholder>
)
