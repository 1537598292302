import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { BodyM, Header2 } from '@/components/common/Text.tsx'

const NavigationCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.common.border};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.common.black05};
`

const CardHeader = styled.div`
  display: flex;
  gap: 8px;
`

const CardTitle = styled(Header2)`
  color: ${({ theme }) => theme.colors.common.accentDark};
`

const OpenButton = styled(StyledActionButton)`
  align-self: flex-start;
`

type NavigationCardProps = {
  description?: string
  disabled?: boolean
  iconSrc?: string
  onClick?: () => void
  title: string
}

export const NavigationCard = ({
  description,
  disabled,
  iconSrc,
  onClick,
  title,
}: NavigationCardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientScreen.navigation',
  })
  return (
    <NavigationCardWrapper>
      <CardHeader>
        <img alt={title} src={iconSrc} />
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <BodyM>{description}</BodyM>
      <OpenButton
        disabled={!onClick || disabled}
        onClick={onClick}
        variant="Secondary"
      >
        {t('openButton')}
      </OpenButton>
    </NavigationCardWrapper>
  )
}
