import { useQuery } from '@tanstack/react-query'

import { Program } from '@/types/program'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useDefaultPrograms = () => {
  const fetchAPI = useFetchFromAPI()

  const {
    data: defaultPrograms,
    isLoading,
    refetch,
  } = useQuery<Program[]>({
    queryFn: async () => fetchAPI(`admin/hand-therapy/default-programs`),
    queryKey: ['default-programs'],
    refetchOnWindowFocus: false,
  })

  return {
    defaultPrograms: defaultPrograms ?? [],
    fetchDefaultPrograms: refetch,
    isLoading,
  }
}
