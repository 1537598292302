import { useQuery } from '@tanstack/react-query'

import { ConversationEvent } from '@/types/conversation'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useConversationEvents = (conversationId: string) => {
  const fetchAPI = useFetchFromAPI()

  const { data: events, isLoading } = useQuery<ConversationEvent[]>({
    queryFn: async () =>
      fetchAPI(`admin/conversations/${conversationId}/events`),
    queryKey: ['conversationEvents', conversationId],
  })

  return { events, isLoading }
}
