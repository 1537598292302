import { useContext } from 'react'

import { HandTherapyContext } from '@/contexts/HandTherapyContext.tsx'

export const useHandTherapyContext = () => {
  const context = useContext(HandTherapyContext)

  if (!context) {
    throw new Error(
      'useHandTherapyContext must be used within a HandTherapyProvider',
    )
  }

  return context
}
