import i18n from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormFieldType } from '@/components/admin/FormField.tsx'
import { ReasonWithOrganizations } from '@/types/reason.ts'

export const useReasonsFormFields = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.reasons',
  })

  const reasonsFormFields: Array<FormFieldType<ReasonWithOrganizations>> =
    useMemo(
      () => [
        {
          id: 'title',
          label: t('columns.title'),
          type: 'text',
        },
        {
          id: 'priority',
          label: t('columns.priority'),
          type: 'select',
        },
        {
          id: 'contentfulId',
          label: t('columns.contentfulId'),
          type: 'text',
        },
        {
          displayValue: 'title',
          id: 'queue',
          label: t('columns.queue'),
          type: 'select',
          value: 'id',
        },
        {
          displayValue: 'name',
          id: 'organizations',
          label: t('columns.organizations'),
          type: 'multiselect',
          value: 'code',
        },
        {
          id: 'isUrgent',
          label: t('columns.urgent'),
          type: 'checkbox',
        },
      ],
      [i18n.language],
    )

  return {
    reasonsFormFields,
  }
}
