import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import i18n from '@/i18n'
import { PatientProspectus } from '@/types/prospectus'

import { useFetchFromAPI } from './useFetchFromAPI'

export const usePatientProspectuses = (patientId: string) => {
  const fetchAPI = useFetchFromAPI()

  const { data: patientProspectuses, isPending: isLoading } = useQuery<
    PatientProspectus[]
  >({
    queryFn: async () => fetchAPI(`admin/prospectuses?patientId=${patientId}`),
    queryKey: ['prospectuses', patientId, i18n.language],
  })

  return { isLoading, patientProspectuses }
}

export const useUpdatePatientProspectus = () => {
  const queryClient = useQueryClient()
  const fetchAPI = useFetchFromAPI('POST')

  const {
    isError,
    isPending: isLoading,
    isSuccess,
    mutate: updatePatientProspectus,
    reset,
  } = useMutation({
    mutationFn: async (data: { patientId: string; prospectuses: string[] }) => {
      return fetchAPI(`admin/prospectuses`, data)
    },
    onSuccess: (_, { patientId }) => {
      queryClient.invalidateQueries({
        queryKey: ['prospectuses', patientId],
      })
    },
  })

  return {
    isError,
    isLoading,
    isSuccess,
    reset,
    updatePatientProspectus,
  }
}
