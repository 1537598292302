import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDestructiveButton } from '@/components/common/Buttons.tsx'

import { CloseConversationModal } from './CloseConversationModal.tsx'

export const CloseConversation = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <StyledDestructiveButton
        data-testid="finishConversationButton"
        onClick={() => setModalIsOpen(true)}
        size="small"
        variant="Secondary"
      >
        {t('actions.close')}
      </StyledDestructiveButton>
      {modalIsOpen && (
        <CloseConversationModal closeModal={() => setModalIsOpen(false)} />
      )}
    </>
  )
}
