import { useTranslation } from 'react-i18next'
import { RxArrowRight } from 'react-icons/rx'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { GridContainer } from '@/components/common/SidebarContainer.tsx'
import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { BodyM, Header1 } from '@/components/common/Text.tsx'
import { Tile } from '@/components/common/Tile.tsx'
import { PatientSidebar } from '@/components/templates/PatientSidebar/Sidebar.tsx'
import { ProspectusSidebar } from '@/components/templates/ProspectsSidebar/ProspectusSidebar.tsx'
import {
  ProspectsProvider,
  SidebarType,
} from '@/contexts/ProspectusContext.tsx'
import { useProspectusContext } from '@/contexts/useProspectusContext.ts'
import { TilesContainer } from '@/screens/PatientHub/Exercises/Exercises.styled.ts'
import * as S from '@/screens/PatientHub/Prospectus/Prospectus.styled.ts'

export const ProspectusScreen = () => {
  return (
    <ProspectsProvider>
      <Content />
    </ProspectsProvider>
  )
}

const Content = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'prospectus',
  })
  const {
    actions: {
      confirmUpdateProspectuses,
      openProspectusSidebar,
      removeProspectus,
      resetUpdateProspectuses,
    },
    isDirty,
    isError,
    isLoading,
    prospectuses,
    sidebar,
  } = useProspectusContext()

  return (
    <GridContainer>
      <S.Main>
        <S.Content>
          <S.Header>
            <Header1>{t('title')}</Header1>
            <StyledActionButton
              disabled={SidebarType.Prospectus === sidebar}
              onClick={openProspectusSidebar}
              variant="Secondary"
            >
              {t('addTreatment')}
              <RxArrowRight height={24} width={24} />
            </StyledActionButton>
          </S.Header>
          {!isDirty && !isLoading && prospectuses.length === 0 && (
            <BodyM>{t('noProspectuses')}</BodyM>
          )}
          {isError && <BodyM color="red">{t('failedToEdit')}</BodyM>}
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <TilesContainer>
              {prospectuses.map((prospectus) => (
                <Tile
                  key={prospectus.id}
                  imageSrc={prospectus.image}
                  onClose={() => removeProspectus(prospectus.id)}
                  title={prospectus.title}
                />
              ))}
            </TilesContainer>
          )}
        </S.Content>
        {isDirty && (
          <S.Actions>
            <StyledActionButton
              onClick={confirmUpdateProspectuses}
              variant="Confirm"
            >
              {t('actions.confirm')}
            </StyledActionButton>
            <S.CancelButton
              onClick={resetUpdateProspectuses}
              variant="Secondary"
            >
              {t('actions.cancel')}
            </S.CancelButton>
          </S.Actions>
        )}
      </S.Main>
      {sidebar === SidebarType.PatientInfo ? (
        <PatientSidebar />
      ) : (
        <ProspectusSidebar />
      )}
    </GridContainer>
  )
}
