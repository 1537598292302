import styled, { keyframes } from 'styled-components'

import { BaseGridContainer } from '@/components/templates/GridContainer.tsx'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'
import { SIDEBAR_WIDTH } from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  overflow: auto;
  padding: 0 25px;
`

export const GridContainer = styled(BaseGridContainer)`
  grid-template-columns: 1fr ${SIDEBAR_WIDTH}px;
  grid-template-rows: 1fr minmax(0, 8fr);
`

export const HeaderSection = styled.div`
  grid-column: 1 / span 2;
  padding-top: 32px;
`

export const SidebarContainer = styled.div`
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  width: ${SIDEBAR_WIDTH}px;
  border-left: ${({ theme }) => `1px solid ${theme.colors.common.border}`};
`

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
export const PatientNavigation = styled.div`
  display: grid;
  grid-template: repeat(auto-fill, 200px) / repeat(auto-fill, 282px);
  gap: 29px;
  flex-wrap: wrap;
  padding: 32px 0;
  animation: ${slideIn} 0.1s ease-out;
`
