import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { useOrganizations } from '@/hooks/admin/useOrganizations.ts'
import {
  denormalizeReason,
  normalizeReasons,
} from '@/screens/admin/UserManagement/reasons/utils.ts'
import { Reason, ReasonWithOrganizations } from '@/types/reason'

import { useFetchFromAPI } from '../data/useFetchFromAPI'

const reasonsQueryOptions = queryOptions({
  queryKey: ['reasons'],
})

export const useReasons = () => {
  const fetchAPI = useFetchFromAPI()
  const { organizations } = useOrganizations()

  const {
    data: reasons,
    isFetching,
    isLoading,
  } = useQuery<ReasonWithOrganizations[]>({
    queryFn: async () => fetchAPI(`admin/reasons`),
    queryKey: reasonsQueryOptions.queryKey,
    select: (data) => {
      return normalizeReasons(data, organizations)
    },
  })

  return { isFetching, isLoading, reasons }
}

type CreateReason = { reason: Omit<Reason, 'id'> }
type useReasonMutationProps = {
  onSuccess?: () => void
}

export const useCreateReason = ({ onSuccess }: useReasonMutationProps) => {
  const fetchAPI = useFetchFromAPI('POST')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    Reason,
    unknown,
    CreateReason
  >({
    mutationFn: async ({ reason }) => fetchAPI('admin/reasons', reason),
    onSuccess: (data) => {
      queryClient.setQueryData(
        reasonsQueryOptions.queryKey,
        (oldData: Reason[]) => [...oldData, data],
      )
      if (onSuccess) onSuccess()
    },
  })

  return {
    createReason: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
    newReason: data,
  }
}

type UpdateReason = {
  reason: Omit<Reason, 'id'>
  reasonId: string
}

export const useUpdateReason = ({ onSuccess }: useReasonMutationProps) => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Reason,
    unknown,
    UpdateReason
  >({
    mutationFn: async ({ reason, reasonId }) =>
      fetchAPI(`admin/reasons/${reasonId}`, reason),
    onSuccess: async () => {
      await queryClient.invalidateQueries(reasonsQueryOptions)
      onSuccess?.()
    },
  })

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    updateReason: mutate,
  }
}

export const useUpdateReasons = () => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()
  const { isError, isPending, isSuccess, mutate } = useMutation<
    Reason[],
    unknown,
    { reasons: ReasonWithOrganizations[] }
  >({
    mutationFn: async ({ reasons }) =>
      fetchAPI(`admin/reasons`, reasons.map(denormalizeReason)),
  })

  const updateReasonsCache = (reasons: ReasonWithOrganizations[]) =>
    queryClient.setQueryData(reasonsQueryOptions.queryKey, reasons)

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    updateReasons: mutate,
    updateReasonsCache,
  }
}

type DeleteReason = {
  reasonId: string
}
export const useDeleteReason = ({ onSuccess }: useReasonMutationProps) => {
  const fetchAPI = useFetchFromAPI('DELETE')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Reason,
    unknown,
    DeleteReason
  >({
    mutationFn: async ({ reasonId }) => fetchAPI(`admin/reasons/${reasonId}`),
    onSuccess: (_, deletedQueue) => {
      queryClient.setQueryData(
        reasonsQueryOptions.queryKey,
        (oldData: Reason[]) => {
          if (oldData) {
            return oldData.filter((queue) => queue.id !== deletedQueue.reasonId)
          }
          return []
        },
      )
      onSuccess?.()
    },
  })

  return {
    deleteReason: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
  }
}
