import { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { useAttachment } from '@/hooks/useAttachment.ts'
import { useEscKeyPress } from '@/hooks/useEscKeyPress.tsx'

import {
  CloseModalButton,
  ModalBackground,
  StyledModal,
} from '../../common/Modal.tsx'

const Modal = styled(StyledModal)`
  padding: 0;
  background: transparent;
`

const Image = styled.img`
  max-height: 80vh;
  max-width: 80vw;
  border-radius: 8px;
`

const StyledCloseModal = styled(CloseModalButton)`
  position: absolute;
  right: 5px;
  top: 5px;

  &:focus,
  &:hover {
    border: none;
  }
`

type AttachmentModalProps = {
  onClose: () => void
  url: string
}

export default function AttachmentModal({
  onClose,
  url,
}: AttachmentModalProps) {
  const [modalContainer] = useState(document.getElementById('modal-container'))
  const dataUrl = useAttachment(url)

  useEscKeyPress(onClose)

  return (
    modalContainer &&
    createPortal(
      <ModalBackground closeModal={onClose}>
        <Modal>
          <StyledCloseModal onClick={onClose} />
          <Image alt="image-from-message" src={dataUrl} />
        </Modal>
      </ModalBackground>,
      modalContainer,
    )
  )
}
