import { useQuery } from '@tanstack/react-query'

import i18n from '@/i18n'
import { Prospectus } from '@/types/prospectus'

import { useFetchFromAPI } from './useFetchFromAPI'

export const useProspectuses = () => {
  const fetchAPI = useFetchFromAPI()

  const { data: prospectuses, isLoading } = useQuery<Prospectus[]>({
    queryFn: async () => fetchAPI(`admin/prospectuses/all`),
    queryKey: ['prospectuses', i18n.language],
  })

  return { isLoading, prospectuses }
}
