import { useCallback, useEffect } from 'react'

export function useEscKeyPress(onEscape: () => void) {
  const KEY_NAME_ESC = 'Escape'
  const KEY_EVENT_TYPE = 'keyup'

  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KEY_NAME_ESC) {
        onEscape()
      }
    },
    [onEscape],
  )

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
    }
  }, [handleEscKey])
}
