import { useTranslation } from 'react-i18next'
import { BiChat } from 'react-icons/bi'
import styled from 'styled-components'

import { Queues } from '@/components/templates/Queues/Queues.tsx'
import { Conversation } from '@/state/stateType.ts'

const Container = styled.div`
  grid-column: 2/4;
  grid-row: 2/6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  svg {
    height: 90px;
    width: 90px;
  }
`

export function NoActiveConversationContainer({
  assignedConversations,
  setActiveConversation,
  unassignedConversations,
}: {
  assignedConversations: Conversation[]
  setActiveConversation: (active: string) => void
  unassignedConversations: Conversation[]
}) {
  return (
    <>
      <Queues
        activeConversationId={undefined}
        assignedConversations={assignedConversations}
        setActiveConversation={setActiveConversation}
        unassignedConversations={unassignedConversations}
      />
      <NoActiveConversation />
    </>
  )
}

function NoActiveConversation() {
  const { t } = useTranslation()
  return (
    <Container data-testid="noSelectedConversation">
      <BiChat />
      {t('chat.noActiveConversations')}
    </Container>
  )
}
