import { useInfiniteQuery } from '@tanstack/react-query'

import { Appointment } from '@/types/appointment'

import { useFetchFromAPI } from './useFetchFromAPI'

export const usePatientAppointments = (patientId: string, limit: number) => {
  const fetchAPI = useFetchFromAPI()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery<Appointment[]>({
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length === limit ? allPages.length + 1 : undefined
      },
      initialPageParam: 1,
      queryFn: async ({ pageParam }) => {
        const response = await fetchAPI<Appointment[], Appointment[]>(
          `admin/appointments?patientId=${patientId}&page=${pageParam}&per_page=${limit}`,
        )
        return response
      },
      queryKey: ['patientAppointments', patientId],
    })

  const appointments = data ? data.pages.flat() : []

  return {
    appointments,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  }
}
