import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { RxEnvelopeClosed } from 'react-icons/rx'
import styled from 'styled-components'

import PDFOutline from '@/assets/svgs/pdf-white-outline.svg'
import { BodyM, BodyS } from '@/components/common/Text.tsx'
import { CardStack } from '@/components/templates/PatientSidebar/CardStack.tsx'
import {
  CardContainer,
  CardWrapper,
  IconWrapper,
} from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'
import { CarePath } from '@/types/patient.ts'

const LinkIcon = styled.button`
  flex: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.common.black75};
  border-radius: 50%;
  padding: 8px;

  &:hover {
    background-color: ${(props) => props.theme.colors.common.black10};
  }
`

const CarePlanCard = ({
  canSendPDF,
  carePlan,
  onClick,
  onSend,
  selected,
}: {
  canSendPDF: boolean
  carePlan: CarePath
  onClick: () => void
  onSend: () => void
  selected: boolean
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.pdfs',
  })

  const carePlanDate = carePlan.treatment?.date || carePlan.period?.start

  return (
    <CardContainer
      active={selected}
      as={selected ? 'div' : 'button'}
      onClick={onClick}
    >
      <IconWrapper src={PDFOutline}></IconWrapper>
      <CardWrapper>
        <BodyM color="black" fontWeight="bold">
          {carePlan.treatment?.name}
        </BodyM>
        <BodyS>
          {carePlanDate &&
            t('created', {
              date: dayjs(carePlanDate).format('DD/MM/YYYY'),
            })}
        </BodyS>
      </CardWrapper>
      {canSendPDF && selected && (
        <LinkIcon onClick={onSend}>
          <RxEnvelopeClosed color="black75" />
        </LinkIcon>
      )}
    </CardContainer>
  )
}
export const CarePlansStack = ({
  canSendPDF = false,
  carePlans,
  onSelect,
  onSend,
  selectedCarePlanId,
  title,
}: {
  canSendPDF?: boolean
  carePlans: CarePath[]
  onSelect: (carePlanId: string) => void
  onSend: (carePlanId: string) => void
  selectedCarePlanId?: string
  title: string
}) => {
  return (
    <CardStack title={title}>
      {carePlans.map((carePlan) => (
        <CarePlanCard
          key={carePlan.id}
          canSendPDF={canSendPDF}
          carePlan={carePlan}
          onClick={() => onSelect(String(carePlan.id))}
          onSend={() => onSend(String(carePlan.id))}
          selected={String(carePlan.id) === selectedCarePlanId}
        />
      ))}
    </CardStack>
  )
}
