import styled from 'styled-components'

import { NAVBAR_HEIGHT } from './NavigationBar/NavigationBar.styled.ts'

export const BaseGridContainer = styled.div`
  display: grid;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  grid-template-columns: 362px 1fr 480px;
  grid-template-rows: 1fr 8fr fit-content(300px);
  min-width: 1000px;
`
