import React from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import styled from 'styled-components'

type ActionButtonsProps = {
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onEdit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ActionButton = styled.button`
  color: ${({ theme }) => theme.colors.common.black75};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black10};
  }
`

export const StyledActionButtons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`
export const ActionButtons = ({ onDelete, onEdit }: ActionButtonsProps) => {
  return (
    <StyledActionButtons>
      {onEdit && (
        <ActionButton onClick={onEdit}>
          <MdEdit />
        </ActionButton>
      )}
      {onDelete && (
        <ActionButton onClick={onDelete}>
          <MdDelete />
        </ActionButton>
      )}
    </StyledActionButtons>
  )
}
