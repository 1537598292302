import { AppState } from '@/state/stateType'
import { setProp } from '@/util/object'
import { UserTypedEvent } from '~common/serverToClientParser'

import mapStateConversations from './util'

export default function handleConversationUserTyped(
  state: AppState,
  ev: UserTypedEvent,
): AppState {
  return mapStateConversations(
    ev.conversationId,
    (c) =>
      setProp(
        c,
        'typingUser',
        ev.isTyping ? { id: ev.userId, name: ev.userName } : undefined,
      ),
    state,
  )
}
