import i18n from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormFieldType } from '@/components/admin/FormField.tsx'
import { FeatureFlag } from '@/types/featureFlag.ts'

export const useFeatureFlagFormFields = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.featureFlags',
  })

  const featureFlagFormFields: Array<FormFieldType<FeatureFlag>> = useMemo(
    () => [
      {
        id: 'key',
        label: t('columns.key'),
        type: 'text',
      },
      {
        id: 'title',
        label: t('columns.title'),
        type: 'text',
      },
      {
        id: 'description',
        label: t('columns.description'),
        type: 'text',
      },
      {
        id: 'value',
        label: t('columns.value'),
        type: 'text',
      },
      {
        id: 'enabled',
        label: t('columns.enabled'),
        type: 'switch',
      },
    ],
    [i18n.language],
  )

  const editFeatureFlagFormFields: Array<FormFieldType<FeatureFlag>> = useMemo(
    () => [
      {
        id: 'title',
        label: t('columns.title'),
        type: 'text',
      },
      {
        id: 'description',
        label: t('columns.description'),
        type: 'text',
      },
    ],
    [i18n.language],
  )

  return {
    editFeatureFlagFormFields,
    featureFlagFormFields,
  }
}
