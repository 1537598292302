import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { Header2 } from '@/components/common/Text.tsx'
import { useOrganizations } from '@/hooks/admin/useOrganizations.ts'
import { useCreateQueue, useQueues } from '@/hooks/admin/useQueues.ts'
import { useCreateReason, useReasons } from '@/hooks/admin/useReasons.ts'
import { useUserGroups } from '@/hooks/admin/useUserGroups.ts'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { QueuesTable } from '@/screens/admin/UserManagement/queues/QueuesTable.tsx'
import { ReasonsTable } from '@/screens/admin/UserManagement/reasons/ReasonsTable.tsx'
import { useReasonsFormFields } from '@/screens/admin/UserManagement/reasons/useReasonsFormFields.ts'
import { Reason } from '@/types/reason.ts'

import * as S from '../UserManagement.styled.ts'
import { StyledActionButtons } from '../UserManagement.styled.ts'
import { useQueueFormFields } from './useQueueFormFields.ts'

export const QueuesDashboard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement',
  })
  const { isLoading, queues } = useQueues()
  const { queueFormFields } = useQueueFormFields()
  const { reasonsFormFields } = useReasonsFormFields()
  const { isLoading: isLoadingUserGroups, userGroups } = useUserGroups()
  const { isLoading: isLoadingReasons } = useReasons()
  const { isLoading: isLoadingOrganizations, organizations } =
    useOrganizations()

  const [newQueueModal, setNewQueueModal] = useState<boolean>(false)
  const [newReasonModal, setNewReasonModal] = useState<boolean>(false)

  const { createQueue, isError: isErrorCreatingQueue } = useCreateQueue({
    onSuccess: () => {
      setNewQueueModal(false)
    },
  })

  const { createReason, isError: isErrorCreatingReason } = useCreateReason({
    onSuccess: () => {
      setNewReasonModal(false)
    },
  })

  return (
    <>
      <S.HeaderBar>
        <Header2>{t('tabs.reasons')}</Header2>
        <StyledActionButtons>
          <StyledActionButton
            onClick={() => setNewReasonModal(true)}
            variant="Primary"
          >
            {t('reasons.actions.add')}
          </StyledActionButton>
          <StyledActionButton
            onClick={() => setNewQueueModal(true)}
            variant="Secondary"
          >
            {t('queues.actions.add')}
          </StyledActionButton>
        </StyledActionButtons>
      </S.HeaderBar>
      <S.FlexContainer>
        {isLoading ||
        isLoadingUserGroups ||
        isLoadingReasons ||
        isLoadingOrganizations ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <>
            <ReasonsTable />
            <QueuesTable />
          </>
        )}
      </S.FlexContainer>
      {newQueueModal && (
        <EditModal
          closeModal={() => setNewQueueModal(false)}
          errorMessage={t('queues.createModal.error')}
          formFields={queueFormFields}
          isError={isErrorCreatingQueue}
          onSave={(queue) => {
            createQueue({
              queue,
            })
          }}
          selectOptions={{ userGroups }}
          title={t('queues.createModal.title')}
        />
      )}

      {newReasonModal && (
        <EditModal
          closeModal={() => setNewReasonModal(false)}
          errorMessage={t('reasons.createModal.error')}
          formFields={reasonsFormFields}
          isError={isErrorCreatingReason}
          onSave={(reason: Reason) => {
            createReason({
              reason: {
                ...reason,
                contentfulId: reason.contentfulId || '',
                isUrgent: reason.isUrgent || false,
                medicalCategories: reason.medicalCategories || [],
                organizationIds: reason.organizationIds || [],
                queue: reason.queue || {},
              },
            })
          }}
          selectOptions={{
            organizations,
            priority: ['low', 'medium', 'high'],
            queue: queues,
          }}
          title={t('reasons.createModal.title')}
        />
      )}
    </>
  )
}
