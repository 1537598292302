import React from 'react'

import { Checkbox } from '@/components/common/Checkbox.tsx'
import { Input, Label } from '@/components/common/Input.tsx'
import { Listbox, MultiSelectListbox } from '@/components/common/Listbox.tsx'
import { Switch } from '@/components/common/Switch.tsx'
import { TagInput } from '@/components/common/TagInput.tsx'

type DeepKeys<T> = T extends object
  ? {
      [K in keyof T]:
        | `${K & string}`
        | `${K & string}.${DeepKeys<T[K]> & string}`
    }[keyof T]
  : ''

export type FormFieldType<T> = {
  id: DeepKeys<T>
  label: string
} & (
  | { type: 'text' }
  | { displayValue?: string; type: 'multiselect' | 'select'; value?: string }
  | { type: 'tagInput' }
  | { type: 'checkbox' }
  | { type: 'switch' }
)

export type FormFieldProps<T> = {
  field: FormFieldType<T>
  onInputChange: (fieldId: string, value: any) => void
  selectOptions?: Partial<Record<DeepKeys<T>, Array<any> | undefined>>
  value: any
}

const areEqual = <T,>(
  prevProps: FormFieldProps<T>,
  nextProps: FormFieldProps<T>,
) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.field.id === nextProps.field.id &&
    prevProps.selectOptions === nextProps.selectOptions
  )
}

const FormFieldWithoutMemo = <T,>({
  field,
  onInputChange,
  selectOptions,
  value,
}: FormFieldProps<T>) => {
  if (field.type === 'multiselect' || field.type === 'select') {
    if (!selectOptions) return null

    const fieldSelectOptions = selectOptions?.[field.id]
    if (!fieldSelectOptions) {
      console.error('Select options not provided for field', field.id)
      return <React.Fragment key={field.id} />
    }

    const parsedOptions = fieldSelectOptions.map((option) => ({
      title: (field.displayValue && option[field.displayValue]) || option,
      value: (field.value && option[field.value]) || option,
    }))

    if (field.type === 'multiselect') {
      const parsedValue = value?.map((val: never) =>
        typeof val === 'string' ? val : val[field.value!],
      )

      return (
        <Label key={field.id}>
          {field.label}
          <MultiSelectListbox
            key={field.id}
            onChange={(items) => {
              if (items) {
                onInputChange(
                  field.id,
                  field.value
                    ? fieldSelectOptions.filter((option) =>
                        items.includes(option[field.value!]),
                      )
                    : items,
                )
              }
            }}
            options={parsedOptions}
            value={parsedValue}
          />
        </Label>
      )
    }

    if (field.type === 'select') {
      const parsedValue = value && {
        title: (field.displayValue && value[field.displayValue]) || value,
        value: (field.value && value[field.value]) || value,
      }

      return (
        <Label key={field.id}>
          {field.label}
          <Listbox
            key={field.id}
            onChange={(item) => {
              if (item) {
                onInputChange(
                  field.id,
                  field.value
                    ? fieldSelectOptions.find(
                        (option) => option[field.value!] === item.value,
                      )
                    : item.value,
                )
              }
            }}
            options={parsedOptions}
            value={parsedValue}
          />
        </Label>
      )
    }
  }

  if (field.type === 'tagInput') {
    return (
      <Label key={field.id}>
        {field.label}
        <TagInput
          handleInputChange={onInputChange}
          id={field.id}
          prevTags={value}
        />
      </Label>
    )
  }

  if (field.type === 'checkbox') {
    return (
      <Label key={field.id}>
        <Checkbox
          checked={value}
          label={field.label}
          onChange={(e) => onInputChange(field.id, e.target.checked)}
        />
      </Label>
    )
  }

  if (field.type === 'switch') {
    return (
      <Label key={field.id}>
        {field.label}
        <Switch
          checked={value}
          onChange={(checked) => {
            onInputChange(field.id, checked)
          }}
        />
      </Label>
    )
  }

  return (
    <Label key={field.id}>
      {field.label}
      <Input
        key={field.id}
        onChange={(e) => onInputChange(field.id, e.target.value)}
        type="text"
        value={value ?? ''}
      />
    </Label>
  )
}
export const FormField = React.memo(
  FormFieldWithoutMemo,
  areEqual,
) as typeof FormFieldWithoutMemo
