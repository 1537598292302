import i18n from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormFieldType } from '@/components/admin/FormField.tsx'
import { User } from '@/types/user.ts'

export const useUserFormFields = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.users',
  })

  const userFormFields: Array<FormFieldType<User>> = useMemo(
    () => [
      {
        id: 'displayName',
        label: t('columns.name'),
        type: 'text',
      },
      {
        id: 'email',
        label: t('columns.email'),
        type: 'text',
      },
    ],
    [i18n.language],
  )

  const userEditFormFields: Array<FormFieldType<User>> = useMemo(
    () => [
      ...userFormFields,
      {
        id: 'customClaims.patientId',
        label: t('columns.patientId'),
        type: 'text',
      },
      {
        displayValue: 'name',
        id: 'customClaims.roles',
        label: t('columns.roles'),
        type: 'multiselect',
        value: 'value',
      },
    ],
    [i18n.language],
  )

  return {
    userEditFormFields,
    userFormFields,
  }
}
