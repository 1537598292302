import styled from 'styled-components'

import { DebouncedInput, Input } from '@/components/common/Input.tsx'
import { BodyM } from '@/components/common/Text.tsx'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 22px 64px;
`

const Breadcrumb = styled.button`
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  color: ${({ theme }) => theme.colors.common.accentDark};
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  margin-bottom: 4px;
`

const Title = styled(BodyM)`
  color: ${({ theme }) => theme.colors.common.black75};
  margin-bottom: 24px;
`

const Description = styled(BodyM)`
  color: ${({ theme }) => theme.colors.common.black75};
`

const ValuesBlockBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 16px;
`

const ValuesBlockLabel = styled(BodyM)`
  color: ${({ theme }) => theme.colors.common.black};
`

const ValuesBlock = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.common.black10};
  border-radius: 4px;
  padding: 4px 16px;
  gap: 16px;
  max-height: 400px;
  overflow-y: scroll;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
const FlexContainerEnd = styled(FlexContainer)`
  justify-content: flex-end;
`

const ChangeValuesButtons = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 12px;
`

const FilterInput = styled(DebouncedInput)`
  width: 300px;
`

const NewItemInput = styled(Input)`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.common.black05};
  box-shadow: 0 0 12px -2px ${({ theme }) => theme.colors.common.black10};
`

const AddValueButton = styled.button`
  background-color: ${({ theme }) => theme.colors.common.accentDark};
  color: ${({ theme }) => theme.colors.common.white};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`

export {
  AddValueButton,
  Breadcrumb,
  ChangeValuesButtons,
  Description,
  FilterInput,
  FlexContainer,
  FlexContainerEnd,
  Header,
  NewItemInput,
  Title,
  ValuesBlock,
  ValuesBlockBar,
  ValuesBlockLabel,
  Wrapper,
}
