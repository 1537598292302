import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { Queue } from '@/types/queue'

import { useFetchFromAPI } from '../data/useFetchFromAPI'

const queueQueryOptions = queryOptions({
  queryKey: ['queues'],
})

export const useQueues = () => {
  const fetchAPI = useFetchFromAPI()

  const { data: queues, isLoading } = useQuery<Queue[]>({
    queryFn: async () => fetchAPI(`admin/queues`),
    queryKey: queueQueryOptions.queryKey,
  })

  return { isLoading, queues }
}

type CreateQueue = { queue: Omit<Queue, 'id'> }
type useQueueMutationProps = {
  onSuccess?: () => void
}

export const useCreateQueue = ({ onSuccess }: useQueueMutationProps) => {
  const fetchAPI = useFetchFromAPI('POST')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    Queue,
    unknown,
    CreateQueue
  >({
    mutationFn: async ({ queue }) => fetchAPI('admin/queues', queue),
    onSuccess: (data) => {
      queryClient.setQueryData(
        queueQueryOptions.queryKey,
        (oldData: Queue[]) => [...oldData, data],
      )
      onSuccess?.()
    },
  })

  return {
    createQueue: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
    newQueue: data,
  }
}

type UpdateQueue = {
  queue: Omit<Queue, 'id'>
  queueId: string
}

export const useUpdateQueue = ({ onSuccess }: useQueueMutationProps) => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Queue,
    unknown,
    UpdateQueue
  >({
    mutationFn: async ({ queue, queueId }) =>
      fetchAPI(`admin/queues/${queueId}`, queue),
    onSuccess: (data) => {
      queryClient.setQueryData(
        queueQueryOptions.queryKey,
        (oldData: Queue[]) => {
          return oldData.map((item) =>
            item.id === data.id ? { ...item, ...data } : item,
          )
        },
      )
      onSuccess?.()
    },
  })

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    updateQueue: mutate,
  }
}

type DeleteQueue = {
  queueId: string
}
export const useDeleteQueue = ({ onSuccess }: useQueueMutationProps) => {
  const fetchAPI = useFetchFromAPI('DELETE')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Queue,
    unknown,
    DeleteQueue
  >({
    mutationFn: async ({ queueId }) => fetchAPI(`admin/queues/${queueId}`),
    onSuccess: (_, deletedQueue) => {
      queryClient.setQueryData(
        queueQueryOptions.queryKey,
        (oldData: Queue[]) => {
          if (oldData) {
            return oldData.filter((queue) => queue.id !== deletedQueue.queueId)
          }
          return []
        },
      )
      onSuccess?.()
    },
  })

  return {
    deleteQueue: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
  }
}
