import { useQuery } from '@tanstack/react-query'

import { Conversation } from '@/types/conversation'

import { useFetchFromAPI } from './useFetchFromAPI'

export const usePatientConversations = (patientId: string) => {
  const fetchAPI = useFetchFromAPI()

  const { data: conversations, isLoading } = useQuery<Conversation[]>({
    queryFn: async () => fetchAPI(`admin/conversations?patientId=${patientId}`),
    queryKey: ['patientConversations', patientId],
  })

  return { conversations, isLoading }
}
