import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './locales/en'
import nl from './locales/nl'

type Language = 'nl' | 'en'

export const supportedLangs: Language[] = ['nl', 'en']

const resources = {
  en: {
    translation: en,
  },
  nl: {
    translation: nl,
  },
}

i18n.use(initReactI18next).init({
  debug: true,
  defaultNS: 'translation',
  fallbackLng: 'nl',
  lng: 'nl',
  resources,
  supportedLngs: supportedLangs,
})

export type { Language }

export default i18n
