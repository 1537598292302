import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { useFetchFromAPI } from '@/hooks/data/useFetchFromAPI.ts'
import { useActiveConversation } from '@/state/hooks/useActiveConversation.ts'
import { QuestionnaireQuestions } from '@/types/questionnaire.ts'

export const useTriageQuestionnaire = () => {
  const fetchAPI = useFetchFromAPI()
  const activeConversation = useActiveConversation()

  const {
    data: questionsAnswers,
    isError,
    isLoading,
  } = useQuery({
    enabled: Boolean(
      activeConversation?.id &&
        activeConversation?.triage?.pulseQuestionnaireId,
    ),
    queryFn: () =>
      fetchAPI<QuestionnaireQuestions, void>(
        `admin/questionnaires/${activeConversation?.triage?.pulseQuestionnaireId}`,
      ).then((questionnaire) => {
        const qa = questionnaire.questions
          .map((item) => {
            const code = activeConversation?.triage?.answers[item.code]
            if (!code || item.class === 'question_sub' || item.type === '*')
              return null
            if (item.type === 'D') {
              return {
                answer: dayjs(code).format('DD/MM/YYYY HH:mm'),
                question: item.question,
              }
            }
            if (item.type === 'M' && item.class === 'question') {
              return {
                answer: questionnaire.questions
                  .filter(
                    (q) =>
                      q.title === item.title &&
                      q.class === 'question_sub' &&
                      activeConversation.triage?.answers[q.code] === 'Y',
                  )
                  .reduce((acc, q, currentIndex, arr) => {
                    return (
                      acc +
                      ' ' +
                      q.question +
                      (currentIndex !== arr.length - 1 ? ' ,' : '')
                    )
                  }, ''),
                question: item.question,
              }
            }
            return {
              answer: item.answers?.[code ?? ''] ?? code ?? '-',
              question: item.question,
            }
          })
          .filter((item) => item !== null) as {
          answer: string
          question: string
        }[]

        return qa
      }),
    queryKey: [
      'triageQuestionnaire',
      activeConversation?.id,
      activeConversation?.triage?.pulseQuestionnaireId,
    ],
    retry: false,
    retryOnMount: false,
  })

  return { isError, isLoading, questionsAnswers }
}
