/* eslint-disable styled-components-a11y/click-events-have-key-events */
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RxArrowRight,
  RxChevronDown,
  RxChevronUp,
  RxCross2,
} from 'react-icons/rx'
import styled, { keyframes } from 'styled-components'

import { BodyM } from '@/components/common/Text.tsx'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { useInternalConversation } from '@/state/hooks/useInternalConversation.ts'

import { AssignPanel } from './AssignPanel.tsx'
import { ChatPanel } from './ChatPanel.tsx'
import { CloseInternalConversationModal } from './CloseInternalConversationModal.tsx'

const slideIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`

const slideOut = keyframes`
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
`

const Chat = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 360px;
  background-color: white;

  & > button[data-headlessui-state~='open'] {
    animation: ${slideIn} 0.3s;
  }

  & > button[data-headlessui-state=''] {
    animation: ${slideOut} 0.3s;
  }
`

const ChatButton = styled(DisclosureButton)`
  background-color: ${({ theme }) => theme.colors.common.accentDark};
  color: ${({ theme }) => theme.colors.common.white};
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 0 0 0;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
const ChatPanelWrapper = styled(DisclosurePanel)`
  height: 390px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-bottom: 0;
  display: flex;
  flex-direction: column;
`

const CloseButton = styled.button`
  display: flex;

  &:hover {
    opacity: 0.5;
  }
`

export const InternalConversation = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'internalChat',
  })

  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false)
  const [opened, setOpened] = useState<boolean>(false)

  const internalConversation = useInternalConversation()
  const currentUser = useCurrentUser()
  const isCreatedByUser = internalConversation?.createdById === currentUser.id

  useEffect(() => {
    setOpened(!!internalConversation?.createdById)
  }, [internalConversation?.conversationId])

  return (
    <Chat>
      <Disclosure>
        <ChatButton
          as="div"
          onClick={() => {
            setOpened((prevOpened) => !prevOpened)
          }}
          role="button"
          tabIndex={0}
        >
          <BodyM color="white" fontWeight="bold">
            {internalConversation
              ? `${t('title')} ${
                  isCreatedByUser
                    ? internalConversation.assignedToName
                    : internalConversation.createdByName
                }`
              : t('action')}
          </BodyM>
          {opened && (
            <FlexContainer>
              {isCreatedByUser && (
                <CloseButton onClick={() => setConfirmIsOpen(true)}>
                  <RxCross2 height={24} width={24} />
                </CloseButton>
              )}
              <RxChevronDown height={24} width={24} />
            </FlexContainer>
          )}
          {!opened &&
            (internalConversation ? <RxChevronUp /> : <RxArrowRight />)}
        </ChatButton>
        {opened && (
          <ChatPanelWrapper static>
            {internalConversation ? (
              <ChatPanel conversation={internalConversation} />
            ) : (
              <AssignPanel />
            )}
          </ChatPanelWrapper>
        )}
        {confirmIsOpen && (
          <CloseInternalConversationModal
            closeModal={() => {
              setOpened(false)
              setConfirmIsOpen(false)
            }}
          />
        )}
      </Disclosure>
    </Chat>
  )
}
