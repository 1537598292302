import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Send from '@/assets/svgs/send.svg'
import { TextArea } from '@/components/common/TextArea.tsx'
import ChatContent from '@/components/templates/ChatArea/ChatContent.tsx'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { InternalConversation } from '@/state/stateType.ts'

const ChatContentWrapper = styled.div`
  flex: 1;
  padding: 10px 16px;
  overflow-y: auto;
`

const InternalInput = styled.div`
  flex: none;
  display: flex;
  padding: 16px 20px;
  gap: 16px;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.common.border};
`

const StyledTextArea = styled(TextArea)`
  flex: 1;
`

const IconButton = styled.button`
  flex: none;
  align-self: flex-end;
`

type ChatPanelProps = {
  conversation: InternalConversation
}

export const ChatPanel = ({ conversation }: ChatPanelProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'internalChat',
  })
  const [value, setValue] = useState<string>('')
  const { addInternalMessage } = useSocket()

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setValue(e.target.value)
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter') {
      e.preventDefault()
      sendMessage(e)
    }
  }

  function sendMessage(
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLTextAreaElement>,
  ): void {
    e.preventDefault()

    if (value !== '') {
      addInternalMessage(conversation.id, value, [])
      setValue('')
    }
  }

  return (
    <>
      <ChatContentWrapper>
        <ChatContent
          assignedToId={conversation.assignedToId}
          createdById={conversation.createdById}
          events={conversation.events || []}
        ></ChatContent>
      </ChatContentWrapper>
      <InternalInput>
        <StyledTextArea
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          placeholder={t('placeholder')}
          rows={1}
          value={value}
          withoutAttachment
        />
        <IconButton onClick={sendMessage}>
          <img alt="send" src={Send} />
        </IconButton>
      </InternalInput>
    </>
  )
}
