import { FilterFn } from '@tanstack/react-table'

export const fuzzyFilter: FilterFn<any> = (row, columnId, value) => {
  // Return if the item should be filtered in/out
  if (typeof row.getValue(columnId) === 'string') {
    return (row.getValue(columnId) as string)
      .toLowerCase()
      .includes(value.toLowerCase())
  }
  if (Array.isArray(value) && value.length === 0) {
    return true
  }
  if (Array.isArray(row.getValue(columnId))) {
    return (row.getValue(columnId) as Array<string>).some((r) =>
      value.includes(r),
    )
  }
  return false
}

export const formatNumber = (number: number) => {
  const numberStr = String(number)
  const reversedStr = numberStr.split('').reverse().join('')
  const formattedParts = []
  for (let i = 0; i < reversedStr.length; i += 3) {
    formattedParts.push(reversedStr.substring(i, i + 3))
  }

  return formattedParts.join(' ').split('').reverse().join('')
}
