import { AppState } from '@/state/stateType.ts'
import { setProp } from '@/util/object'
import {
  ChatMessageAddedEvent,
  ConversationEvent,
} from '~common/serverToClientParser'

import mapStateConversations from './util'

export default function handleChatMessageAdded(
  state: AppState,
  ev: ChatMessageAddedEvent,
): AppState {
  // TODO: only send notification for person who conversation is assigned to
  // if (!ev.isClinician) {
  //   new Notification(t('chat.notifications.messageAdded', { user: ev.userName }))
  // }

  const unique = (data: ConversationEvent[]) => {
    return [
      ...data
        .reduce((a, c) => {
          a.set(c.id, c)
          return a
        }, new Map())
        .values(),
    ]
  }

  return mapStateConversations(
    ev.conversationId,
    (c) => setProp(c, 'events', unique([...(c.events || []), ev])),
    state,
  )
}
