import { Menu, MenuButton, MenuItem } from '@headlessui/react'
import dayjs from 'dayjs'
import { Fragment } from 'react'

import * as S from './MonthPicker.styled.ts'

type YearPickerProps = {
  onChange: (onChange: number) => void
  selected: number
}

const YEARS_RANGE = 6

const getYearsRange = (currentYear: number) => {
  return new Array(YEARS_RANGE)
    .fill(0)
    .map((_, index) => {
      return currentYear - index
    })
    .reverse()
}
export const YearPicker = ({ onChange, selected }: YearPickerProps) => {
  const currentYear = dayjs().year()
  const years = getYearsRange(currentYear)

  return (
    <Menu as={S.StyledMenu}>
      <MenuButton as={S.MenuButton}>{selected}</MenuButton>
      <S.StyledMenuItems>
        <S.Wrapper>
          {years.map((year) => (
            <MenuItem key={year} as={Fragment}>
              <S.PickerButton
                active={year === selected}
                onClick={() => onChange(year)}
              >
                {year}
              </S.PickerButton>
            </MenuItem>
          ))}
        </S.Wrapper>
      </S.StyledMenuItems>
    </Menu>
  )
}
