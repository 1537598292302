import { useQuery } from '@tanstack/react-query'

import { useFetchFromAPI } from '@/hooks/data/useFetchFromAPI.ts'
import i18n from '@/i18n'
import { Reason } from '@/types/reason.ts'

export const useContactReasons = () => {
  const fetchAPI = useFetchFromAPI()

  const { data: contactReasons, isLoading } = useQuery<Reason[]>({
    queryFn: async () => fetchAPI('admin/chat/reasons'),
    queryKey: ['contactReasons', i18n.language],
  })

  return { contactReasons, isLoading }
}
