import {
  ServerToClientEvent,
  UserTypedEvent,
} from '~common/serverToClientParser'

import mapStateConversations from './state/eventHandlers/util'
import reduce from './state/reducer'
import { AppState } from './state/stateType'
import { createAtom } from './util/atom'
import { setProp, updateProp } from './util/object'

export default function initStore(initialState: AppState) {
  const atom = createAtom(initialState)
  const timeouts: Record<string, NodeJS.Timeout | undefined> = {}

  function handleEvent(ev: ServerToClientEvent) {
    atom.update((state) => reduce(state, ev))
    if (ev.type === 'UserTyped') {
      handleUserTypingIndicator(ev)
    }
  }

  function handleUserTypingIndicator(ev: UserTypedEvent) {
    clearTimeout(timeouts[ev.conversationId])
    timeouts[ev.conversationId] = setTimeout(() => {
      timeouts[ev.conversationId] = undefined
      atom.update((state) =>
        mapStateConversations(
          ev.conversationId,
          (c) => setProp(c, 'typingUser', undefined),
          state,
        ),
      )
    }, 5000)
  }

  function setActiveConversation(id: string | undefined) {
    atom.update((state) => setProp(state, 'activeConversationId', id))
  }

  function setLoggedInUser(id: string | undefined) {
    atom.update((state) => setProp(state, 'loggedInUserId', id))
  }

  function setIsLoading(isLoading: boolean) {
    atom.update((state) => setProp(state, 'isLoading', isLoading))
  }

  function setError(error: string | undefined) {
    atom.update((state) => setProp(state, 'error', error))
  }

  function setNotification(notification: string | undefined) {
    atom.update((state) => setProp(state, 'notification', notification))
  }

  function setAttachment(url: string, data: Promise<string>) {
    atom.update((state) =>
      updateProp(state, 'attachments', (a) => setProp(a, url, data)),
    )
  }

  function setIsAttachmentLoading({
    conversationId,
    isLoading,
  }: {
    conversationId: string
    isLoading: boolean
  }) {
    atom.update((state) =>
      mapStateConversations(
        conversationId,
        (c) => setProp(c, 'isAttachmentLoading', isLoading),
        state,
      ),
    )
  }

  return {
    getSnapshot: atom.getSnapshot,
    handleEvent,
    setActiveConversation,
    setAttachment,
    setError,
    setIsAttachmentLoading,
    setIsLoading,
    setLoggedInUser,
    setNotification,
    subscribe: atom.subscribe,
  }
}
