import { dropWhile } from './array'

export function parseStringDate(s: string | undefined): Date | undefined {
  return s !== undefined ? new Date(s) : undefined
}

const MSPerSec = 1000
const MSPerMinute = MSPerSec * 60
const MSPerHour = MSPerMinute * 60
const MSPerDay = MSPerHour * 24
const divisors = [
  { div: MSPerDay, unit: 'd' },
  { div: MSPerHour, unit: 'h' },
  { div: MSPerMinute, unit: 'm' },
]

// Converts the difference between `now` and `since`
// to a string representation.
export function durationSince(now: Date, since: Date): string {
  const diff = now.getTime() - since.getTime()
  if (diff < 0) return '0m'
  const values = divisors.reduce(
    (acc, item) => {
      const value = Math.floor(acc.value / item.div)
      return {
        result: acc.result.concat([{ unit: item.unit, value }]),
        value: acc.value - value * item.div,
      }
    },
    {
      result: [] as { unit: string; value: number }[],
      value: diff,
    },
  ).result

  return dropWhile(values, (i) => i.value === 0)
    .map((v, i) => `${i > 0 ? zeroPrefix(v.value) : v.value}${v.unit}`)
    .join(' ')
}

function zeroPrefix(v: number): string {
  return `${v < 10 ? '0' : ''}${v}`
}
