import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxArrowLeft } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'
import { textSizeS } from '@/styles/theme/fonts.ts'

const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  ${textSizeS};
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.common.black75};
  margin-bottom: 12px;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export const EXPAND_PROFILE_TRANSITION_DURATION = 400

const ExpandProfileTransitionContext = React.createContext<{
  expanding: boolean
  setExpanding: (expanding: boolean) => void
}>({
  expanding: false,
  setExpanding: () => void 0,
})

export const ExpandProfileTransitionProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [expanding, setExpanding] = useState<boolean>(false)
  return (
    <ExpandProfileTransitionContext.Provider
      value={{ expanding, setExpanding }}
    >
      {children}
    </ExpandProfileTransitionContext.Provider>
  )
}
export const useExpandProfileTransition = () => {
  const { expanding, setExpanding } = useContext(ExpandProfileTransitionContext)
  return { expanding, setExpanding }
}

export const ExpandProfile = ({ pulseIds }: { pulseIds?: string }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar',
  })
  const { expanding, setExpanding } = useExpandProfileTransition()

  const { patientId } = usePatientPulseIdFromParams()
  const navigate = useNavigate()

  if (expanding) {
    return null
  }
  return (
    <ExpandButton
      onClick={() => {
        setExpanding(true)
        setTimeout(() => {
          navigate(`/patients/${pulseIds ? btoa(pulseIds) : patientId}`)
          setExpanding(false)
        }, EXPAND_PROFILE_TRANSITION_DURATION)
      }}
    >
      <RxArrowLeft />
      {t('expandProfile')}
    </ExpandButton>
  )
}
