import { Menu, MenuButton, MenuItem } from '@headlessui/react'
import dayjs from 'dayjs'
import { Fragment } from 'react'

import * as S from './MonthPicker.styled.ts'

type MonthPickerProps = {
  filterByYear: number
  onChange: (date: dayjs.Dayjs) => void
  selected: dayjs.Dayjs
}

export const MonthPicker = ({
  filterByYear,
  onChange,
  selected,
}: MonthPickerProps) => {
  const currentMonth = dayjs().month()

  return (
    <Menu as={S.StyledMenu}>
      <MenuButton as={S.MenuButton}>{selected.format('MMMM')}</MenuButton>
      <S.StyledMenuItems>
        <S.Header>{filterByYear}</S.Header>
        <S.Wrapper>
          {dayjs.months().map((month, index) => (
            <MenuItem key={month} as={Fragment}>
              <S.PickerButton
                active={index === selected.month()}
                disabled={
                  index > currentMonth && filterByYear === dayjs().year()
                }
                onClick={() =>
                  onChange(dayjs().month(index).year(filterByYear))
                }
              >
                {month}
              </S.PickerButton>
            </MenuItem>
          ))}
        </S.Wrapper>
      </S.StyledMenuItems>
    </Menu>
  )
}
