import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxArrowRight, RxChevronDown, RxChevronUp } from 'react-icons/rx'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Skeleton } from '@/components/common/Skeleton.tsx'
import { BodyS, Header1 } from '@/components/common/Text.tsx'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext.ts'
import {
  selectExercisesPath,
  selectPatientPath,
  SelectPatientPathParams,
  SelectProgramPathParams,
} from '@/router/paths.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`

const Content = styled.section`
  padding: 40px;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.common.black10};
  margin: 24px 0 32px;
`

const Actions = styled.footer`
  display: flex;
  gap: 16px;
  padding: 20px 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.common.black10};
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.common.white};
`

const Radio = styled.span``

const ProgramWrapper = styled.label<{ selected: boolean }>`
  cursor: pointer;
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.common.black10};
  border-radius: 4px;
  padding: 16px 20px;
  font-weight: 500;
  margin-bottom: 8px;
  background-color: ${({ selected, theme }) =>
    selected && theme.colors.common.background};

  ${Radio} {
    position: relative;
    border: 2px solid
      ${({ selected, theme }) =>
        selected
          ? theme.colors.common.accentDark
          : theme.colors.common.black62};
    border-radius: 50%;
    height: 20px;
    margin-right: 12px;
    width: 20px;

    ${({ selected, theme }) =>
      selected &&
      `&:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: ${theme.colors.common.accentDark};
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }`}
  }
`

const Program = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.button`
  height: 24px;
  width: 24px;
  margin-left: auto;
`

const CollapsibleExerciseList = styled.div<{ extended: boolean }>`
  margin: ${(props) => (props.extended ? '8px 32px' : 0)};
  height: ${(props) => (props.extended ? 'auto' : 0)};
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`

export const ProgramSelection = () => {
  const { programId } = useParams<SelectProgramPathParams>()
  const { patientId } = usePatientPulseIdFromParams() as SelectPatientPathParams
  const [selectedProgram, setSelectedProgram] = useState<string | undefined>('')
  const [extendedProgram, setExtendedProgram] = useState<string | undefined>('')
  const { t } = useTranslation('translation', {
    keyPrefix: 'programSelection',
  })
  const navigate = useNavigate()

  const { actions, defaultPrograms } = useHandTherapyContext()

  const handleToggle = (program: string | undefined) => {
    setExtendedProgram(program === extendedProgram ? '' : program)
  }

  const handleConfirm = () => {
    const program = defaultPrograms.programs.find(
      (program) => program.contentfulId === selectedProgram,
    )

    if (program) {
      actions?.setCurrentProgram(program)
      navigate(selectExercisesPath({ patientId, programId }), {
        state: { assignNew: true, programContentfulId: selectedProgram },
      })
    }
  }

  const handleCancel = () => {
    if (programId) {
      navigate(-1)
    } else {
      navigate(selectPatientPath({ patientId }))
    }
  }

  return (
    <Main>
      <Content>
        <Header1>{programId ? t('titles.change') : t('titles.select')}</Header1>
        <Divider />

        {defaultPrograms.isLoading && (
          <>
            <Skeleton height={48} width={600} />
            <Skeleton height={48} width={600} />
            <Skeleton height={48} width={600} />
            <Skeleton height={48} width={600} />
          </>
        )}

        <ul>
          {defaultPrograms.programs.map((program) => (
            <li key={program.name}>
              <ProgramWrapper
                selected={program.contentfulId === selectedProgram}
              >
                <Program>
                  <Radio />
                  <input
                    name="program"
                    onChange={() => setSelectedProgram(program.contentfulId)}
                    type="radio"
                    value={program.contentfulId}
                  />
                  {program.name}

                  <Icon onClick={() => handleToggle(program.contentfulId)}>
                    {extendedProgram === program.contentfulId ? (
                      <RxChevronUp />
                    ) : (
                      <RxChevronDown />
                    )}
                  </Icon>
                </Program>

                <CollapsibleExerciseList
                  extended={program.contentfulId === extendedProgram}
                >
                  <div>
                    <BodyS fontWeight="bold">{t('splint')}</BodyS>
                    {program.splints.map((splint) => (
                      <BodyS key={splint.name}>{splint.name}</BodyS>
                    ))}
                  </div>
                  <div>
                    <BodyS fontWeight="bold">{t('exercise')}</BodyS>
                    {program.exercises.map((exercise) => (
                      <BodyS key={exercise.name}>{exercise.name}</BodyS>
                    ))}
                  </div>
                </CollapsibleExerciseList>
              </ProgramWrapper>
            </li>
          ))}
        </ul>
      </Content>

      <Actions>
        <StyledActionButton
          disabled={!selectedProgram}
          onClick={handleConfirm}
          variant="Confirm"
        >
          {t('actions.confirm')}
          <RxArrowRight color="white" height={22} width={22} />
        </StyledActionButton>
        <StyledActionButton onClick={handleCancel} variant="Secondary">
          {t('actions.cancel')}
        </StyledActionButton>
      </Actions>
    </Main>
  )
}
