import { useEffect, useRef, useState } from 'react'
import { RxCaretDown, RxCaretUp } from 'react-icons/rx'
import styled from 'styled-components'

import { BodyMedium } from './Text.tsx'

interface HeaderProps {
  isOpen: boolean
}

const DropdownHeader = styled.div<HeaderProps>`
  display: flex;
  gap: 2px;
  border: 1px solid ${(props) => props.theme.colors.common.border};
  padding: ${(props) =>
    `10px ${props.theme.spacing * 2}px 10px ${props.theme.spacing * 2}px;`};
  justify-content: space-between;
  border-radius: ${(props) =>
    `4px 4px ${props.isOpen ? '0' : '4'}px ${props.isOpen ? '0' : '4'}px`};
  align-items: center;
  cursor: pointer;
`

const OptionsContainer = styled.ul`
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.common.border};
  }
`

const DropdownOption = styled.li`
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary.main};

  padding: ${(props) =>
    `10px ${props.theme.spacing * 2}px 10px ${props.theme.spacing * 2}px;`};
  border: 1px solid ${(props) => props.theme.colors.common.border};
  border-bottom: 0;

  :first-child {
    border-top: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary.main};
  }
`

const Selected = styled(BodyMedium)`
  color: ${(props) => props.theme.colors.primary.main};
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.colors.primary.main};
`

interface Item {
  id: string
  title: string
}

interface DropdownProps<T extends Item = Item> {
  items: T[]
  onSelect: (id: string) => void
  prompt?: string
  selectedItemId?: string
}

export function Dropdown({
  items,
  onSelect,
  prompt,
  selectedItemId,
}: DropdownProps) {
  return (
    <RawDropdown
      items={items}
      onSelect={onSelect}
      prompt={prompt}
      renderItem={(item: Item) => item.title}
      selectedItemId={selectedItemId}
    />
  )
}

type RawDropdownProps<T extends Item> = DropdownProps<T> & {
  renderItem: (item: T) => JSX.Element | string
}

export function RawDropdown<T extends Item>({
  items,
  onSelect,
  prompt,
  renderItem,
  selectedItemId,
}: RawDropdownProps<T>) {
  const [isListOpen, setIsListOpen] = useState(false)
  const refEl = useRef<HTMLInputElement>(null)

  function setActive(item: Item) {
    onSelect(item.id)
    setIsListOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        refEl.current &&
        !refEl.current.contains(event.target as HTMLInputElement)
      ) {
        setIsListOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
  }, [refEl])

  const selectedItem = items.find((i) => i.id === selectedItemId)
  return (
    <div ref={refEl}>
      <DropdownHeader
        isOpen={isListOpen}
        onClick={() => setIsListOpen((previousValue) => !previousValue)}
        onKeyDown={() => setIsListOpen((previousValue) => !previousValue)}
        role="button"
        tabIndex={0}
      >
        <Selected>{selectedItem ? selectedItem.title : prompt || ''}</Selected>
        <Icon>{isListOpen ? <RxCaretUp /> : <RxCaretDown />}</Icon>
      </DropdownHeader>

      {isListOpen && (
        <OptionsContainer>
          {items.map((item) => (
            // eslint-disable-next-line styled-components-a11y/no-noninteractive-element-to-interactive-role
            <DropdownOption
              key={item.id}
              onClick={() => setActive(item)}
              onKeyDown={() => setActive(item)}
              role="button"
            >
              {renderItem(item)}
            </DropdownOption>
          ))}
        </OptionsContainer>
      )}
    </div>
  )
}
