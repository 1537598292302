import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
    font-family: TitilliumWeb;
  }

  body p {
    font-size: 16px;
  }

  body ul {
    list-style-type: none;
  }

  button, input, textarea {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
  }

  img {
    all: unset;
    overflow: hidden;
  }

  body svg {
    height: 24px;
    width: 24px;
  }

  input, textarea {
    font-size: 16px;
    font-weight: 300;
    font-family: TitilliumWeb;
  }
`
