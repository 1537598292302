import { z } from 'zod'

export const UUIDParser = z.string().uuid()

export const ConversationPriorityParser = z.union([
  z.literal('low'),
  z.literal('medium'),
  z.literal('high'),
])

export type ConversationPriority = z.infer<typeof ConversationPriorityParser>
