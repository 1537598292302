import { useQuery } from '@tanstack/react-query'

import { Metrics } from '@/types/metrics.ts'

import { useFetchFromAPI } from '../data/useFetchFromAPI.ts'

export const useMetrics = (from: string, to: string) => {
  const fetchAPI = useFetchFromAPI()

  const { data: metrics, isLoading } = useQuery<Metrics>({
    queryFn: async () => fetchAPI(`admin/metrics?from=${from}&to=${to}`),
    queryKey: ['metrics', from, to],
  })

  return { isLoading, metrics }
}
