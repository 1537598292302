import { useEffect } from 'react'
import styled from 'styled-components'

import { useSocket } from '@/contexts/useSocketContext.ts'

interface NotificationProps {
  readonly animationDurationInSeconds: number
}

const NotificationAnimationContainer = styled.div<NotificationProps>`
  position: fixed;
  left: 50%;
  translate: -50%;
  bottom: -6%;

  animation-name: example;
  animation-duration: ${(props) => props.animationDurationInSeconds / 2}s;
  animation-iteration-count: 2;
  animation-direction: alternate;
  @keyframes example {
    0% {
      bottom: -6%;
    }
    50% {
      bottom: 6%;
    }
    100% {
      bottom: 6%;
    }
  }
`
const NotificationStyleContainer = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.common.generalEvent};
  border-radius: 8px;
`

export function Notification({ text }: { text: string }) {
  const { setNotification } = useSocket()
  const animationDurationInMs = 4000

  useEffect(() => {
    setTimeout(() => {
      setNotification(undefined)
    }, animationDurationInMs)
  }, [])

  return (
    <NotificationAnimationContainer
      animationDurationInSeconds={animationDurationInMs / 1000}
    >
      <NotificationStyleContainer>{text}</NotificationStyleContainer>
    </NotificationAnimationContainer>
  )
}
