import { useContext } from 'react'

import { AuthenticationContext } from '@/contexts/AuthenticationContext.tsx'

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext)

  if (!context) {
    throw new Error(
      'useAuthentication must be used within a AuthenticationProvider',
    )
  }

  return context
}
