import { useContext } from 'react'

import { ProspectusContext } from '@/contexts/ProspectusContext.tsx'

export const useProspectusContext = () => {
  const context = useContext(ProspectusContext)
  if (context === undefined) {
    throw new Error(
      'useProspectusContext must be used within a ProspectusProvider',
    )
  }
  return context
}
