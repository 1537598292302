export interface ReadableAtom<T> {
  getSnapshot: () => T
  subscribe(f: () => void): () => void
}

export interface WritableAtom<T> extends ReadableAtom<T> {
  update: (f: (old: T) => T) => void
}

export function createAtom<T>(initialValue: T): WritableAtom<T> {
  const subscribers = createSubscribers()
  let value = initialValue

  function update(f: (old: T) => T) {
    const newValue = f(value)
    if (newValue !== value) {
      value = newValue
      subscribers.notify()
    }
  }

  function getSnapshot() {
    return value
  }

  return {
    getSnapshot,
    subscribe: subscribers.subscribe,
    update,
  }
}

export function createSubscribers() {
  let subscribers: Array<() => void> = []

  function notify() {
    subscribers.forEach((s) => s())
  }

  function subscribe(f: () => void): () => void {
    subscribers = subscribers.concat([f])
    return () => {
      subscribers = subscribers.filter((s) => s !== f)
    }
  }

  function count() {
    return subscribers.length
  }

  return { count, notify, subscribe }
}
