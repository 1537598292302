import { AppState } from '@/state/stateType'
import { setProps } from '@/util/object'
import { ConversationMovedEvent } from '~common/serverToClientParser'

import { getActiveConversation } from '../stateUtil'
import mapStateConversations from './util'

export default function handleConversationMoved(
  state: AppState,
  ev: ConversationMovedEvent,
): AppState {
  const newQueue = state.queues.find((q) => q.id === ev.queueId)

  const userCanSeeNewQueue = newQueue?.userGroups?.some((group) => {
    return state.user.userGroups.map((t) => t.name)?.includes(group)
  })
  const activeConversation = getActiveConversation(state)

  if (
    activeConversation?.assignedToId !== state.user.id &&
    !userCanSeeNewQueue
  ) {
    const updatedConversations = [...state.conversations].filter(
      (conversation) => conversation.id !== ev.conversationId,
    )

    return setProps(state, {
      activeConversationId: undefined,
      conversations: updatedConversations,
    })
  }

  return mapStateConversations(
    ev.conversationId,
    (c) =>
      setProps(c, {
        events: c.events?.concat([ev]),
        priority: ev.priority,
        queueId: ev.queueId,
      }),
    state,
  )
}
