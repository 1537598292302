import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxArrowRight, RxMagnifyingGlass } from 'react-icons/rx'
import styled from 'styled-components'

import { Avatar } from '@/components/common/Avatar'
import { StyledActionButton } from '@/components/common/Buttons'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useClinicians } from '@/state/hooks/useClinicians.ts'
import { Clinician } from '@/state/stateType.ts'
import { getInitials } from '@/util/getInitials.ts'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`

const SearchInputWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  gap: 8px;
`

// fix for placeholder prop on input
type InputProps = {
  placeholder: string
}
const Input = styled(ComboboxInput)<InputProps>`
  flex: 1;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  flex: none;
  color: ${({ theme }) => theme.colors.common.black62};
`
const Options = styled(ComboboxOptions)`
  flex: 1;
  margin: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
`

type OptionProps = {
  selected?: boolean
}

const Option = styled(ComboboxOption)<OptionProps>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-radius: 4px;
  padding: 8px 20px 8px 12px;
  gap: 16px;
  margin: 4px 0;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.common.background : theme.colors.common.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.common.background};
  }
`

export const AssignPanel = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'internalChat',
  })
  const [selectedPerson, setSelectedPerson] = useState<Clinician | null>(null)
  const [query, setQuery] = useState('')

  const clinicians = useClinicians()
  const { createInternalConversation } = useSocket()

  const filteredPeople =
    query === ''
      ? clinicians
      : clinicians.filter((clinician) => {
          return clinician.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Wrapper>
      <Combobox onChange={setSelectedPerson} value={selectedPerson}>
        <SearchInputWrapper>
          <Input
            onChange={(event) => setQuery(event.target.value)}
            placeholder={t('searchbarPlaceholder')}
          />
          <Icon>
            <RxMagnifyingGlass />
          </Icon>
        </SearchInputWrapper>
        <Options static>
          {filteredPeople.map((person) => (
            <Option
              key={person.id}
              selected={person.name === selectedPerson?.name}
              value={person}
            >
              <Avatar fontWeight="bold">{getInitials(person.name)}</Avatar>
              {person.name}
            </Option>
          ))}
        </Options>
      </Combobox>
      <StyledActionButton
        disabled={!selectedPerson}
        onClick={() => {
          if (selectedPerson) {
            createInternalConversation(selectedPerson)
          }
        }}
        variant="Confirm"
      >
        Start chat <RxArrowRight color="white" height={22} width={22} />
      </StyledActionButton>
    </Wrapper>
  )
}
