import { Attachment } from '~common/clientToServerParser'

import { store } from '../app'

async function loadAttachmentData(
  serverUrl: string,
  token: string,
  attachmentUrl: string,
): Promise<string> {
  const { url } = await fetch(
    `${serverUrl}/image/getDownloadUrl?url=${attachmentUrl}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => res.json())

  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => URL.createObjectURL(blob))
}

export function loadAttachment(
  serverUrl: string,
  token: string,
  attachmentUrl: string,
): Promise<string> {
  const { attachments } = store.getSnapshot()
  if (attachments[attachmentUrl] !== undefined) {
    return attachments[attachmentUrl]
  }
  const operation = loadAttachmentData(serverUrl, token, attachmentUrl)
  store.setAttachment(attachmentUrl, operation)
  return operation
}

export async function getAttachmentFullUrl(
  serverUrl: string,
  token: string,
  attachmentUrl: string,
) {
  return await fetch(`${serverUrl}/image/getDownloadUrl?url=${attachmentUrl}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export const uploadFile =
  (serverUrl: string, token: string) =>
  async (file: File): Promise<Attachment> => {
    const { url } = await fetch(`${serverUrl}/image/getUploadUrl`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json())

    await fetch(url, {
      body: file,
      headers: {
        'Content-Type': file.type,
      },
      method: 'PUT',
      mode: 'cors',
    })
    const imageUrl = url.split('?')[0]
    return { mimeType: file.type, name: file.name, url: imageUrl }
  }

export function uploadFiles(
  serverUrl: string,
  token: string,
  fileList: File[],
): Promise<Attachment[]> {
  const uploadFn = uploadFile(serverUrl, token)
  if (fileList.length === 0) return Promise.resolve([])
  return Promise.all(fileList.map(uploadFn))
}
