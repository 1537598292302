import styled from 'styled-components'

import {
  CloseModalButton as StyledCloseModalButton,
  StyledModal,
} from '@/components/common/Modal.tsx'
import { BodyM } from '@/components/common/Text.tsx'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'
import {
  SIDEBAR_WIDTH,
  SidebarContainer as BaseSidebarContainer,
} from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  overflow: auto;
  padding: 0 20px;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr ${SIDEBAR_WIDTH}px;
  grid-template-rows: auto;
  height: 100vh;
`

export const SidebarContainer = styled(BaseSidebarContainer)<{
  expanding: boolean
}>`
  padding: 32px 25px;
  height: calc(100vh - 64px);
  overflow: auto;
  border-left: ${({ theme }) => `1px solid ${theme.colors.common.border}`};
`

export const CarePlanContainer = styled.div`
  padding: 32px 0;
  overflow-y: auto;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

export const DefaultBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.common.background};
`

export const DefaultPDFIcon = styled.img`
  margin-bottom: 20px;
`

export const DefaultPDFText = styled(BodyM)`
  margin-bottom: 12px;
`

export const Modal = styled(StyledModal)`
  width: 60%;
  max-width: 600px;
  max-height: 100%;
`

export const CloseModalButton = styled(StyledCloseModalButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const ModalText = styled(BodyM)`
  text-align: center;
`

export const ButtonSpinner = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.common.accentLight};
  border-top: 2px solid ${({ theme }) => theme.colors.common.black75};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
