import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Listbox } from '@/components/common/Listbox.tsx'

export const FrequencyListbox = ({
  defaultValue,
  i18nKeyPrefix,
  name,
  options,
}: {
  defaultValue?: string
  i18nKeyPrefix: string
  name: string
  options: string[]
}) => {
  const { setValue } = useFormContext()
  const { t } = useTranslation('translation', {
    keyPrefix: i18nKeyPrefix,
  })

  const splintOptions = options.map((key) => ({
    title: t(`frequencyOptions.${key}`),
    value: key,
  }))

  const [selectedValue, setSelectedValue] = useState(
    defaultValue
      ? { title: t(`frequencyOptions.${defaultValue}`), value: defaultValue }
      : splintOptions[0],
  )

  const handleSelectedValue = (val?: { title: string; value: string }) => {
    if (val) {
      setValue(name, val.value, { shouldDirty: true })
      setSelectedValue(val)
    }
  }

  return (
    <Listbox
      onChange={handleSelectedValue}
      options={splintOptions}
      value={selectedValue}
    ></Listbox>
  )
}
