import { User as FirebaseUser } from 'firebase/auth'
import { createContext, PropsWithChildren, useEffect, useState } from 'react'

import { firebaseAuth } from '@/util/firebase.ts'

interface AuthenticationContextValues {
  isAuthenticated: boolean
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  token: string | null
  user: FirebaseUser | null
}

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  setIsLoading: () => {
    /*no-op*/
  },
  token: null,
  user: null,
}

export const AuthenticationContext =
  createContext<AuthenticationContextValues>(initialState)

export const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] =
    useState<Pick<AuthenticationContextValues, 'user' | 'isAuthenticated'>>(
      initialState,
    )
  const [token, setToken] = useState<string | null>(null)

  const onAuthStateChanged = (user: FirebaseUser | null) => {
    setValues({ isAuthenticated: Boolean(user), user })

    if (user) {
      user?.getIdToken().then((token) => {
        setToken(token)
      })
    } else {
      setValues(initialState)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const onAuthUnsubscribe =
      firebaseAuth.onAuthStateChanged(onAuthStateChanged)
    const onIdChangeUnsubscribe =
      firebaseAuth.onIdTokenChanged(onAuthStateChanged)
    return () => {
      onAuthUnsubscribe()
      onIdChangeUnsubscribe()
    }
  }, [])

  return (
    <AuthenticationContext.Provider
      value={{ ...values, isLoading, setIsLoading, token }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
