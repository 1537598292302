import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'

export const StartConversation = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })
  const clinician = useCurrentUser()
  const { assignActiveConversation } = useSocket()

  return (
    <StyledActionButton
      data-testid="assignToLoggedInClinician"
      onClick={() => assignActiveConversation(clinician)}
      size="small"
      variant="Primary"
    >
      {t('actions.start')}
    </StyledActionButton>
  )
}
