import { Tab, TabList } from '@headlessui/react'
import styled from 'styled-components'

export const StyledTabList = styled(TabList)`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
  background-color: ${({ theme }) => theme.colors.common.white};
  padding: 0 94px;
`

export const StyledTab = styled(Tab)`
  padding: 6px 12px;
  color: ${({ theme }) => theme.colors.common.black75};

  &[data-headlessui-state~='selected'] {
    color: ${({ theme }) => theme.colors.common.accentDark};
    border-bottom: 2px solid ${({ theme }) => theme.colors.common.accentDark};
  }

  & + & {
    border-left: none;
  }
`
