import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxMagnifyingGlass } from 'react-icons/rx'
import styled from 'styled-components'

import CloseIcon from '@/assets/svgs/close-line.svg'
import { Dropdown } from '@/components/common/Dropdown.tsx'
import { Search } from '@/components/common/Search.tsx'
import { SidebarContainer } from '@/components/common/SidebarContainer.tsx'
import { Header4 } from '@/components/common/Text.tsx'
import { TileSmall } from '@/components/common/TileSmall.tsx'
import { useProspectusContext } from '@/contexts/useProspectusContext.ts'
import { useProspectuses } from '@/hooks/data/useProspectus.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseButton = styled.button`
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.background};
  }
`

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  gap: 16px;
`

const DropdownContainer = styled.div`
  min-width: 200px;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
`
export const ProspectusSidebar = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'prospectus',
  })
  const { patientId } = usePatientPulseIdFromParams()
  const { prospectuses } = useProspectuses()
  const {
    actions: { addProspectus, closeProspectusSidebar, removeProspectus },
    prospectuses: patientProspectuses,
  } = useProspectusContext()
  const [query, setQuery] = useState<string | undefined>()
  const [categoryFilter, setCategoryFilter] = useState<string>('all')

  const categories = prospectuses?.map((prospectus) => ({
    id: prospectus.category,
    title: prospectus.category,
  }))

  const filteredProspectuses = prospectuses
    ?.filter((prospectus) => {
      return query
        ? prospectus.title.toLowerCase().includes(query.toLowerCase())
        : true
    })
    .filter((prospectus) => {
      return categoryFilter && categoryFilter !== 'all'
        ? prospectus.category === categoryFilter
        : true
    })

  if (!patientId) {
    return <></>
  }
  return (
    <SidebarContainer>
      <Header>
        <Header4>{t('sidebarTitle')}</Header4>
        <CloseButton onClick={closeProspectusSidebar}>
          <img alt="close button" src={CloseIcon} />
        </CloseButton>
      </Header>
      <Search>
        <input
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('searchPlaceholder')}
        ></input>
        <RxMagnifyingGlass />
      </Search>
      {categories && categories.length > 0 && (
        <DropdownContainer>
          <Dropdown
            items={[
              {
                id: 'all',
                title: t('allCategories'),
              },
              ...categories,
            ]}
            onSelect={setCategoryFilter}
            prompt={t('filterPlaceholder')}
            selectedItemId={categoryFilter}
          />
        </DropdownContainer>
      )}
      <TilesContainer>
        {filteredProspectuses?.map((prospectus) => {
          const selected = !!patientProspectuses?.find(
            (patientProspectus) => patientProspectus.id === prospectus.id,
          )

          return (
            <TileSmall
              key={prospectus.id}
              imageSrc={prospectus.image}
              onClick={() =>
                selected
                  ? removeProspectus(prospectus.id)
                  : addProspectus(prospectus)
              }
              selected={selected}
              title={prospectus.title}
            />
          )
        })}
      </TilesContainer>
    </SidebarContainer>
  )
}
