import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import InstructionsLine from '@/assets/svgs/instructions-line.svg'
import { Accordion } from '@/components/common/Accordion.tsx'
import { BodyM, BodyS } from '@/components/common/Text.tsx'
import { usePatientQuestionnairesTasks } from '@/hooks/data/usePatientQuestionnaires.ts'
import type { QuestionnaireTask } from '@/types/questionnaire.ts'

import { CardStack } from './CardStack.tsx'
import { DateSpan } from './DateSpan.tsx'
import { CardContainer, CardWrapper, IconWrapper } from './Sidebar.styled.tsx'
import { TabSkeleton } from './TabSkeleton.tsx'

const LinkIcon = styled.a`
  flex: none;
  color: ${(props) => props.theme.colors.primary.main};
`

type AppointmentCardContainerProps = {
  $isExpired?: boolean
}

const QuestionnaireCardContainer = styled(
  CardContainer,
)<AppointmentCardContainerProps>(({ $isExpired, theme }) =>
  $isExpired
    ? `
    text-decoration: none;
    opacity: 50%;
    background-color: ${theme.colors.common.black05}
  `
    : `
    text-decoration: none;
    opacity: 100%;
    background-color: transparent;
`,
)

const DateCaption = styled(DateSpan)`
  margin-left: 8px;
`

const NoContent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.questionnaires',
  })

  return <BodyM>{t('noContent')}</BodyM>
}

const QuestionnaireStack = ({
  isExpired = false,
  isUrgent = false,
  questionnaires,
  title,
}: {
  isExpired?: boolean
  isUrgent?: boolean
  questionnaires: QuestionnaireTask[]
  title: string
}) => (
  <>
    {questionnaires.length > 0 && (
      <CardStack isUrgent={isUrgent} title={title}>
        {questionnaires.map((questionnaire) => (
          <QuestionnaireCard
            key={questionnaire.id}
            isExpired={isExpired}
            questionnaire={questionnaire}
          />
        ))}
      </CardStack>
    )}
  </>
)

const QuestionnaireList = ({
  questionnaires,
}: {
  questionnaires: QuestionnaireTask[]
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.questionnaires',
  })

  const todo: QuestionnaireTask[] = []
  const expired: QuestionnaireTask[] = []
  const completed: QuestionnaireTask[] = []

  questionnaires.forEach((questionnaire) => {
    if (questionnaire.status === 'rejected') {
      expired.push(questionnaire)
    } else if (questionnaire.status === 'completed') {
      completed.push(questionnaire)
    } else {
      todo.push(questionnaire)
    }
  })

  return (
    <React.Fragment>
      <QuestionnaireStack questionnaires={todo} title={t('todo')} />
      <QuestionnaireStack questionnaires={completed} title={t('completed')} />
      <QuestionnaireStack
        isExpired={true}
        questionnaires={expired}
        title={t('expired')}
      />
    </React.Fragment>
  )
}

const QuestionnaireCard = ({
  isExpired,
  questionnaire,
}: {
  isExpired?: boolean
  questionnaire: QuestionnaireTask
}) => {
  return (
    <QuestionnaireCardContainer $isExpired={isExpired}>
      <IconWrapper src={InstructionsLine}></IconWrapper>
      <CardWrapper>
        <BodyM color="black" fontWeight="bold">
          {questionnaire.name ?? questionnaire.displayName}
          {questionnaire.executionPeriod.start && (
            <DateCaption date={questionnaire.executionPeriod.start} />
          )}
        </BodyM>
        <BodyS>{questionnaire?.round?.description}</BodyS>
      </CardWrapper>
    </QuestionnaireCardContainer>
  )
}

type QuestionnairesProps = {
  patientId: string
}

const Questionnaires = ({ patientId }: QuestionnairesProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.questionnaires',
  })

  const { isLoading, questionnaires } = usePatientQuestionnairesTasks(patientId)

  if (isLoading) {
    return <TabSkeleton />
  }

  if (!isLoading && (!questionnaires || questionnaires.length === 0)) {
    return <NoContent />
  }

  const organizationQuestionnaires =
    questionnaires?.filter((t) => t.owner.type === 'Organization') || []

  const patientQuestionnaires =
    questionnaires?.filter((t) => t.owner.type === 'Patient') || []

  return (
    <>
      {organizationQuestionnaires.length > 0 && (
        <Accordion title={t('therapist')}>
          <QuestionnaireList questionnaires={organizationQuestionnaires} />
        </Accordion>
      )}

      {patientQuestionnaires.length > 0 && (
        <Accordion title={t('patient')}>
          <QuestionnaireList questionnaires={patientQuestionnaires} />
        </Accordion>
      )}
    </>
  )
}

export const QuestionnairesTabContent = ({
  pulseIds,
}: {
  pulseIds?: string
}) => {
  if (!pulseIds) {
    return <NoContent />
  }
  return <Questionnaires patientId={pulseIds} />
}
