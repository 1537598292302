import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { RxMagnifyingGlass } from 'react-icons/rx'
import styled from 'styled-components'

import { Avatar } from '@/components/common/Avatar'
import { StyledActionButton } from '@/components/common/Buttons'
import {
  CloseModalButton,
  ModalActionButtons,
  ModalBackground,
  StyledModal,
} from '@/components/common/Modal.tsx'
import { Search } from '@/components/common/Search.tsx'
import { Header3 } from '@/components/common/Text.tsx'
import { useSocket } from '@/contexts/useSocketContext.ts'
import { useClinicians } from '@/state/hooks/useClinicians.ts'
import { useInternalConversation } from '@/state/hooks/useInternalConversation.ts'
import type { Clinician } from '@/state/stateType.ts'
import { getInitials } from '@/util/getInitials'

const AssignConversationModalBackground = styled(StyledModal)`
  width: 350px;
  /* height: 520px; */
  overflow: hidden;
  padding: 0;
  gap: 0;
`

const TopSection = styled.div`
  width: 100%;
  flex: 1;
  padding: 18px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ConfirmAssignModal = styled(StyledModal)`
  width: 440px;
  text-align: center;
`

const AssignConversationModalSearchBar = styled(Search)`
  width: 100%;
  margin-bottom: 12px;
`

const AssignClinicianOptionContainer = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing}px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 350px;
  padding-bottom: 20px;
`

const AssignClinicianOption = styled.li<{ selected: boolean }>`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;

  border: 1px solid ${(props) => props.theme.colors.common.border};
  border-radius: 4px;
  cursor: pointer;
  ${({ selected, theme }) =>
    selected &&
    `
       border-color: ${theme.colors.common.border};
       background-color: ${theme.colors.common.background};
 `};
  &:hover {
    background-color: ${({ theme }) => theme.colors.common.background};
  }
`

const AssignClinicianHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const ModalButtons = styled(ModalActionButtons)`
  padding: 20px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.common.border}`};
`

export function AssignConversationModal({
  closeModal,
}: {
  closeModal: () => void
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.assign',
  })

  const [selectedClinician, setSelectedClinician] = useState<Clinician>()
  const [modalContainer] = useState(document.getElementById('modal-container'))
  const [searchParameter, setSearchParameter] = useState('')
  const [allowAssign, setAllowAssign] = useState(false)

  const clinicians = useClinicians()
  const internalConversation = useInternalConversation()
  const { assignActiveConversation, closeInternalConversation } = useSocket()

  const filteredClinicians = clinicians.filter((c) =>
    c.name.toLowerCase().includes(searchParameter.toLowerCase()),
  )

  if (internalConversation && !allowAssign) {
    return (
      modalContainer &&
      createPortal(
        <ModalBackground closeModal={closeModal}>
          <ConfirmAssignModal>
            <Header3>{t('confirmModal.header')}</Header3>
            <p>{t('confirmModal.text')}</p>
            <ModalActionButtons>
              <StyledActionButton onClick={closeModal} variant="Secondary">
                {t('confirmModal.cancel')}
              </StyledActionButton>
              <StyledActionButton
                onClick={() => {
                  setAllowAssign(true)
                }}
                variant="Secondary"
              >
                {t('confirmModal.confirm')}
              </StyledActionButton>
            </ModalActionButtons>
          </ConfirmAssignModal>
        </ModalBackground>,
        modalContainer,
      )
    )
  }

  return (
    modalContainer &&
    createPortal(
      <ModalBackground closeModal={closeModal}>
        {(!internalConversation || allowAssign) && (
          <AssignConversationModalBackground>
            <TopSection>
              <AssignClinicianHeader>
                <Header3>{t('modal.header')}</Header3>
                <CloseModalButton onClick={closeModal} />
              </AssignClinicianHeader>
              <AssignConversationModalSearchBar>
                <input
                  onChange={(e) => setSearchParameter(e.target.value)}
                  placeholder={t('modal.inputPlaceholder')}
                />
                <RxMagnifyingGlass />
              </AssignConversationModalSearchBar>
              <AssignClinicianOptionContainer>
                {filteredClinicians.map((clinician) => (
                  // eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-noninteractive-element-interactions
                  <AssignClinicianOption
                    key={clinician.id}
                    data-testid={`assignTo${clinician.id}`}
                    onClick={() => {
                      setSelectedClinician(clinician)
                    }}
                    selected={selectedClinician?.id === clinician.id}
                  >
                    <Avatar fontWeight="bold">
                      {getInitials(clinician.name)}
                    </Avatar>
                    {clinician.name}
                  </AssignClinicianOption>
                ))}
              </AssignClinicianOptionContainer>
            </TopSection>

            <ModalButtons>
              <StyledActionButton
                disabled={!selectedClinician}
                onClick={() => {
                  if (selectedClinician) {
                    if (internalConversation) {
                      closeInternalConversation(internalConversation.id)
                    }
                    assignActiveConversation(selectedClinician)
                    closeModal()
                  }
                }}
                variant="Confirm"
              >
                {t('modal.button')}
              </StyledActionButton>
            </ModalButtons>
          </AssignConversationModalBackground>
        )}
      </ModalBackground>,
      modalContainer,
    )
  )
}
