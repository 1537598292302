import handleInternalConversationCreated from '@/state/eventHandlers/handleCloseInternalConversation.ts'
import handleInternalChatMessageAdded from '@/state/eventHandlers/handleInternalChatMessageAdded.ts'
import * as E from '~common/serverToClientParser'

import handleChatMessageAdded from './eventHandlers/chatMessageAdded'
import handleConversationAssigned from './eventHandlers/conversationAssigned'
import handleConversationClosed from './eventHandlers/conversationClosed'
import handleConversationCreated from './eventHandlers/conversationCreated'
import handleConversationMoved from './eventHandlers/conversationMoved'
import handleConversationReasonForEntryChanged from './eventHandlers/conversationReasonForEntryChanged'
import handleConversationUnassigned from './eventHandlers/conversationUnassigned'
import handleConversationUserTyped from './eventHandlers/handleConversationUserTyped'
import handleCreateInternalConversation from './eventHandlers/handleCreateInternalConversation'
import handleInitialData from './eventHandlers/initialData'
import { AppState } from './stateType'

export default function reduce(
  state: AppState,
  ev: E.ServerToClientEvent,
): AppState {
  switch (ev.type) {
    case 'InitialData':
      return handleInitialData(state, ev)

    case 'ConversationCreated':
      return handleConversationCreated(state, ev)

    case 'ChatMessageAdded':
      return handleChatMessageAdded(state, ev)

    case 'ConversationAssigned':
      return handleConversationAssigned(state, ev)

    case 'ConversationClosed':
      return handleConversationClosed(state, ev)

    case 'ConversationMoved':
      return handleConversationMoved(state, ev)

    case 'ConversationReasonForEntryChanged':
      return handleConversationReasonForEntryChanged(state, ev)

    case 'ConversationUnassigned':
      return handleConversationUnassigned(state, ev)

    case 'UserTyped':
      return handleConversationUserTyped(state, ev)

    case 'InternalConversationCreated':
      return handleCreateInternalConversation(state, ev)

    case 'InternalConversationClosed':
      return handleInternalConversationCreated(state)

    case 'InternalChatMessageAdded':
      return handleInternalChatMessageAdded(state, ev)

    case 'Error':
      return state

    default:
      console.log('unhandled event: ', ev)
      return state
  }
}
