import { FiAlertTriangle } from 'react-icons/fi'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.theme.colors.common.error};
`

const ErrorMessageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AlertIcon = styled(FiAlertTriangle)`
  margin-right: 8px;

  color: ${(props) => props.theme.colors.destructive.main};
`

const CloseIcon = styled(IoIosCloseCircleOutline)`
  margin-right: 8px;
  cursor: pointer;
  flex-grow: 0;
`
interface ErrorProps {
  message: string
  onClose: () => void
}

export function ErrorTop({ message, onClose }: ErrorProps) {
  return (
    <ErrorContainer>
      <ErrorMessageContainer>
        <AlertIcon />
        {message}
      </ErrorMessageContainer>
      <CloseIcon onClick={onClose} size={24} />
    </ErrorContainer>
  )
}
