import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons'
import { Header1 } from '@/components/common/Text'
import { firebaseAuth } from '@/util/firebase'

import { NAVBAR_HEIGHT } from '../NavigationBar/NavigationBar.styled'

const Container = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  justify-content: center;
`

type ErrorProps = {
  hasLogoutButton: boolean
  text: string
}

export const Error = ({ hasLogoutButton, text }: ErrorProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' })

  return (
    <Container>
      <Header1>{text}</Header1>
      {hasLogoutButton && (
        <StyledActionButton
          onClick={() => firebaseAuth.signOut()}
          variant="Primary"
        >
          {t('logout')}
        </StyledActionButton>
      )}
    </Container>
  )
}
