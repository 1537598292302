import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import styled from 'styled-components'

import { Conversation } from '@/state/stateType.ts'

import { BodySmall, TinyTitle } from '../../common/Text.tsx'
import { ConversationSelector } from './ConversationSelector.tsx'

dayjs.extend(RelativeTime)

interface QueueProps {
  activeConversationId: string | undefined
  assignedToClinician: boolean
  conversations: Conversation[]
  setActive: (value: string) => void
  title: string
}

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
`

const QueueTitleAndSize = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 12px;
`

export function Queue({
  activeConversationId,
  assignedToClinician,
  conversations,
  setActive,
  title,
}: QueueProps) {
  return (
    <>
      <QueueTitleAndSize>
        <TinyTitle data-testid={`queue${title}`}>{title}</TinyTitle>
        <BodySmall>{conversations.length}</BodySmall>
      </QueueTitleAndSize>
      <Navigation data-testid={`navigation${title}`}>
        <ListContainer>
          {conversations.map((conversation) => (
            <ConversationSelector
              key={conversation.id}
              assignedToClinician={assignedToClinician}
              conversation={conversation}
              isActive={conversation.id === activeConversationId}
              onSelect={() => setActive(conversation.id)}
            />
          ))}
        </ListContainer>
      </Navigation>
    </>
  )
}
