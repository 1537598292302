import styled from 'styled-components'

import { BodyM } from './Text'

export const Avatar = styled(BodyM)`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.common.accentDark};
  color: ${({ theme }) => theme.colors.common.white};
`
