import styled from 'styled-components'

type StackProps = {
  alignItems?: string
  direction: 'row' | 'column'
  spacing: number
}

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => `${props.spacing}px`};
  align-items: ${(props) => props.alignItems};
`
