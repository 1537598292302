import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { io, Socket } from 'socket.io-client'
import { v4 } from 'uuid'

import { store } from '@/app.ts'
import i18n from '@/i18n'
import { AppState, Clinician, Conversation } from '@/state/stateType.ts'
import { getActiveConversation } from '@/state/stateUtil.ts'
import { throttle } from '@/util/function.ts'
import { uploadFiles } from '@/util/storage.ts'
import { ClientToServerEvent } from '~common/clientToServerParser'
import { ServerToClientMessageParser } from '~common/serverToClientParser'

export type SocketContextValue = {
  addInternalMessage: (
    internalConversationId: string,
    content: string,
    files: File[],
  ) => void
  addMessageToActiveConversation: (
    content: string,
    files: File[],
  ) => Promise<void>
  assignActiveConversation: (clinician: Clinician) => void
  changeActiveConversationQueue: (queueId: string) => void
  changeReasonForEntry: (reasonForEntryId: string) => void
  closeActiveConversation: () => void
  closeInternalConversation: (internalConversationId: string) => void
  createInternalConversation: (clinician: Clinician) => void
  setNotification: (value?: string) => void
  socket: Socket
  throttledSendTypingIndicator: () => void
  unassignActiveConversation: () => void
}

export const SocketContext = React.createContext<SocketContextValue | null>(
  null,
)

export const SocketProvider = ({
  children,
  token,
}: {
  children: ReactNode
  token: string
}): ReactElement => {
  const registered = useRef(false)

  const socket = useMemo(
    () =>
      io(import.meta.env.API_URL, {
        auth: {
          token: `Bearer ${token}`,
        },
        autoConnect: false,
        extraHeaders: {
          'Accept-Language': i18n.language,
        },
        path: '/ws',
        reconnection: true,
        withCredentials: true,
      }),
    [token],
  )

  useEffect(() => {
    if (!token) throw new Error('No token')
    socket.connect()

    socket.on('connect', () => {
      // console.log('Connected to server')
    })

    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        socket.connect()
      }
    })

    socket.on('event', (event: unknown) => {
      handleEventFromServer(event)
    })

    return () => {
      socket.removeAllListeners()
      socket.disconnect()
    }
  }, [registered, socket, token])

  function handleEventFromServer(data: unknown) {
    try {
      const event = ServerToClientMessageParser.parse(data)
      if (event.type === 'Error') {
        store.setError(event.code)
      } else {
        handleAssignInternalConversation(event)
        store.handleEvent(event)
        store.setError(undefined)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(`Server sent us invalid message.`, e.message)
    }
  }

  function handleAssignInternalConversation(event: {
    id: string
    type: string
  }) {
    if (event.type === 'InternalConversationCreated') {
      const cmd = {
        conversationId: event.id,
        type: 'InternalConversationAssigned',
      }
      // add check that its assigned
      sendCommandWithResponse(cmd)
    }
  }

  function handleSendError(e: unknown) {
    console.error(e)
    if (e instanceof Error) {
      store.setError(`Error sending a command to server: ${e.message}`)
    } else {
      store.setError('Error occurred')
    }
  }

  function sendConversationRequest<T extends ClientToServerEvent>(
    createCommand: (c: Conversation, state: AppState) => T,
  ) {
    const state = store.getSnapshot()
    const conversation = getActiveConversation(state)
    if (!conversation) {
      return
    }
    const cmd = createCommand(conversation, state)

    sendCommandWithResponse(cmd)
      .then(handleEventFromServer)
      .catch(handleSendError)
  }

  function sendCommandWithResponse(data: unknown): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (!socket.connected) {
        reject(new Error('No connection'))
      }

      socket.emit('command', data, resolve)
    })
  }

  function closeActiveConversation() {
    sendConversationRequest((c) => ({
      conversationId: c.id,
      id: v4(),
      timestamp: new Date().toISOString(),
      type: 'CloseConversation',
    }))
  }

  async function addMessageToActiveConversation(
    content: string,
    files: File[],
  ) {
    showMessageLoadingIndicator(true)
    const attachments = await uploadFiles(import.meta.env.API_URL, token, files)
    sendConversationRequest((c) => {
      sendTypingIndicator(false)
      showMessageLoadingIndicator(false)
      return {
        attachments,
        content,
        conversationId: c.id,
        id: v4(),
        timestamp: new Date().toISOString(),
        type: 'AddChatMessage',
      }
    })
  }

  function changeActiveConversationQueue(queueId: string) {
    sendConversationRequest((c) => {
      sendTypingIndicator(false)
      return {
        conversationId: c.id,
        id: v4(),
        priority: c.priority,
        queueId,
        timestamp: new Date().toISOString(),
        type: 'MoveConversation',
      }
    })
  }

  function assignActiveConversation(clinician: Clinician) {
    sendConversationRequest((c) => ({
      conversationId: c.id,
      id: v4(),
      timestamp: new Date().toISOString(),
      type: 'AssignConversation',
      userId: clinician.id,
    }))
    if (IsNotLoggedInUser(clinician)) {
      setNotification(`Conversation assigned to ${clinician.name}`)
      store.setActiveConversation(undefined)
    }
  }

  function unassignActiveConversation() {
    sendConversationRequest((c) => ({
      conversationId: c.id,
      id: v4(),
      timestamp: new Date().toISOString(),
      type: 'UnassignConversation',
    }))
  }

  function setNotification(value?: string) {
    store.setNotification(value)
  }

  function changeReasonForEntry(reasonForEntryId: string) {
    sendConversationRequest((c) => ({
      conversationId: c.id,
      id: v4(),
      reasonForEntryId,
      timestamp: new Date().toISOString(),
      type: 'ChangeReasonForEntry',
    }))
  }

  function sendTypingIndicator(isTyping: boolean) {
    sendConversationRequest((c) => ({
      conversationId: c.id,
      id: v4(),
      isTyping,
      timestamp: new Date().toISOString(),
      type: 'SendTypingIndicator',
    }))
  }

  function showMessageLoadingIndicator(isLoading: boolean) {
    const state = store.getSnapshot()
    const conversation = getActiveConversation(state)
    if (!conversation) {
      return
    }
    store.setIsAttachmentLoading({
      conversationId: conversation.id,
      isLoading,
    })
  }

  const throttledSendTypingIndicator = throttle(
    () => sendTypingIndicator(true),
    1,
  )

  function IsNotLoggedInUser(clinician: Clinician) {
    const state = store.getSnapshot()
    return clinician.id !== state.user.id
  }

  function createInternalConversation(clinician: Clinician) {
    sendConversationRequest((c) => ({
      assignedToId: clinician.id,
      conversationId: c.id,
      type: 'CreateInternalConversation',
    }))
  }

  function closeInternalConversation(internalConversationId: string) {
    sendConversationRequest((c) => ({
      conversationId: c.id,
      internalConversationId: internalConversationId,
      type: 'CloseInternalConversation',
    }))
  }

  async function addInternalMessage(
    internalConversationId: string,
    content: string,
    files: File[],
  ) {
    const attachments = await uploadFiles(import.meta.env.API_URL, token, files)
    sendConversationRequest((c) => ({
      attachments,
      content,
      conversationId: c.id,
      internalConversationId,
      type: 'AddInternalChatMessage',
    }))
  }

  return (
    <SocketContext.Provider
      value={{
        addInternalMessage,
        addMessageToActiveConversation,
        assignActiveConversation,
        changeActiveConversationQueue,
        changeReasonForEntry,
        closeActiveConversation,
        closeInternalConversation,
        createInternalConversation,
        setNotification,
        socket,
        throttledSendTypingIndicator,
        unassignActiveConversation,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}
