import { Attachment } from '~common/clientToServerParser'
import { ConversationPriority } from '~common/commonParser'

import { AppState, Conversation } from './stateType'

export function getAmountOfLatestCustomerMessages(c: Conversation): number {
  const chatMessages = c.events.filter((e) => e.type === 'ChatMessageAdded')

  const lastClinicianMessageIndex = chatMessages
    .sort((x, y) => (new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1))
    .findIndex((e) => e.isClinician)
  const noClinicianMessages = lastClinicianMessageIndex === -1

  return noClinicianMessages ? chatMessages.length : lastClinicianMessageIndex
}

export function getConversationsAssignedToMe(state: AppState): Conversation[] {
  return sortConversations(
    state.conversations.filter(
      (c) =>
        (c.assignedToId !== undefined &&
          !c.closedAt &&
          c.assignedToId === state.user.id) ||
        c.internalConversation?.assignedToId === state.user.id,
    ),
  )
}

export function getUnassignedConversations(state: AppState): Conversation[] {
  return sortConversations(
    state.conversations.filter((c) => !c.assignedToId && !c.closedAt),
  )
}

export function getConversationAttachments(
  conversation: Conversation | undefined,
) {
  const attachments = conversation?.events.reduce<Attachment[]>(
    (acc, event) => {
      if (
        event &&
        event.type === 'ChatMessageAdded' &&
        event.attachments.length > 0
      ) {
        acc = acc.concat(event.attachments)
      }

      return acc
    },
    [],
  )

  return attachments ?? []
}

export function getActiveConversation(
  state: AppState,
): Conversation | undefined {
  return state.activeConversationId !== undefined
    ? state.conversations.find((c) => c.id === state.activeConversationId)
    : undefined
}

export function getFirstPatientMessageTime(c: Conversation): Date | undefined {
  const ts = c.events.filter(
    (e) => e.type === 'ChatMessageAdded' && !e.isClinician,
  )[0]?.timestamp

  return ts ? new Date(ts) : undefined
}

// Note: Mutates the given array
export function sortConversations(c: Conversation[]): Conversation[] {
  return c.sort(orderByPriorityAndFirstMessageTime)
}

const priorityOrder: Record<ConversationPriority, number> = {
  high: 2,
  low: 0,
  medium: 1,
}

function orderByPriorityAndFirstMessageTime(
  a: Conversation,
  b: Conversation,
): number {
  const priorityDiff = priorityOrder[b.priority] - priorityOrder[a.priority]
  if (priorityDiff !== 0) return priorityDiff

  const at = getFirstPatientMessageTime(a)
  if (!at) return 1
  const bt = getFirstPatientMessageTime(b)
  if (!bt) return -1
  return at.getTime() - bt.getTime()
}
