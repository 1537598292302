import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Header1 } from '@/components/common/Text.tsx'
import {
  SelectExercisesPathParams,
  selectPatientPath,
  SelectPatientPathParams,
} from '@/router/paths.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.colors.common.border};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 800px;
  text-align: center;
`

export const ProgramNotFound = () => {
  const navigate = useNavigate()
  const { programId } = useParams() as SelectExercisesPathParams
  const { patientId } = usePatientPulseIdFromParams() as SelectPatientPathParams
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy',
  })

  return (
    <Main>
      <Content>
        <Header1>{t('notFound.title', { programId })}</Header1>
        <StyledActionButton
          onClick={() => navigate(selectPatientPath({ patientId }))}
          variant="Primary"
        >
          {t('notFound.backTo')}
        </StyledActionButton>
      </Content>
    </Main>
  )
}
