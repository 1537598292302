import { Empty } from '@/loadState'
import { parseStringDate } from '@/util/date'
import { setProps } from '@/util/object.ts'
import { InitialData } from '~common/serverToClientParser'

import { AppState, Conversation } from '../stateType'

export default function handleInitialData(
  state: AppState,
  ev: InitialData,
): AppState {
  const conversations: Conversation[] = ev.conversations.map((c) => ({
    ...c,
    closedAt: c.closedAt,
    eventLoadState: Empty,
    lastMessageTime: parseStringDate(c.lastMessageTime),
  }))

  return setProps(state, {
    clinicians: ev.clinicians,
    conversations,
    isInitialized: true,
    queues: ev.queues,
    reasonsForEntry: ev.reasonsForEntry,
    user: ev.user,
  })
}
