export type LoadState =
  | { type: 'empty' }
  | { type: 'loading' }
  | { type: 'success' }
  | { message: string; type: 'error' }

export const Empty: LoadState = { type: 'empty' }
export const Loading: LoadState = { type: 'loading' }
export const Success: LoadState = { type: 'success' }
export const Error = (message: string): LoadState => ({
  message,
  type: 'error',
})
