import { useAppStore } from '@/app.ts'
import { getActiveConversation } from '@/state/stateUtil.ts'

export const useInternalConversation = () => {
  const state = useAppStore()

  const activeConversation = getActiveConversation(state)

  return activeConversation?.internalConversation
}
