import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { Header3 } from '@/components/common/Text.tsx'
import { useUsers } from '@/hooks/admin/useUsers.ts'
import { BarChart } from '@/screens/admin/UserManagement/dashboard/Charts/BarChart.tsx'
import { PieChart } from '@/screens/admin/UserManagement/dashboard/Charts/PieChart.tsx'
import * as S from '@/screens/admin/UserManagement/dashboard/Dashboard.styled.ts'
import {
  DashboardCard,
  DashboardValue,
} from '@/screens/admin/UserManagement/dashboard/DashboardCard.tsx'
import { isVerified } from '@/screens/admin/UserManagement/dashboard/utils.ts'
import { DownloadCSVButton } from '@/screens/admin/UserManagement/DownloadCSVButton.tsx'

export const UsersDashboard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminDashboard.users',
  })
  const { isLoading, users } = useUsers()

  const usersCount = users.length
  const allVerifiedUsers = users.filter(isVerified)
  const patients = allVerifiedUsers.filter(
    (user) => user.customClaims?.patientId,
  )
  const patientsCount = patients.length

  const currentYear = dayjs().year()
  const currentMonth = dayjs().month()

  const newUsersPerMonth = dayjs.months().reduce((arr, _, index) => {
    if (index > currentMonth) return arr
    return [
      ...arr,
      {
        count: patients.filter((user) => {
          return (
            dayjs(user.metadata.creationTime).month() === index &&
            dayjs(user.metadata.creationTime).year() === currentYear
          )
        }).length,
        name: dayjs().month(index).format('MMM'),
      },
    ]
  }, [] as any[])

  const cliniciansCount = users.filter(
    (user) => user.customClaims?.roles,
  )?.length

  const activePatients = patients.filter((patient) => {
    return dayjs(patient.metadata.lastRefreshTime).month() === currentMonth
  })

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return (
    <S.Section>
      <S.HeaderBar>
        <Header3>{t('title')}</Header3>
        <DownloadCSVButton
          data={users
            .filter((user) => user.customClaims?.patientId)
            .map((user) => ({
              creationDate: user.metadata.creationTime,
              lastRefresh: user.metadata.lastRefreshTime,
              patientId: user.customClaims.patientId,
            }))}
          fileName="users"
          label={t('actions.download')}
        />
      </S.HeaderBar>
      <S.Row>
        <DashboardCard
          caption={t('chart.activePatients.caption', {
            activePatients: activePatients.length,
            patients: patients.length,
          })}
          title={t('chart.activePatients.title')}
        >
          <DashboardValue>
            {((activePatients.length / patients.length) * 100).toFixed(2)} %
          </DashboardValue>
        </DashboardCard>
        <DashboardCard
          caption={t('chart.totalUsers.caption', {
            users: usersCount,
          })}
          title={t('chart.totalUsers.title')}
        >
          <PieChart
            data={[
              { count: patientsCount, key: t('chart.pie.verifiedPatients') },
              {
                count: cliniciansCount,
                key: t('chart.pie.clinicians'),
              },
              {
                count: usersCount - patientsCount - cliniciansCount,
                key: t('chart.pie.unverified'),
              },
            ].filter((users) => users.count > 0)}
          />
        </DashboardCard>

        <DashboardCard
          caption={t('chart.newUsersByMonth.caption', {
            year: currentYear,
          })}
          title={t('chart.newUsersByMonth.title')}
        >
          <BarChart
            bar1DataKey="count"
            data={newUsersPerMonth}
            xDataKey="name"
          ></BarChart>
        </DashboardCard>
      </S.Row>
    </S.Section>
  )
}
