import { mapSelected } from '../../util/array'
import { setProp } from '../../util/object'
import { AppState, Conversation } from '../stateType'

export default function mapStateConversations(
  id: string,
  map: (c: Conversation) => Conversation,
  state: AppState,
): AppState {
  const conversations = mapSelected(
    (c) => c.id === id,
    map,
    state.conversations,
  )
  return setProp(state, 'conversations', conversations)
}
