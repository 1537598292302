import { useEffect } from 'react'
import Smartlook from 'smartlook-client'

import { Clinician } from '@/state/stateType'

const projectId = import.meta.env.SMARTLOOK_KEY
Smartlook.init(projectId, { region: 'eu' })

export const useSmartLook = (clinician: Clinician) => {
  useEffect(() => {
    if (clinician?.id) {
      Smartlook.identify(clinician.id, {
        name: clinician.name,
      })
    }
  }, [clinician])
}
