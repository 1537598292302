import dayjs from 'dayjs'
import React, { ForwardedRef, forwardRef, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { RxArrowRight, RxPerson } from 'react-icons/rx'
import InfiniteScroll from 'react-infinite-scroll-component'
import { createSearchParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Stack } from '@/components/common/Stack.tsx'
import { BodyM, BodyS, BodySmall } from '@/components/common/Text.tsx'
import { usePatientAppointments } from '@/hooks/data/usePatientAppointments.ts'
import { useActiveConversation } from '@/state/hooks/useActiveConversation.ts'
import { Appointment } from '@/types/appointment.ts'
import { ENABLE_VIDEO_CALL } from '@/util/featureToggles.ts'

import { CardStack } from './CardStack.tsx'
import { DateTime } from './DateSpan.tsx'
import { CardContainer, CardWrapper } from './Sidebar.styled.tsx'
import { TabSkeleton } from './TabSkeleton.tsx'

interface AppointmentCardContainerProps {
  readonly $isBefore?: boolean
  readonly warning?: boolean
}

const AppointmentCardContainer = styled(
  CardContainer,
)<AppointmentCardContainerProps>(({ $isBefore, theme }) =>
  $isBefore
    ? `
    opacity: 50%;
    background-color: ${theme.colors.common.black05}
  `
    : `
    opacity: 100%;
    background-color: transparent;
`,
)

const JoinCallButton = styled(StyledActionButton)`
  align-self: center;
`

const InfiniteScrollMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  width: 100%;
`

const Avatar = styled.img`
  vertical-align: middle;
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.colors.common.black75};

  > svg {
    width: 28px;
    height: 28px;
  }
`

const NoContent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.carePath',
  })
  return <BodyM>{t('noContent')}</BodyM>
}

type AppointmentCardProps = {
  appointment: Appointment
}

const AppointmentCard = forwardRef(
  (
    { appointment }: AppointmentCardProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const navigate = useNavigate()
    const { t } = useTranslation('translation', {
      keyPrefix: 'patientSidebar.carePath',
    })
    const activeConversation = useActiveConversation()

    const navigateToPreCall = () => {
      const patient = activeConversation?.patient

      return navigate({
        pathname: '/pre-call',
        search: createSearchParams({
          code: appointment.id.toString(),
          date: appointment.start,
          patientName: patient ? patient.name : '',
        }).toString(),
      })
    }

    const shouldShowJoinCall =
      ENABLE_VIDEO_CALL &&
      dayjs().isSame(appointment.start, 'day') &&
      appointment.type === 'video'

    return (
      <AppointmentCardContainer
        ref={ref}
        $isBefore={dayjs(appointment.start).isBefore(dayjs())}
      >
        <CardWrapper>
          <Stack alignItems="center" direction="row" spacing={8}>
            <div style={{ width: 60 }}>
              {appointment.practitioner?.picture ? (
                <Avatar
                  alt="Clinician"
                  src={appointment.practitioner?.picture}
                />
              ) : (
                <Icon>
                  <RxPerson />
                </Icon>
              )}
            </div>
            <div>
              <BodyM color="black" fontWeight="bold">
                {appointment.description}
              </BodyM>
              <BodySmall>{appointment.practitioner?.name}</BodySmall>
              <DateTime
                end={appointment.end}
                start={appointment.start}
                onlyDate={appointment.type === 'telephone'}
              ></DateTime>
              <BodyS>{appointment.location?.name}</BodyS>
            </div>
            {shouldShowJoinCall && (
              <JoinCallButton
                onClick={navigateToPreCall}
                size="small"
                variant="Primary"
              >
                {t('joinCall')}
                <RxArrowRight />
              </JoinCallButton>
            )}
          </Stack>
        </CardWrapper>
      </AppointmentCardContainer>
    )
  },
)
AppointmentCard.displayName = 'AppointmentCard'

type AppointmentsProps = {
  pulseIds?: string
  scrollableRef: React.RefObject<HTMLDivElement | null>
}

type AppointmentPropsWithPatientId = AppointmentsProps & {
  patientId: string
}

const Appointments = ({
  patientId,
  scrollableRef,
}: AppointmentPropsWithPatientId) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.carePath',
  })
  const todayRef = useRef<HTMLDivElement>(null)
  const plannedRef = useRef<HTMLDivElement>(null)

  const { appointments, fetchNextPage, hasNextPage, isLoading } =
    usePatientAppointments(patientId, 100)

  const groupedAppointments = groupAppointments(appointments)

  useEffect(() => {
    const scrollTo = todayRef.current
      ? todayRef.current
      : plannedRef.current
        ? plannedRef.current
        : undefined
    setTimeout(() => {
      scrollTo?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    })
  }, [todayRef.current, plannedRef.current])

  if (isLoading) {
    return <TabSkeleton />
  }

  if (!isLoading && (!appointments || appointments.length === 0)) {
    return <NoContent />
  }

  return (
    <InfiniteScroll
      dataLength={appointments.length}
      endMessage={
        <InfiniteScrollMessage>{t('noMoreAppointments')}</InfiniteScrollMessage>
      }
      hasMore={!!hasNextPage}
      loader={
        <InfiniteScrollMessage>
          {t('loadingAppointments')}
        </InfiniteScrollMessage>
      }
      next={() => fetchNextPage()}
      scrollableTarget={scrollableRef.current?.id || ''}
    >
      {groupedAppointments.past.length > 0 && (
        <CardStack title={t('past')}>
          {groupedAppointments.past.map((appointment) => (
            <AppointmentCard key={appointment.id} appointment={appointment} />
          ))}
        </CardStack>
      )}
      {groupedAppointments.today.length > 0 && (
        <CardStack ref={todayRef} title={t('today')}>
          {groupedAppointments.today.map((appointment) => (
            <AppointmentCard key={appointment.id} appointment={appointment} />
          ))}
        </CardStack>
      )}
      {groupedAppointments.future.length > 0 && (
        <CardStack ref={plannedRef} title={t('planned')}>
          {groupedAppointments.future.map((appointment) => (
            <AppointmentCard key={appointment.id} appointment={appointment} />
          ))}
        </CardStack>
      )}
    </InfiniteScroll>
  )
}

export const AppointmentTabContent = ({
  pulseIds,
  scrollableRef,
}: AppointmentsProps) => {
  if (!pulseIds) {
    return <NoContent />
  }

  return <Appointments patientId={pulseIds} scrollableRef={scrollableRef} />
}

function groupAppointments(
  appointments: Appointment[],
): Record<'future' | 'past' | 'today', Appointment[]> {
  if (!appointments?.length) return { future: [], past: [], today: [] }

  return appointments.reduce(
    (acc, appointment) => {
      const daysDiff = Math.round(dayjs(appointment.start).diff(dayjs(), 'day'))
      if (daysDiff < 0) {
        acc.past.push(appointment)
      } else if (daysDiff > 0) {
        acc.future.push(appointment)
      } else {
        acc.today.push(appointment)
      }
      return acc
    },
    { future: [], past: [], today: [] } as Record<
      'future' | 'past' | 'today',
      Appointment[]
    >,
  )
}
