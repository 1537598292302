import styled, { css } from 'styled-components'

import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'

export const SIDEBAR_WIDTH = 480

export const SidebarContainer = styled.div<{ expanding?: boolean }>`
  padding: 30px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${SIDEBAR_WIDTH}px;

  transition: width 0.4s ease-in-out;
  will-change: width;
  z-index: ${({ theme }) => theme.zIndex.sidebar};

  ${({ expanding }) =>
    expanding &&
    css`
      background-color: white;
      width: 100vw;
      position: absolute;
      right: 0;
      top: ${NAVBAR_HEIGHT}px;
    `};
`

export const Overlay = styled.div<{ expanding: boolean }>`
  position: fixed;
  top: ${NAVBAR_HEIGHT}px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ expanding }) => (expanding ? 1 : 0)};
  background-color: ${({ theme }) => theme.colors.common.black20};
  z-index: ${({ expanding }) => (expanding ? 1 : -1)};
  transition: opacity 0.4s;
`

export const InformationTabsContainer = styled.div`
  display: flex;
  height: 1px;
  flex-grow: 1;
  margin-top: 24px;
  flex-direction: column;
`

export const CardContainer = styled.button<{ active?: boolean }>`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  border: ${({ theme }) => `1px ${theme.colors.common.border} solid`};
  border-radius: 5px;

  a&:hover,
  button&:hover,
  &[role='button']:hover {
    background-color: ${({ theme }) => theme.colors.common.black10};
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.common.black05};
    `}
`

export const IconWrapper = styled.img`
  flex: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 4px;
  color: white;
  background-color: ${({ theme }) => theme.colors.common.black75};
`

export const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0 8px;
`

export const CardTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`
