import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosInformationCircle } from 'react-icons/io'
import styled from 'styled-components'

import { Stack } from '@/components/common/Stack'
import { Header4, Text } from '@/components/common/Text'
import { CarePath } from '@/types/patient'
import { groupCarePlans } from '@/util/care-plans'

interface TooltipPanelProps {
  side: 'left' | 'right'
}
const TooltipPanel = styled(PopoverPanel)<TooltipPanelProps>`
  position: relative;
  overflow: visible !important;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: ${({ theme }) => `1px solid ${theme.colors.common.black20}`};
  z-index: 20;

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: white;
    transform: rotate(45deg);
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.common.black20};
    border-width: 1px 0 0 1px;
    left: ${({ side }) => (side === 'left' ? '18px' : 'unset')};
    right: ${({ side }) => (side === 'right' ? '18px' : 'unset')};
    top: -9px;
  }
`

const TooltipContent = styled.div``

interface LabelProps {
  isPrimary: boolean
}
const Label = styled(Text)<LabelProps>`
  display: flex;
  color: ${({ isPrimary, theme }) =>
    isPrimary ? theme.colors.primary.main : theme.colors.common.black75};
  padding: 16px 0;
`

const InfoIcon = styled(IoIosInformationCircle)`
  margin-right: 8px;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.common.black10};
  margin: 16px 0;
`

const SectionTitle = styled.div`
  margin-bottom: 22px;
  color: ${({ theme }) => theme.colors.common.black62};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`

const NMoreContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

interface CarePlansStackProps {
  hasOpacity?: boolean
  paddingBottom: boolean
}
const CarePlansStack = styled(Stack)<CarePlansStackProps>`
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? '20px' : '0')};
  opacity: ${({ hasOpacity }) => (hasOpacity ? 0.5 : 1)};
`

const Tooltip = ({
  children,
  hidden,
  label,
  side,
}: {
  children: any
  hidden: boolean
  label: ReactElement
  side: 'left' | 'right'
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover style={{ position: 'relative' }}>
      <PopoverButton
        onMouseEnter={() => !hidden && setIsOpen(true)}
        onMouseLeave={() => !hidden && setIsOpen(false)}
      >
        {label}
      </PopoverButton>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        show={isOpen}
      >
        <TooltipPanel
          anchor={side === 'left' ? 'bottom start' : 'bottom end'}
          side={side}
          static
        >
          <TooltipContent>{children}</TooltipContent>
        </TooltipPanel>
      </Transition>
    </Popover>
  )
}

const CarePlanList = ({
  carePlans,
  hasOpacity,
  paddingBottom,
}: {
  carePlans: CarePath[]
  hasOpacity?: boolean
  paddingBottom?: boolean
}) => {
  return (
    <CarePlansStack
      direction="column"
      hasOpacity={hasOpacity}
      paddingBottom={!!paddingBottom}
      spacing={24}
    >
      {carePlans.map((path) => (
        <Stack key={path.id} direction="column" spacing={4}>
          <Header4>{path?.diagnosis?.name}</Header4>
          <Text>
            {path?.treatment?.name}
            {path?.treatment?.date &&
              ` - ${dayjs(path.treatment?.date).format('DD/MM/YYYY')}`}
          </Text>
        </Stack>
      ))}
    </CarePlansStack>
  )
}

const CarePlans = ({
  onGoingCarePlans,
  pastCarePlans,
}: {
  onGoingCarePlans: CarePath[]
  pastCarePlans: CarePath[]
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.carePath',
  })
  return (
    <>
      {!!onGoingCarePlans.length && (
        <>
          <SectionTitle>{t('currentCarePaths')}</SectionTitle>
          <CarePlanList carePlans={onGoingCarePlans} />
        </>
      )}
      {!!onGoingCarePlans.length && !!pastCarePlans.length && <Divider />}
      {!!pastCarePlans.length && (
        <>
          <SectionTitle>{t('closedCarePaths')}</SectionTitle>
          <CarePlanList carePlans={pastCarePlans} hasOpacity={true} />
        </>
      )}
    </>
  )
}

export const CarePlansCondensed = ({
  carePlans,
  fixedActiveCarePlans,
}: {
  carePlans: CarePath[]
  fixedActiveCarePlans: boolean
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.carePath',
  })
  const { onGoingCarePlans, pastCarePlans } = groupCarePlans(carePlans)

  const fixedCarePlans = fixedActiveCarePlans
    ? onGoingCarePlans.slice(0, 2)
    : []
  const nMore = fixedActiveCarePlans
    ? onGoingCarePlans.length - fixedCarePlans.length
    : 0

  return (
    <>
      <Tooltip
        hidden={!carePlans.length}
        label={
          <Label isPrimary={true}>
            <InfoIcon />
            {getLabel(t, onGoingCarePlans.length, pastCarePlans.length)}
          </Label>
        }
        side="left"
      >
        <CarePlans
          onGoingCarePlans={onGoingCarePlans}
          pastCarePlans={pastCarePlans}
        />
      </Tooltip>
      {!!fixedCarePlans.length && (
        <CarePlanList carePlans={fixedCarePlans} paddingBottom={true} />
      )}
      {!!nMore && (
        <NMoreContainer>
          <Tooltip
            hidden={!carePlans.length}
            label={
              <Label isPrimary={false}>{t('nMore', { num: nMore })}</Label>
            }
            side="right"
          >
            <CarePlans
              onGoingCarePlans={onGoingCarePlans}
              pastCarePlans={pastCarePlans}
            />
          </Tooltip>
        </NMoreContainer>
      )}
    </>
  )
}

function getLabel(
  t: TFunction<'translation', 'patientSidebar.carePath'>,
  ongoing: number,
  closed: number,
): string {
  let label = ''
  if (!ongoing) {
    label += t('noActiveCarePaths')
  } else if (ongoing === 1) {
    label += t('numActiveCarePathsSingular')
  } else {
    label += t('numActiveCarePathsPlural', { num: ongoing })
  }
  label += ', '
  if (!closed) {
    label += t('noClosedCarePaths')
  } else if (closed === 1) {
    label += t('numClosedCarePathsSingular')
  } else {
    label += t('numClosedCarePathsPlural', { num: closed })
  }
  return label
}
