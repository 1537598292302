import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Tag, Tags } from '@/components/admin/Tags.styled.ts'
import { Header3 } from '@/components/common/Text.tsx'
import { useDeleteRole, useUpdateRole } from '@/hooks/admin/useRoles.ts'
import i18n from '@/i18n'
import { ActionButtons } from '@/screens/admin/UserManagement/ActionButtons.tsx'
import { ContentTable } from '@/screens/admin/UserManagement/ContentTable.tsx'
import { DeleteModal } from '@/screens/admin/UserManagement/DeleteModal.tsx'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { useRoleFormFields } from '@/screens/admin/UserManagement/roles/useRoleFormFields.ts'
import { HeaderBar } from '@/screens/admin/UserManagement/UserManagement.styled.ts'
import { Permission, PermissionWithColor } from '@/types/permission.ts'
import { Role } from '~common/auth/role'

type RolesTableProps = {
  permissions: PermissionWithColor[]
  roles: Role[]
}

export const RolesTable = ({ permissions, roles }: RolesTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.roles',
  })
  const { roleFormFields } = useRoleFormFields()
  const [roleToDelete, setRoleToDelete] = useState<string | null>(null)
  const [roleToEdit, setRoleToEdit] = useState<Role | null>(null)
  const { updateRole } = useUpdateRole({
    onSuccess: () => {
      handleStopEditingRole()
    },
  })
  const { deleteRole } = useDeleteRole({
    onSuccess: () => {
      setRoleToDelete(null)
    },
  })

  const handleStopEditingRole = useCallback(() => {
    setRoleToEdit(null)
  }, [])

  const columns = useMemo<ColumnDef<Role>[]>(
    () => [
      {
        accessorKey: 'name',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
        header: t('columns.name'),
      },
      {
        accessorKey: 'value',
        enableColumnFilter: false,
        header: t('columns.value'),
      },
      {
        accessorKey: 'description',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
        header: t('columns.description'),
      },
      {
        accessorKey: 'permissions',
        cell: (info) => (
          <Tags>
            {(info.getValue() as Array<Permission>)?.map((permission) => {
              const permissionWithColor = permissions?.find(
                (p) => p.id === permission.id,
              )
              return (
                <Tag
                  key={permission.id}
                  backgroundColor={permissionWithColor?.backgroundColor}
                  color={permissionWithColor?.color}
                >
                  {permission.name}
                </Tag>
              )
            })}
          </Tags>
        ),
        enableColumnFilter: false,

        header: t('columns.permissions'),
      },
      {
        accessorFn: (row) => row,
        cell: ({ getValue }) => {
          const role = getValue() as Role

          return (
            <ActionButtons
              onDelete={(e) => {
                e.stopPropagation()
                setRoleToDelete(role.id)
              }}
              onEdit={(e) => {
                e.stopPropagation()
                setRoleToEdit(role)
              }}
            />
          )
        },
        enableColumnFilter: false,
        header: t('columns.actions'),
        id: 'actions',
      },
    ],
    [i18n.language],
  )
  const table = useReactTable<Role>({
    columns,
    data: roles,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <HeaderBar>
        <Header3>{t('title')}</Header3>
      </HeaderBar>
      <ContentTable table={table} modalOnRowClick />
      {roleToEdit && (
        <EditModal
          closeModal={handleStopEditingRole}
          data={roleToEdit}
          errorMessage={t('editModal.error')}
          formFields={roleFormFields}
          isError={false}
          onSave={(role) => {
            updateRole({
              role,
              roleId: role.id,
            })
          }}
          selectOptions={{
            permissions,
          }}
          title={t('editModal.title')}
        />
      )}
      {roleToDelete && (
        <DeleteModal
          caption={t('deleteModal.caption', {
            name: roles.find((role) => role.id === roleToDelete)?.name,
          })}
          closeModal={() => {
            setRoleToDelete(null)
          }}
          errorMessage={t('deleteModal.error')}
          onDelete={() => deleteRole({ roleId: roleToDelete })}
          title={t('deleteModal.title')}
        />
      )}
    </>
  )
}
